import {
    PRODUCTS_START_LOADING,
    PRODUCTS_FINISH_LOADING,
    PRODUCTS_ALL_PERSIST,
    PRODUCTS_CLEAR
} from '../actionTypes/actionTypes'

const initialState = {
    allProducts: [],
    availabilities: [],
    reservedTimes: [],
    loadingProducts: false,
    message: ""
}

export default (state = initialState, action) => {
    switch(action.type){
        case PRODUCTS_START_LOADING:
            return {...state, message: action.payload, loadingProducts: true}
        case PRODUCTS_FINISH_LOADING:
            return {...state, message: "", loadingProducts: false}
        case PRODUCTS_ALL_PERSIST:
            return {...state, allProducts: action.payload}
        
        case PRODUCTS_CLEAR:
            return {...state, allProducts: []}
        default:
            return state
    }
}