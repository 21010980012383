import {
    USER_LOGIN,
    USER_LOGOUT,
    USER_START_LOADING,
    USER_FINISH_LOADING
} from '../actionTypes/actionTypes'

import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants'
import axios from 'axios'

export const TryLogin = (username,password) => {
    return async (dispatch) =>{
        dispatch(UserStartLoading(""))
        axios.post(backendRoot + "api/UserAccounts/Login",{
            username: username,
            password: password
            },
            {
                withCredentials: true
            })
            .then((response) =>{
                if(response.status === 200)
                {
                    dispatch(AutentificateUser())
                }
            })
            .catch((error) =>{
                dispatch(UserFinishLoading(error.toString()))
                //console.log(error);
            })
    }
}

export const AutentificateUser = () => {
    return async (dispatch) =>{
        dispatch(UserStartLoading(""))
        axios.post(backendRoot + "api/UserAccounts/User",{},
        {
            withCredentials: true
        })
        .then((response) =>{
            //console.log(response)
            if(response.status === 200 && response.data !== "Unauthorized")
            {
                dispatch(UserLogin(response.data))
            }
            dispatch(UserFinishLoading(""))
        })
        .catch((error) =>{
            dispatch(UserFinishLoading(error))
            //console.log(error);
        })
    }
}

export const TryLogout = () => {
    return async (dispatch) =>{
        dispatch(UserStartLoading(""))
        axios.post(backendRoot + "api/UserAccounts/Logout",{},{
            withCredentials: true
        })
        .then((response) =>{
                if(response.status === 200)
                {
                    dispatch(UserLogout())
                    dispatch(UserFinishLoading(""))
                }
        })
        .catch((error) =>{
            dispatch(UserFinishLoading(error))
            //console.log(error);
        })
    }
}


export const UserStartLoading = (message) => {
    return (dispatch) => {
        dispatch(StartLoading(message))
    };
};

export const UserFinishLoading = (message) => {
    return (dispatch) => {
        dispatch(FinishLoading(message))
    };
};

export const UserLogin = (data) => {
    return (dispatch) => {
        dispatch(Login(data))
    };
};

export const UserLogout = () => {
    return (dispatch) => {
        dispatch(Logout())
    };
};

const StartLoading = (message) => {
    return {
        type: USER_START_LOADING,
        payload: message
    }
}

const FinishLoading = (message) => {
    return {
        type: USER_FINISH_LOADING,
        payload: message
    }
}

const Login = (data) => {
    return {
        type: USER_LOGIN,
        payload: data
    }
}

const Logout = () => {
    return {
        type: USER_LOGOUT
    }
}