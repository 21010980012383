import React, { useEffect, useState } from "react";
import ReservationCalendar from "../../components/reservation-calendar/reservation-calendar";
import "../../assets/styles/Reservation/Reservation.scss";
import ReservationStep from "../../components/reservation-step/reservation-step";
import ReservationProducts from "../../components/reservation-products/reservation-products";
import ReservationUser from "../../components/reservation-user/reservation-user";

import * as ProductsActionCreators from "../../store/actionCreators/products";
import * as ReservationActionCreators from "../../store/actionCreators/reservation";

import { useSelector, useDispatch, Provider } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import Loading from "../../components/loading/loading";
import { google } from "google-map-react";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";

function Reservation(props) {
  const {
    sendMessage,
    message,
    setMessage,
    clientKey,
    userType,
    isOverview,
    daysWithoutAppointments,
  } = props;

  const { t } = useTranslation();

  const productsSession = useSelector((state) => state.productsSession);
  const reservationSession = useSelector((state) => state.reservationSession);
  const dispatch = useDispatch();
  const { setReservationDate } = bindActionCreators(
    { ...ProductsActionCreators, ...ReservationActionCreators },
    dispatch
  );

  const [loading, setLoading] = useState(false);

  const [isEscapeRoom, setIsEscapeRoom] = useState(true);

  return (
    <>
      {productsSession.loadingProducts && <Loading />}
      {loading && <Loading />}
      <div
        className={
          userType === "admin"
            ? "reservation-main-admin-container"
            : "reservation-main-container"
        }
      >
        <div
          className={
            userType === "admin"
              ? "reservation-admin-container"
              : "reservation-container"
          }
        >
          {productsSession.allProducts.length ? (
            <>
              <ReservationStep stepNumber={"Step 1"} text={t("SelectDate")} />
              <ReservationCalendar
                daysWithoutAppointments={daysWithoutAppointments}
              />
              <ReservationStep
                stepNumber={"Step 2"}
                text={t("SelectRoomAndTime")}
              />
              <ReservationProducts
                productType={1}
                userType={"admin"}
                isOverview={isOverview}
                setIsEscapeRoom={setIsEscapeRoom}
              />
              {reservationSession.time.hour !== null && (
                <ReservationStep
                  stepNumber={"Step 3"}
                  text={t("CompleteReservation")}
                />
              )}
              <ReservationUser
                sendMessage={sendMessage}
                message={message}
                setMessage={setMessage}
                clientKey={clientKey}
                setLoading={setLoading}
                isEscapeRoom={isEscapeRoom}
              />
            </>
          ) : (
            <ReservationStep
              stepNumber={"Step 3"}
              text={t(
                "Make a reservation at +381611574924 or @escaperoomcegar on Instagram"
              )}
            />
          )}
        </div>
        {userType !== "admin" && <Footer />}
      </div>
    </>
  );
}

export default Reservation;
