import axios from "axios"
import { useEffect, useState } from "react"
import { backendRoot } from "../../../User/assets/apiConstants/ApiConstants"
import Loading from "../../../User/components/loading/loading"
import '../../assets/styles/ReservationOverview/ReservationOverview.scss'
import ReceiptCard from "../receipt-card/receipt-card"

import { ChevronLeft, ChevronRight, Search, X, Check } from 'react-bootstrap-icons'
import ConfirmationModal from "../confirmation-modal/confirmation-modal"
import ReservationDetailsModal from "../reservation-details-modal/reservation-details-modal"
import { useSelector, useDispatch, Provider } from 'react-redux';
import * as ProductsActionCreators from "../../../User/store/actionCreators/products"
import * as ReservationActionCreators from '../../../User/store/actionCreators/reservation';
import { bindActionCreators } from 'redux';
import { PencilSquare } from "react-bootstrap-icons";


function ReservationOverview() {

    const [receiptCount, setReceiptCount] = useState(null);
    const dispatch = useDispatch();
    const { FetchProductsData } = bindActionCreators({ ...ProductsActionCreators, ...ReservationActionCreators }, dispatch)

    const [receipts, setReceipts] = useState(null)
    const [receiptsByDate, setReceiptsByDate] = useState(null)
    const [loading, setLoading] = useState(false)



    const [date, setDate] = useState(new Date())

    const [displayDate, setDisplayDate] = useState("")

    const [name, setName] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [teamName, setTeamName] = useState("")

    const [selectedFilter, setSelectedFilter] = useState(0)

    const [filteredReceipts, setFilteredReceipts] = useState([])

    const [receiptIdToDelete, setReceiptIdToDelete] = useState(null)

    const [receiptForDetails, setReceiptForDetails] = useState(null)

    useEffect(() => {
        if (displayDate === "")
            setDisplayDate(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())
    }, [displayDate])

    /*const GetReceiptsCount = async () => {
        setLoading(true)
        await axios.get(backendRoot + "api/Receipts/GetCount")
            .then((response) => {
                console.log(response.data)
                setReceiptCount(response.data)
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                console.log(error);
            })
    }*/

    const GetReceipts = async () => {
        setLoading(true)
        await axios.get(backendRoot + "api/Receipts/GetReceiptsForReservarions")
            .then((response) => {
                //console.log(response.data)
                //console.log([...response.date].sort((a, b) => a.id < b.id))
                setReceipts(response.data)
                setFilteredReceipts(response.data.sort(function(a, b){return b.id - a.id}))
                /*if (receipts === null)
                    setReceipts(response.data)
                else
                    setReceipts(prevState => ([...prevState, ...response.data]))*/
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                //console.log(error);
            })
    }

    const GetReceiptsByDate = async (date) => {
        setLoading(true)
        await axios.get(backendRoot + "api/Receipts/GetByDate/" + date.toDateString())
            .then((response) => {
                setReceiptsByDate(response.data)
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
            })
    }

    

    /*useEffect(() => {
        if (receipts !== null) {
            setFilteredReceipts(receipts)
        }
    }, [receipts])*/

    useEffect(() => {
        if (receipts === null) {
            GetReceipts()
        }
    }, [receipts])

    useEffect(() => {
        if(receiptsByDate === null && date != null && date != undefined)
            GetReceiptsByDate(date)
    }, [date])



    const DeleteReceipt = async (id) => {
        setLoading(true)
        await axios.post(backendRoot + "api/Reservation/Delete/" + id,{},{
            withCredentials: true
        })
            .then((response) => {
                //console.log(response.data)
                handleUpdate()
                setReceipts(null)
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                //console.log(error);
            })
    }


    const addDate = () => {
        var newDate = date
        newDate.setDate(newDate.getDate() + 1)
        setDisplayDate(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())
        setDate(newDate)
        //GetReceiptsByDate(newDate)
    }

    const subDate = () => {
        var newDate = date
        newDate.setDate(newDate.getDate() - 1)
        setDisplayDate(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())
        setDate(newDate)
        //GetReceiptsByDate(newDate)
    }

    const filterReceipts = () => {
        var newReceipts = receipts
        if(name !== "")
            newReceipts = newReceipts.filter(receipt => receipt?.individual?.name.toUpperCase().includes(name.toUpperCase()))
        if(lastname !== "")
            newReceipts = newReceipts.filter(receipt => receipt?.individual?.lastname.toUpperCase().includes(lastname.toUpperCase()))
        if(email !== "")
            newReceipts = newReceipts.filter(receipt => receipt?.individual?.email.toUpperCase().includes(email.toUpperCase()))
        if(teamName !== "")
            newReceipts = newReceipts.filter(receipt => receipt?.appointmentReservation?.teamName.toUpperCase().includes(teamName.toUpperCase()))
        if(selectedFilter === 1)
            newReceipts = newReceipts.filter(receipt => receipt?.legalEntity !== null)
        if(selectedFilter === 2)
            newReceipts = newReceipts.filter(receipt => receipt?.individual !== null)
        setFilteredReceipts(newReceipts)
    }

    const handleUpdate = () => {
        FetchProductsData("admin")
        setReceiptForDetails(null)
        setReceiptIdToDelete(null)
        GetReceiptsByDate(date)
    }


    return (
        <>
            {loading && <Loading />}
            <div className="admin-panelpage-title">
                Reservations / Overview
            </div>
            <div className="section-title">
                Search reservations
            </div>
            <div className="reservation-overview-date-container">
                Select date
                <div className="reservation-overview-date-container-buttons">
                    <button onClick={subDate}><ChevronLeft /></button>{displayDate}<button onClick={addDate}><ChevronRight /></button><button onClick={()=>{GetReceiptsByDate(date)}}><Search /> Search</button>
                </div>
                <div className="reservations-by-date-scroll-container">
                <div className="reservations-by-date-container">
                                            <div><b>Product</b></div>
                                            <div><b>Time</b></div>
                                            <div><b>User</b></div>
                                            <div><b>Legal entity</b></div>
                                            <div><b>Email</b></div>
                                            <div><b>Price</b></div>
                                            <div></div>
                                        </div>
                {
                    receiptsByDate?.length > 0 ?
                        <>
                            {
                                receiptsByDate.map((receipt, index) => {
                                    return (
                                        <div key={receipt.id} className="reservations-by-date-container">
                                            <div className="receipt-container-v2-product-name"><button onClick={()=>{setReceiptForDetails(receipt)}}><PencilSquare/> Edit</button><b>{receipt?.appointmentReservation?.product?.nameTranslation?.sr}</b></div>
                                            <div>{receipt?.appointmentReservation?.reservedAppointmentTime?.hour}:{receipt?.appointmentReservation?.reservedAppointmentTime?.minute < 10 ? "0"+receipt?.appointmentReservation?.reservedAppointmentTime?.minute : receipt?.appointmentReservation?.reservedAppointmentTime?.minute} h</div>
                                            <div>{receipt?.individual !== null ? (receipt?.individual?.name + " " + receipt?.individual?.lastname) : (receipt?.legalEntity?.name + " " + receipt?.legalEntity?.lastname)}</div>
                                            <div>{receipt?.individual !== null ? <X style={{color: "rgb(220,53,69)", fontSize: "large"}}/> : <Check style={{color: "rgb(40,167,69)", fontSize: "large"}} />}</div>
                                            <div>{receipt?.individual !== null ? receipt?.individual?.email : receipt?.legalEntity?.email}</div>
                                            <div>{receipt?.price} RSD</div>
                                            <div><button style={{backgroundColor: "rgb(220,53,69)", borderColor: "rgb(220,53,69)"}} onClick={()=>{setReceiptIdToDelete(receipt.id)}}>Delete</button></div>
                                        </div>
                                    )
                                })
                            }
                        </>
                        :
                        <div style={{padding: "10px"}}>
                            No reservations
                        </div>
                }
                </div>
            </div>
            <div className="section-title">
                All reservations
            </div>
            <div className="reservation-overview-search">
                Filters
                <div className="find-voucher-legend-container">
                    <div onClick={() => { setSelectedFilter(0) }} style={{ backgroundColor: selectedFilter === 0 ? "rgb(48, 60, 84)" : "rgb(150,150,150)", cursor: "pointer" }}>All reservations</div>
                    <div onClick={() => { setSelectedFilter(1) }} style={{ backgroundColor: selectedFilter === 1 ? "rgb(48, 60, 84)" : "rgb(150,150,150)", cursor: "pointer" }}>Legal entities</div>
                    <div onClick={() => { setSelectedFilter(2) }} style={{ backgroundColor: selectedFilter === 2 ? "rgb(48, 60, 84)" : "rgb(150,150,150)", cursor: "pointer" }}>Individuals</div>
                </div>
                <div className="reservation-overview-search-items">
                    <div><input value={name} onChange={(e) => {setName(e.target.value)}} placeholder="Name" type="text"/></div>
                    <div><input value={lastname} onChange={(e) => {setLastname(e.target.value)}} placeholder="Lastname" type="text"/></div>
                    <div><input value={email} onChange={(e) => {setEmail(e.target.value)}} placeholder="Email" type="text"/></div>
                    <div><input value={teamName} onChange={(e) => {setTeamName(e.target.value)}} placeholder="Team name" type="text"/></div>
                    <div><button onClick={filterReceipts}><Search /> Search</button><button onClick={()=>{setSelectedFilter(0); setName(""); setLastname(""); setEmail(""); setTeamName(""); setFilteredReceipts(receipts.sort(function(a, b){return b.id - a.id}))}} style={{marginLeft: "5px", backgroundColor: "rgb(220,53,69)", border: "1px solid rgb(220,53,69)"}}><X /> Clear</button></div>
                </div>
            </div>
            <div className="reservation-overview-container">
                {/*<h5>Select date</h5>
                <input type="date" /><br />
    <button onClick={() => { GetReceipts() }}>Search</button>*/}
                {
                    filteredReceipts?.length > 0 ?
                        <>
                            {
                                filteredReceipts.map((receipt, index) => {
                                    return (
                                        <ReceiptCard key={index} receipt={receipt} DeleteReceipt={setReceiptIdToDelete} />
                                    )
                                })
                            }
                        </>
                        :
                        <div style={{padding: "10px", color: "rgb(150,150,150)"}}>
                            No reservations
                        </div>
                }
                
            </div>
            {receiptForDetails !== null && <ReservationDetailsModal handleUpdate={handleUpdate} handleClose={()=>{setReceiptForDetails(null)}} receipt={receiptForDetails} handleDelete={()=>{setReceiptIdToDelete(receiptForDetails.id)}} handleEdit={()=>{setReceiptForDetails(null)}}/>}
            <ConfirmationModal isOpen={receiptIdToDelete !== null} message={"Are you sure you want to delete this reservation?"} handleYes={()=>{DeleteReceipt(receiptIdToDelete)}} handleNo={()=>{setReceiptIdToDelete(null)}}/>
        </>
    )
}

export default ReservationOverview;