import { JsonHubProtocol } from "@microsoft/signalr";
import { backendRoot } from "../../assets/apiConstants/ApiConstants";
import {
  PRODUCTS_START_LOADING,
  PRODUCTS_FINISH_LOADING,
  PRODUCTS_ALL_PERSIST,
  PRODUCTS_AVAILABILITIES,
  PRODUCTS_CLEAR,
} from "../actionTypes/actionTypes";

const axios = require("axios").default;

export const FetchProductsData = (userType) => {
  return async (dispatch) => {
    var route = backendRoot + "api/Products/GetAll/" + userType;
    dispatch(productsStartLoading("LOADING"));
    console.log("fetching products");

    axios
      .get(route)
      .then((response) => {
        dispatch(persistAllProducts(response.data));
        dispatch(productsFinishLoading());
      })
      .catch((error) => {
        dispatch(productsFinishLoading());
        //console.log(error);
      });
  };
};

export const productsStartLoading = (message) => {
  return (dispatch) => {
    dispatch(startLoading(message));
  };
};

export const productsFinishLoading = () => {
  return (dispatch) => {
    dispatch(finishLoading());
  };
};

export const productsClear = () => {
  return (dispatch) => {
    dispatch(clearProducts());
  };
};

export const persistProductsAvailabilities = (_year, _month, _date, _day) => {
  return (dispatch) => {
    dispatch(persistAvailabilities(_year, _month, _date, _day));
  };
};

const startLoading = (message) => {
  return {
    type: PRODUCTS_START_LOADING,
    payload: message,
  };
};
const finishLoading = () => {
  return {
    type: PRODUCTS_FINISH_LOADING,
  };
};
const persistAllProducts = (products) => {
  return {
    type: PRODUCTS_ALL_PERSIST,
    payload: products,
  };
};
const persistAvailabilities = (_year, _month, _date, _day) => {
  return {
    type: PRODUCTS_AVAILABILITIES,
    payload: { year: _year, month: _month, date: _date, day: _day },
  };
};
const clearProducts = () => {
  return {
    type: PRODUCTS_CLEAR,
  };
};
