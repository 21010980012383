import {useEffect, useState} from 'react'
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants';
import '../../assets/styles/AdminProfile/AdminProfile.scss'

import * as UserActionCreators from '../../store/actionCreators/user';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Loading from '../../../User/components/loading/loading';

function AdminProfile(props){

    const userSession = useSelector((state) => state.userSession) 
    const dispatch = useDispatch();
    const { AutentificateUser } = bindActionCreators({ ...UserActionCreators}, dispatch)   

    const [loading, setLoading] = useState(false)
    const [showEdit, setShowEdit] = useState(true)

    const [imageSrc, setImageSrc] = useState("")
    const [imageFile, setImageFile] = useState(null)

    const [name, setName] = useState("")
    const [lastname, setLastname] = useState("")
    const [username, setUsername] = useState("")
    const [role, setRole] = useState("")

    const [password, setPassword] = useState("")

    useEffect(()=>{
        if(name === "" && lastname === "" && username === "" && role === "")
        {
            setName(userSession.data.name)
            setLastname(userSession.data.lastname)
            setUsername(userSession.data.username)
            setRole(userSession.data.role.roleName)
        }
    })

    const showPreview = e => {
        //console.log(e.target.files)
        if(e.target.files && e.target.files[0])
        {
            let imageFile = e.target.files[0]
            const reader = new FileReader()
            reader.onload = x=>{
                setImageFile(imageFile)
                setImageSrc(x.target.result)
            }
            reader.readAsDataURL(imageFile)
        }
    }

    const uploadImage = async () => {
        //console.log(imageFile)
        setLoading(true)
        const formData = new FormData()

        formData.append('id', userSession.data.id)
        formData.append('class', 3)
        formData.append('imageFile', imageFile)

        await axios.post(backendRoot + "api/Images/AddImage",formData,
        {
            withCredentials: true
        })
            .then((response) =>{
                //console.log(response)
                setLoading(false)
                setShowEdit(true)
                AutentificateUser()
            })
            .catch((error) =>{
                //console.log(error);
                setLoading(false)
            })
    }

    const handleCancel = () => {
        setShowEdit(true)
    }

    const UpdateProfile = async() => {
        setLoading(true)
        await axios.put(backendRoot + "api/UserAccounts/Update",
        {
            id: userSession.data.id,
            name: name,
            lastname: lastname,
            username: username,
            password: password === "" ? userSession.data.password : password,
            imageName: userSession.data.imageName
        },
        {
            withCredentials: true
        })
            .then((response) =>{
                //console.log(response)
                if(imageSrc !== "")
                    uploadImage()
                else
                {
                    setLoading(false)
                    setShowEdit(true)
                    AutentificateUser()
                }
                    
            })
            .catch((error) =>{
                //console.log(error);
            })
    }

    const handleSave = () => {
        if(imageSrc !== "")
            uploadImage()
        
    }

    useEffect(()=>{
        //console.log(userSession.data)
    },[userSession.data])

    return(
        <>
        {loading && <Loading/>}
        <div className="admin-profile-container">
            
            <div className="admin-profile-image" style={{backgroundImage: imageSrc === "" ? userSession.data && "url("+backendRoot + "ProfileImages/" + userSession.data.imageName + ")" : "url(" + imageSrc + ")" , backgroundSize: "cover", backgroundPosition: "50% 50%"}}/>
            {!showEdit && <input type={"file"} accept="image/*" onChange={showPreview}/>}<br/>
            <h2>{userSession.data && userSession.data.name + ' ' + userSession.data.lastname}</h2>
            {
                showEdit ?
                <>
                <div><h5>Name: </h5>{name}</div><br/>
                <div><h5>Lastname: </h5>{lastname}</div><br/>
                <div><h5>Username: </h5>{username}</div><br/>
                </>
                :
                <>
                <div><h5>Name: </h5><input value={name} onChange={(e)=>{setName(e.target.value)}}/></div><br/>
                <div><h5>Lastname: </h5><input value={lastname} onChange={(e)=>{setLastname(e.target.value)}}/></div><br/>
                <div><h5>Username: </h5><input value={username} onChange={(e)=>{setUsername(e.target.value)}}/></div><br/>
                <div><h5>New password: </h5><input value={password} onChange={(e)=>{setPassword(e.target.value)}}/></div><br/>
                </>
            }
            <div><h5>Role: </h5>{role}</div><br/>
            <div>
                {
                    showEdit ?
                    <>
                    <button onClick={()=>{setShowEdit(false)}}>Edit</button>
                    </>
                    :
                    <>
                    <button onClick={handleCancel}>Cancel</button>
                    <button onClick={UpdateProfile}>Save</button>
                    </>
                }
                
            </div>
        </div>
        </>
    )
}

export default AdminProfile;