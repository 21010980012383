import React, { useState, useEffect } from "react";
import '../../assets/styles/Home/Home.scss'
import HomeAccordion from "../../components/home-accordion/home-accordion";
import HomeHero from "../../components/home-hero/home-hero";
import HomeTheme from "../../components/home-theme/home-theme";
import HomeAbout from "../../components/home-about/home-about";
import HomePromotional from "../../components/home-promotional/home-promotional";
import HomeDescription from "../../components/home-description/home-description";
import Footer from "../../components/footer/footer";
import { useSelector } from 'react-redux';
import Loading from "../../components/loading/loading";

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'


import { useTranslation } from 'react-i18next';

import Carousel from 'react-bootstrap/Carousel';


function Home(props) {
    const { numberVisitors } = props
    const productsSession = useSelector((state) => state.productsSession)

    const { t } = useTranslation()


    return (
        <>
            {/*productsSession.loadingProducts && <Loading />*/}
            <HomeHero />
            <Carousel>
                <Carousel.Item>
                    <div
                        className="d-block w-100 slide1"
                    ></div>
                    <Carousel.Caption>
                        <div className="slide-text">
                        <h3>{t('slide1-1')}</h3>
                        <p>{t('slide1-2')}</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                <div
                        className="d-block w-100 slide2"
                    ></div>

                    <Carousel.Caption>
                    <div className="slide-text">
                        <h3>{t('slide2-1')}</h3>
                        <p>{t('slide2-2')}</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                <div
                        className="d-block w-100 slide3"
                    ></div>

                    <Carousel.Caption>
                    <div className="slide-text">
                        <h3>{t('slide3-1')}</h3>
                        <p>{t('slide3-2')}</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            {/*<div className="slide-container-home">
                <Slide>
                    <div className="each-slide-home slide1">
                        <div>
                            <h4 className="home-theme-child-2-heading">{t('slide1-1')}</h4>
                            <br/>
                            <p>{t('slide1-2')}</p>
                        </div>
                    </div>
                    <div className="each-slide-home slide2">
                        <div>
                            <h4 className="home-theme-child-2-heading">{t('slide2-1')}</h4>
                            <br/>
                            <p>{t('slide2-2')}</p>
                        </div>
                    </div>
                    <div className="each-slide-home slide3">
                        <div>
                            <h4 className="home-theme-child-2-heading">{t('slide3-1')}</h4>
                            <br/>
                            <p>{t('slide3-2')}</p>
                        </div>
                    </div>
                </Slide>
    </div>*/}

            <HomeTheme />
            <HomePromotional />
            <HomeDescription />
            <HomeAbout />
            <HomeAccordion />
            <div className="home-footer-container">
                <Footer numberVisitors={numberVisitors} />
            </div>

        </>
    );
}

export default Home