import { useEffect, useState } from 'react'
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants'
import '../../assets/styles/AdminProductCard/AdminProductCard.scss'
import { useSelector, useDispatch, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ProductsActionCreators from '../../../User/store/actionCreators/products';
import * as ReservationActionCreators from '../../../User/store/actionCreators/reservation';
import axios from 'axios';
import Loading from '../../../User/components/loading/loading';
import Modal from 'react-modal';

function AdminProductCard(props){
    const {product} = props

    const productsSession = useSelector((state) => state.productsSession)

    const dispatch = useDispatch();
    const { 
      FetchProductsData,
      setReservationDate 
    } = bindActionCreators({ ...ProductsActionCreators, ...ReservationActionCreators}, dispatch)

    var images = []
    images.push(product.mainProductComponent.imageName)

    product.productByAditionalComponents.length > 0 && 
    product.productByAditionalComponents.forEach((component)=> images.push(component.aditionalProductComponent.imageName))

    const [nameEN, setNameEN] = useState("")
    const [nameSR, setNameSR] = useState("")
    const [descriptionEN, setDescriptionEN] = useState("")
    const [descriptionSR, setDescriptionSR] = useState("")
    const [discountPerPerson, setDiscountPerPerson] = useState("")
    const [discountIfMoreThan, setDiscountIfMoreThan] = useState("")
    const [maxNumberOfPersons, setMaxNumberOfPersons] = useState("")
    const [minNumberOfPersons, setMinNumberOfPersons] = useState("")

    const handleEdit = () => {
        setEdit(true)
        setNameEN(product.nameTranslation.en)
        setNameSR(product.nameTranslation.sr)
        setDescriptionEN(product.descriptionTranslation.en)
        setDescriptionSR(product.descriptionTranslation.sr)
        setDiscountPerPerson(product.discountPerPerson)
        setDiscountIfMoreThan(product.discountIfMoreThan)
        setMaxNumberOfPersons(product.maxNumberOfPersons)
        setMinNumberOfPersons(product.minNumberOfPersons)
    }


    const [edit, setEdit] = useState(false)

    const renderDate = (date) => {
        var newDate = new Date(date)
        return newDate.getDate()+"/"+(newDate.getMonth()+1)+"/"+newDate.getFullYear()
    }

    var StartingAppointmentTimeIdsForDelete = []

    const handleCheckStartingAppointmentTime = (id,checked) => {
        if(checked)
            StartingAppointmentTimeIdsForDelete.push(id)
        else
        {
            var newArray = []
            StartingAppointmentTimeIdsForDelete.forEach((id1)=>{
                if(id1 !== id)
                    newArray.push(id1)
            })
            StartingAppointmentTimeIdsForDelete = newArray
        }
        //console.log(StartingAppointmentTimeIdsForDelete)
    }

    const deleteStartingAppointmentTime = async (id) => {
        await axios.delete(backendRoot + "api/StartingAppointmentTimes/Delete/" + id)
            .then((response) =>{
                //console.log(response)
            })
            .catch((error) =>{
                //console.log(error);
            })
    }

    const deleteStartingAppointmentTimes = () => {
        StartingAppointmentTimeIdsForDelete.forEach((id)=>{
            deleteStartingAppointmentTime(id)
        })
    }

    const handleSave = () => {
        if(StartingAppointmentTimeIdsForDelete.length > 0)
            deleteStartingAppointmentTimes()
        setEdit(false)
        FetchProductsData("admin")
    }

    const handleDeleteAppointments = async () => {
        if(StartingAppointmentTimeIdsForDelete.length > 0)
            deleteStartingAppointmentTimes()
        setEdit(false)
        FetchProductsData("admin")
    }

    const [showAddAppointment, setShowAddAppointment] = useState(false)
    const [appointmentType, setAppointmentType] = useState(0)
    const [selectedDayId, setSelectedDayId] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [hour, setHour] = useState(0)
    const [minute, setMinute] = useState(0)

    const createNewAvailability = async () => {
        var newDate = null
        console.log("asdddddddddddd")
        if(selectedDate !== null)
        {
            newDate = new Date(parseInt(selectedDate.split("-")[0]),parseInt(selectedDate.split("-")[1]) - 1,parseInt(selectedDate.split("-")[2]))
            newDate.setHours(newDate.getHours() + 2)
        }
            
        console.log("NEW DATE",newDate)
        await axios.post(backendRoot + "api/Availabilities/Create",{
            description: "Dostupnost",
            date:  newDate ,
            mainProductComponentId: product.mainProductComponent.id,
            dayId: selectedDayId
        })
            .then((response) =>{
                console.log(response)
                createStartingAppointmentTime(response.data.id)
            })
            .catch((error) =>{
                console.log(error);
            })
    }

    const createStartingAppointmentTime = async (id) => {
        await axios.post(backendRoot + "api/StartingAppointmentTimes/Create",{
            availabilityId: id,
            hour: hour,
            minute: minute
        })
            .then((response) =>{
                //console.log(response)
            })
            .catch((error) =>{
                //console.log(error);
            })
    }

    const checkAppointment = () => {
        var result = null
        if(selectedDayId !== null)
        {
            product.mainProductComponent.availabilities.forEach((availability)=>{
                if(availability.dayId === selectedDayId)
                {
                    result = availability.id
                }
            })
        }
        if(selectedDayId === null)
        {
            product.mainProductComponent.availabilities.forEach((availability)=>{
                console.log(availability.date, selectedDate, availability.date !== null && availability.date.split("T")[0] === selectedDate)
                if(availability.date !== null && availability.date.split("T")[0] === selectedDate)
                {
                    
                    result = availability.id
                }
            })
        }
        
        return result
    }

    const addAppointment = () => {
        var id = checkAppointment()
        if(id !== null)
        {
            createStartingAppointmentTime(id)
        }
        else
        {
            createNewAvailability()
        }
        FetchProductsData("admin")
    }
    
    

    return(
        <>
        {productsSession.loadingProducts && <Loading/>}
        <div className="admin-product-card-container">
            <div className="admin-product-card-images">
            {
                images.map((image,index)=>{
                    return <img key={index} style={{background: "url("+backendRoot+"Images/" + image +")", backgroundSize: "cover", backgroundPositionX: "50%"}} className="admin-product-card-image"/>
                })
            }
            </div>
            <div><b>Name(EN): </b>{edit ? <><input value={nameEN} onChange={(e)=>{setNameEN(e.target.value)}}/></>:<>{product.nameTranslation.en}</>}</div>
            <div><b>Name(SR): </b>{edit ? <><input value={nameSR} onChange={(e)=>{setNameSR(e.target.value)}}/></>:<>{product.nameTranslation.sr}</>}</div><br/>
            <div><b>Description(EN): </b>{edit ? <><textarea value={descriptionEN} onChange={(e)=>{setDescriptionEN(e.target.value)}}/></>:<>{product.descriptionTranslation.en}</>}</div><br/>
            <div><b>Description(SR): </b>{edit ? <><textarea value={descriptionSR} onChange={(e)=>{setDescriptionSR(e.target.value)}}/></>:<>{product.descriptionTranslation.sr}</>}</div><br/>
            <div><b>Discount per person: </b>{edit ? <><input value={discountPerPerson} onChange={(e)=>{setDiscountPerPerson(e.target.value)}}/></>:<>{product.discountPerPerson}</>}</div>
            <div><b>Discount per person if more than: </b>{edit ? <><input value={discountIfMoreThan} onChange={(e)=>{setDiscountIfMoreThan(e.target.value)}}/></>:<>{product.discountIfMoreThan}</>}</div>
            <div><b>Max number of persons: </b>{edit ? <><input value={maxNumberOfPersons} onChange={(e)=>{setMaxNumberOfPersons(e.target.value)}}/></>:<>{product.maxNumberOfPersons}</>}</div>
            <div><b>Min number of persons: </b>{edit ? <><input value={minNumberOfPersons} onChange={(e)=>{setMinNumberOfPersons(e.target.value)}}/></>:<>{product.minNumberOfPersons}</>}</div>
            <div><b>Product type: </b>{product.productType.name}</div><br/>
            
            <div><b>Availabilities per days: </b></div>

            <div className="admin-product-card-times">
                {
                    product.mainProductComponent.availabilities.map((availability,index)=>{
                        return(
                        <>
                        {availability.dayId &&
                        
                        <div key={index}  className="admin-product-card-time">
                        <b>
                        {availability.dayId && availability.dayId === 1 && "Mon"}
                        {availability.dayId && availability.dayId === 2 && "Tue"}
                        {availability.dayId && availability.dayId === 3 && "Wed"}
                        {availability.dayId && availability.dayId === 4 && "Thu"}
                        {availability.dayId && availability.dayId === 5 && "Fri"}
                        {availability.dayId && availability.dayId === 6 && "Sat"}
                        {availability.dayId && availability.dayId === 7 && "Sun"}
                        </b>
                        {
                            availability.startingAppointemntTimes.length > 0 ?

                            availability.startingAppointemntTimes.map((time,index)=>{
                                return (
                                <div key={index} className="admin-product-card-time" style={{display: StartingAppointmentTimeIdsForDelete.length > 0 && StartingAppointmentTimeIdsForDelete.includes(time.id) && "none"}}>
                                {time.hour < 10 ? "0"+time.hour : time.hour}:{time.minute < 10 ? "0"+time.minute : time.minute}
                                {edit && <input type="checkbox" className="checkbox-time" onClick={(e)=>{handleCheckStartingAppointmentTime(time.id,e.target.checked)}}/>}
                                </div>
                                )
                            })
                            :
                            <div className="admin-product-card-time">
                                No available times
                            </div>
                        }
                        </div>
                        }</>)
                    })
                }
                
            </div>
            
            <br/>
            <div><b>Availabilities per dates: </b></div>
            <div className="admin-product-card-times">
                {
                    product.mainProductComponent.availabilities.map((availability,index)=>{
                        return(
                        <>
                        {availability.date &&
                        <div key={index}  className="admin-product-card-time" style={{width: "100%"}}>
                        <b>
                        {availability.date && renderDate(availability.date)}
                        </b>
                        {
                            availability.startingAppointemntTimes.length > 0 ?

                            availability.startingAppointemntTimes.map((time,index)=>{
                                return (
                                    <div key={index} className="admin-product-card-time" style={{display: StartingAppointmentTimeIdsForDelete.length > 0 && StartingAppointmentTimeIdsForDelete.includes(time.id) && "none"}}>
                                    {time.hour < 10 ? "0"+time.hour : time.hour}:{time.minute < 10 ? "0"+time.minute : time.minute}
                                    {edit && <input type="checkbox" className="checkbox-time" onClick={(e)=>{handleCheckStartingAppointmentTime(time.id,e.target.checked)}}/>}
                                    </div>
                                    )
                            })
                            :
                            <div className="admin-product-card-time" style={{width: "100%"}}>
                                No available times
                            </div>
                        }
                        </div>
                        }</>)
                    })
                }
            </div>
            {edit && 
            <>
            <button style={{height: 55}} onClick={handleDeleteAppointments}>Delete selected appointments</button><br/>
            <button style={{height: 55}} onClick={()=>{setShowAddAppointment(true)}}>Add new appointment</button>
            </>
            }
            {
                showAddAppointment &&
                <div>
                    <div>
                        <input type="radio" name="appointment-type" onClick={()=>{setAppointmentType(1)}}/> Add appointment for day<br/>
                        <input type="radio" name="appointment-type" onClick={()=>{setAppointmentType(2)}}/> Add appointment for date<br/>
                    </div>
                    {
                        appointmentType === 1 &&
                        <>
                        <div>
                            <b>Select day:</b><br/>

                            <input type="radio" name="day-id" onClick={()=>{setSelectedDayId(1)}}/>Mon<br/>
                            <input type="radio" name="day-id" onClick={()=>{setSelectedDayId(2)}}/>Tue<br/>
                            <input type="radio" name="day-id" onClick={()=>{setSelectedDayId(3)}}/>Wed<br/>
                            <input type="radio" name="day-id" onClick={()=>{setSelectedDayId(4)}}/>Thu<br/>
                            <input type="radio" name="day-id" onClick={()=>{setSelectedDayId(5)}}/>Fri<br/>
                            <input type="radio" name="day-id" onClick={()=>{setSelectedDayId(6)}}/>Sat<br/>
                            <input type="radio" name="day-id" onClick={()=>{setSelectedDayId(7)}}/>Sun<br/>
                        </div>
                        <br/>
                        <div>
                            <b>Enter time:</b><br/>
                            Hour: <input type="number" value={hour} onChange={(e)=>{setHour(e.target.value)}}/><br/>
                            Minute: <input type="number" value={minute} onChange={(e)=>{setMinute(e.target.value)}}/><br/>
                            <button onClick={addAppointment}>Add</button>
                        </div>
                        </>
                    }
                    {
                        appointmentType === 2 &&
                        <>
                        <div>
                            <b>Select date:</b><br/>

                            <input type="date" onChange={(e)=>{setSelectedDate(e.target.value)}}/><br/>
                        </div>
                        <br/>
                        <div>
                            <b>Enter time:</b><br/>
                            Hour: <input type="number"  value={hour} onChange={(e)=>{setHour(e.target.value)}}/><br/>
                            Minute: <input type="number" value={minute} onChange={(e)=>{setMinute(e.target.value)}}/><br/>
                            <button onClick={addAppointment}>Add</button>
                        </div>
                        </>
                    }
                </div>
            }
            <br/>
            <div>
                {
                    edit ?
                    <>
                    <button onClick={handleSave}>Save</button>
                    <button onClick={()=>{setEdit(false)}}>Cancel</button>
                    </>
                    :
                    <>
                    <button onClick={handleEdit}>Edit</button>
                    <button>Delete</button>
                    </>
                }
            </div>
        </div>
        
        </>
    )
}

export default AdminProductCard;