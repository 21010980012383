import { useEffect, useState } from 'react'
import { useSelector, useDispatch, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ProductsActionCreators from '../../../User/store/actionCreators/products';
import * as ReservationActionCreators from '../../../User/store/actionCreators/reservation';
import axios from 'axios';
import Loading from '../../../User/components/loading/loading';
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants'
import '../../assets/styles/CreateProduct/CreateProduct.scss'
import ProductComponentCard from '../product-component-card/product-component-card';

function CreateProduct(props) {

    const { product, setSelectedOption } = props

    const [step, setStep] = useState(1)

    const productsSession = useSelector((state) => state.productsSession)

    const dispatch = useDispatch();
    const {
        FetchProductsData
    } = bindActionCreators({ ...ProductsActionCreators, ...ReservationActionCreators }, dispatch)

    const [nameEN, setNameEN] = useState("")
    const [nameSR, setNameSR] = useState("")
    const [descriptionEN, setDescriptionEN] = useState("")
    const [descriptionSR, setDescriptionSR] = useState("")
    const [pricePerPerson, setPricePerPerson] = useState("")
    const [discountPerPerson, setDiscountPerPerson] = useState("")
    const [discountIfMoreThan, setDiscountIfMoreThan] = useState("")
    const [maxNumberOfPersons, setMaxNumberOfPersons] = useState("")
    const [minNumberOfPersons, setMinNumberOfPersons] = useState("")
    const [isEscapeRoom, setIsEscapeRoom] = useState(true)

    //images for components
    var images = []

    useEffect(() => {
        if (product !== undefined) {
            images.push(product.mainProductComponent.imageName)
            product.productByAditionalComponents.length > 0 &&
                product.productByAditionalComponents.forEach((component) => images.push(component.aditionalProductComponent.imageName))
        }
    })

    //edit
    const [edit, setEdit] = useState(false)

    const handleEdit = () => {
        setEdit(true)
        /*setNameEN(product.nameTranslation.en)
        setNameSR(product.nameTranslation.sr)
        setDescriptionEN(product.descriptionTranslation.en)
        setDescriptionSR(product.descriptionTranslation.sr)
        setDiscountPerPerson(product.discountPerPerson)
        setDiscountIfMoreThan(product.discountIfMoreThan)
        setMaxNumberOfPersons(product.maxNumberOfPersons)
        setMinNumberOfPersons(product.minNumberOfPersons)*/
    }

    const handleSave = () => {
        /*if(StartingAppointmentTimeIdsForDelete.length > 0)
            deleteStartingAppointmentTimes()
        setEdit(false)
        FetchProductsData()*/
    }

    //main product components
    const [mainProductComponents, setMainProductComponents] = useState([])

    const GetMainProductComponents = async () => {
        await axios.get(backendRoot + "api/MainProductComponents/Get")
            .then((response) => {
                setMainProductComponents(response.data)
            })
            .catch((error) => {
                //console.log(error);
            })
    }

    const [selectedRoom, setSelectedRoom] = useState(null)

    //additional product components
    const [additionalProductComponents, setAdditionalProductComponents] = useState([])

    const GetAdditionalProductComponents = async () => {
        await axios.get(backendRoot + "api/AdditionalProductComponents/Get")
            .then((response) => {
                setAdditionalProductComponents(response.data)
            })
            .catch((error) => {
                //console.log(error);
            })
    }

    const [selectedAdditionals, setSelectedAdditionals] = useState([])

    const AddRemoveAditional = (component) => {
        var newArray = []
        selectedAdditionals.forEach((additional) => {
            if (additional.id !== component.id) {
                newArray.push(component)
            }
        })
        if (newArray.length === selectedAdditionals.length)
            newArray.push(component)
        setSelectedAdditionals(newArray)
    }



    //product types
    const [newProductType, setNewProductType] = useState()
    const [productTypes, setProductTypes] = useState([])


    useEffect(() => {
        if (newProductType && newProductType.name === "Package")
            setSelectedAdditionals([])
    }, [newProductType])

    const GetProductTypes = async () => {
        await axios.get(backendRoot + "api/ProductTypes/Get")
            .then((response) => {
                setProductTypes(response.data)
                setNewProductType(response.data[0])
            })
            .catch((error) => {
                //console.log(error);
            })
    }

    useEffect(() => {

        if (mainProductComponents.length === 0) {
            GetMainProductComponents()
        }
        if (additionalProductComponents.length === 0) {
            GetAdditionalProductComponents()
        }
        if (productTypes.length === 0) {
            GetProductTypes()
        }
    }, [productTypes, mainProductComponents, additionalProductComponents])

    //translations

    const [nameTranslation, setNameTranslation] = useState()
    const [descriptionTranslation, setDescriptionTranslation] = useState()

    const CreateTranslation = async (uiName, sr, en) => {
        await axios.post(backendRoot + "api/Translations/Create", {
            uiName: uiName,
            sr: sr,
            en: en
        })
            .then((response) => {
                //console.log(response)
                if (uiName === "ADDITIONAL_PRODUCT_COMPONENT_NAME")
                    setNameTranslation(response.data)
                else {
                    setDescriptionTranslation(response.data)
                }
            })
            .catch((error) => {
                //console.log(error);
            })
    }

    //product

    const CreateProduct = async () => {
        //console.log(nameTranslation)
        //console.log(descriptionTranslation)
        await axios.post(backendRoot + "api/Products/Create", {
            pricePerPerson: pricePerPerson,
            discountPerPerson: discountPerPerson,
            discountIfMoreThan: discountIfMoreThan,
            minNumberOfPersons: minNumberOfPersons,
            maxNumberOfPersons: maxNumberOfPersons,
            mainProductComponentId: selectedRoom.id,
            nameTranslationId: nameTranslation.id,
            descriptionTranslationId: descriptionTranslation.id,
            productTypeId: newProductType.id,
            productTypeName: isEscapeRoom ? "escape_room" : "virtual_reality"
        })
            .then((response) => {
                //console.log(response)
                if (selectedAdditionals.length > 0) {
                    selectedAdditionals.forEach((component) => {
                        CreateProductByAdditionalComponent(response.data.id, component.id)
                    })
                }
            })
            .catch((error) => {
                //console.log(error);
            })
    }

    const CreateProductByAdditionalComponent = async (productId, additionalId) => {
        await axios.post(backendRoot + "api/ProductByAdditionalProductComponents/Create", {
            aditionalProductComponentsId: additionalId,
            productId: productId
        })
            .then((response) => {
                //console.log(response)
            })
            .catch((error) => {
                //console.log(error);
            })
    }

    const handleStep2 = async () => {
        setStep(3)
        if (nameEN !== "" && nameSR !== "" && descriptionEN !== "" && descriptionSR !== "") {
            await CreateTranslation("ADDITIONAL_PRODUCT_COMPONENT_NAME", nameSR, nameEN)
            await CreateTranslation("ADDITIONAL_PRODUCT_COMPONENT_DESCRIPTION", descriptionSR, descriptionEN)
        }
    }

    const handleCreateProduct = async () => {
        await CreateProduct()
    }

    const [disabledNext1Button, setDisabledNext1Button] = useState(false)

    useEffect(() => {
        if (selectedRoom !== null) {
            setDisabledNext1Button(false)
        }
        else {
            setDisabledNext1Button(true)
        }
    }, [selectedRoom])

    const [disabledNext2Button, setDisabledNext2Button] = useState(false)

    useEffect(() => {
        if (nameEN !== "" && nameSR !== "" && descriptionEN !== "" && descriptionSR !== "") {
            setDisabledNext2Button(false)
        }
        else {
            setDisabledNext2Button(true)
        }
    }, [nameEN, nameSR, descriptionEN, descriptionSR])

    return (
        <>
            {productsSession.loadingProducts && <Loading />}
            <div className="create-product-container">


                {step === 1 &&
                    <>
                        <div><h5>Select product type: </h5><br />
                            {
                                productTypes.map((type) => {
                                    return (
                                        <>
                                            <input className="productType" key={type.id} type="radio" name="productType" onChange={() => { setNewProductType(type) }} /> {type.name}
                                        </>
                                    )
                                })
                            }
                        </div><br />

                        <div><h5>Escape room or virtual reality: </h5><br />
                            <input className="productType" checked={isEscapeRoom} type="radio" name="productTypeName" onChange={() => { setIsEscapeRoom(true) }} /> Escape room
                            <input className="productType" checked={!isEscapeRoom} type="radio" name="productTypeName" onChange={() => { setIsEscapeRoom(false) }} /> Virtual reality
                        </div><br />

                        <div><h5>Select room:</h5></div>
                        <div><button onClick={() => { setSelectedOption(4) }}>Create new</button></div><br />
                        <div className="create-product-main-components-container">
                            {mainProductComponents && mainProductComponents.map((component, index) => {
                                return (
                                    <ProductComponentCard component={component} index={index} selected={selectedRoom && component.id === selectedRoom.id} handleSelect={setSelectedRoom} newProductType={newProductType} />
                                )
                            })}
                        </div>
                        {newProductType && newProductType.name === "Package" &&
                            <>
                                <br />
                                <div><h5>Select additionals:</h5></div>
                                <div><button onClick={() => { setSelectedOption(4) }}>Create new</button></div><br />
                                <div className="create-product-main-components-container">
                                    {additionalProductComponents && additionalProductComponents.map((component, index) => {
                                        return (
                                            <ProductComponentCard component={component} index={index} selected={selectedAdditionals.includes(component)} handleSelect={AddRemoveAditional} newProductType={newProductType} />
                                        )
                                    })}
                                </div>
                            </>}
                        <button onClick={() => { setStep(2) }} disabled={disabledNext1Button}>Next</button>
                    </>}



                {step === 2 &&
                    <>
                        <div><h5>Enter name(EN): </h5><input value={nameEN} onChange={(e) => { setNameEN(e.target.value) }} /></div><br />
                        <div><h5>Enter name(SR): </h5><input value={nameSR} onChange={(e) => { setNameSR(e.target.value) }} /></div><br />
                        <div style={{ width: "100%" }}><h5>Enter description(EN): </h5><textarea value={descriptionEN} onChange={(e) => { setDescriptionEN(e.target.value) }} /></div><br />
                        <div style={{ width: "100%" }}><h5>Enter description(SR): </h5><textarea value={descriptionSR} onChange={(e) => { setDescriptionSR(e.target.value) }} /></div><br />
                        <div className="create-room-buttons-container-1">
                            <button style={{ visibility: "hidden" }}>Cancel</button>
                            <div>
                                <button style={{ visibility: "hidden" }}>Save</button>
                                <button onClick={handleStep2} disabled={disabledNext2Button}>Next</button>
                            </div>
                        </div>
                    </>}



                {step === 3 &&
                    <>
                        <div><h5>Price per person: </h5><br /><input value={pricePerPerson} onChange={(e) => { setPricePerPerson(e.target.value) }} /></div>
                        <div><h5>Discount per person: </h5><br /><input value={discountPerPerson} onChange={(e) => { setDiscountPerPerson(e.target.value) }} /></div>
                        <div><h5>Discount per person if more than: </h5><br /><input value={discountIfMoreThan} onChange={(e) => { setDiscountIfMoreThan(e.target.value) }} /></div>
                        <div><h5>Max number of persons: </h5><br /><input value={maxNumberOfPersons} onChange={(e) => { setMaxNumberOfPersons(e.target.value) }} /></div>
                        <div><h5>Min number of persons: </h5><br /><input value={minNumberOfPersons} onChange={(e) => { setMinNumberOfPersons(e.target.value) }} /></div>
                        <br />
                        <div><button onClick={handleCreateProduct}>Create</button></div>
                    </>}
            </div>
        </>
    )
}

export default CreateProduct;