import '../../assets/styles/Footer/Footer.scss'
import GoogleMapReact from 'google-map-react';

import mapImage from '../../assets/images/Footer/Theater1-min.jpeg'
import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

function Footer(props){

    const { t, i18n } = useTranslation()
    const {numberVisitors} = props

    const defaultProps = {
        center: {
          lat: 43.3211221,
          lng: 21.8987715
        },
        zoom: 15
      };

    const [map, setMap] = useState(null)

    return(
        <>
        {
            map !== null &&
            <div className="preview-map" onClick={()=>{setMap(null)}}>
                <img src={map} alt={"map"} className="preview-map-image"/>
            </div>
        }
        <div className="footer-container">
            <div className="footer-map-container">
                {/*<GoogleMapReact
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                </GoogleMapReact>*/}
                {/*<img src={localmap} alt={"localmap"} onClick={()=>{setMap(localmap)}}/>
                <img src={bigmap} alt={"bigmap"} onClick={()=>{setMap(bigmap)}}/>*/}
                <img src={mapImage} alt={"localmap"} onClick={()=>{setMap(mapImage)}}/>
            </div>
            <div className="footer-info-container">
                Orlovića Pavla 12a<br/>
                Niš<br/>
                {t('Phone')}: 061 1574924<br/><br/>
                <a href='https://www.instagram.com/escaperoomcegar/' target={"_blank"} style={{color: "white"}}><Icon.Instagram style={{ color: "white", fontSize: "50px", marginBottom: "10px", cursor: "pointer" }} /> <b style={{color: "white"}}>escaperoomcegar</b></a>
                <br/><br/>
                <b><Link style={{ color: "white" }} to={i18n.language === "rs" ? "/privacy-policy-RS" : "/privacy-policy-EN"} target="_blank">{t('PrivacyPolicy1')}</Link></b>
            </div>
        </div>
        </>
    )
}

export default Footer;