import {
    RESERVATION_DATE_SET,
    RESERVATION_TIME_SET,
    RESERVATION_MAIN_COMPONENT_ID_SET,
    RESERVATION_STARTING_APPOINTMENT_TIME,
    RESERVATION_APPOINTMENT_RESERVATION,
    RESERVATION_USER,
    RESERVATION_RECEIPT,
    RESERVATION_TIME_CLEAR,
    RESERVATION_CLEAR_ALL,
    RESERVATION_START_LOADING,
    RESERVATION_FINISH_LOADING,
    RESERVATION_SET_ERROR
} from '../actionTypes/actionTypes'

import { backendRoot } from '../../assets/apiConstants/ApiConstants'
import axios from 'axios'




export const createReservedAppointmentTime = (date,hour,minute,mainComponetnId) => {
    return async (dispatch) =>{
        dispatch(reservationStartLoading("loading"))
        var route = backendRoot + 'api/ReservedAppointmentTimes/Create'

        axios.post(route,{
            mainProductComponentId: mainComponetnId,
            date: date,
            hour: hour,
            minute: minute
          })
            .then((response) =>{
                    //console.log("----------------------------------------------------------",response.data)
                    dispatch(setStartingAppointmentTime(response.data))
                    //console.log("uspesno kreiran termin")
            })
            .catch((error) =>{
                // handle error
                //console.log("already exists")
                dispatch(setError("already exists"))
                dispatch(reservationFinishLoading())
                //console.log(error);
            })
    }
}

export const createAppointmentReservation = (productId, reservedAppointmentTimeId, teamName, numberOfPerson) => {
    return async (dispatch) =>{
        var route = backendRoot + 'api/AppointmentReservations/Create'

        axios.post(route,{
            productId: productId,
            reservedAppointmentTimeId: reservedAppointmentTimeId,
            teamName: teamName,
            numberOfPerson: numberOfPerson,
            timeToFinishInMinutes: 0,
            seccessfulltyCompleted: false
          })
            .then((response) =>{
                    dispatch(setAppointmentReservation(response.data))
                    //console.log("uspesno kreirana rezervacija")
            })
            .catch((error) =>{
                // handle error
                dispatch(reservationFinishLoading())
                //console.log(error);
            })
    }
}

export const createIndividual = (name,lastname,email,phone) => {
    return async (dispatch) =>{
        var route = backendRoot + 'api/Individuals/Create'

        axios.post(route,{
            name: name,
            lastname: lastname,
            email: email,
            phone: phone
          })
            .then((response) =>{
                    dispatch(setUser(response.data))
                    //console.log("uspesno kreiran user")
            })
            .catch((error) =>{
                // handle error
                dispatch(reservationFinishLoading())
                //console.log(error);
            })
    }
}

export const createLegalEntity = (name,lastname,email,companyName,pib,address,city,postalCode,phone) => {
    return async (dispatch) =>{
        var route = backendRoot + 'api/LegalEntities/Create'

        axios.post(route,{
            isClient: true,
            companyName: companyName,
            pib: pib,
            address: address,
            city: city,
            postalCode: postalCode,
            name: name,
            lastname: lastname,
            email: email,
            phoneNumber: phone
          })
            .then((response) =>{
                    dispatch(setUser(response.data))
                    //console.log("uspesno kreiran user")
            })
            .catch((error) =>{
                // handle error
                dispatch(reservationFinishLoading())
                //console.log(error);
            })
    }
}

export const createReceipt = (appointmentReservationId,individualId,legalEntityId,price) => {
    return async (dispatch) =>{
        var route = backendRoot + 'api/Receipts/Create'

        axios.post(route,{
            paymentTypeId: 1,
            appointmentReservationId: appointmentReservationId,
            individualId: individualId ? individualId : null,
            legalEntityId: legalEntityId ? legalEntityId : null,
            price: price,
            isPaid: false
          })
            .then((response) =>{
                    //console.log("uspesno kreirana priznanica")
                    dispatch(setReceipt(response.data))
                    dispatch(reservationFinishLoading())
            })
            .catch((error) =>{
                dispatch(reservationFinishLoading())
                // handle error
                //console.log(error);
            })
    }
}

export const setReservationDate = (year, month, date, day) => {
    return (dispatch) => {
        dispatch(setDate(year, month, date, day))
    };
};
export const setReservationTime = (hour, minute) => {
    return (dispatch) => {
        dispatch(setTime(hour, minute))
    };
};
export const clearReservationTime = () => {
    return (dispatch) => {
        dispatch(clearTime())
    };
};
export const setReservationProduct = (product) => {
    return (dispatch) => {
        dispatch(setProduct(product))
    };
};
export const clearReservation = () => {
    return (dispatch) => {
        dispatch(clearAll())
    };
};
export const setReservationError = (message) => {
    return (dispatch) => {
        dispatch(setError(message))
    };
};
export const reservationStartLoading = (message) => {
    return (dispatch) => {
        dispatch(startLoading(message))
    };
};

export const reservationFinishLoading = () => {
    return (dispatch) => {
        dispatch(finishLoading())
    };
};
const setDate = (year, month, date, day) => {
    return {
        type: RESERVATION_DATE_SET,
        payload: {year: year, month: month, date: date, day: day}
    }
}
const setTime = (hour, minute) => {
    return {
        type: RESERVATION_TIME_SET,
        payload: {hour: hour, minute: minute}
    }
}
const clearTime = () => {
    return {
        type: RESERVATION_TIME_CLEAR,
    }
}
const setProduct = (product) => {
    return {
        type: RESERVATION_MAIN_COMPONENT_ID_SET,
        payload: product
    }
}
const setStartingAppointmentTime = (data) => {
    return {
        type: RESERVATION_STARTING_APPOINTMENT_TIME,
        payload: data
    }
}
const setAppointmentReservation = (data) => {
    return {
        type: RESERVATION_APPOINTMENT_RESERVATION,
        payload: data
    }
}
const setUser = (data) => {
    return {
        type: RESERVATION_USER,
        payload: data
    }
}
const setReceipt = (data) => {
    return {
        type: RESERVATION_RECEIPT,
        payload: data
    }
}
const clearAll = () => {
    return {
        type: RESERVATION_CLEAR_ALL
    }
}
const startLoading = (message) => {
    return {
        type: RESERVATION_START_LOADING,
        payload: message
    }
}
const finishLoading = () => {
    return {
        type: RESERVATION_FINISH_LOADING
    }
}
const setError = (message) => {
    return {
        type: RESERVATION_SET_ERROR,
        payload: message
    }
}