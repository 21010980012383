import '../../assets/styles/ReservationProducts/ReservationProducts.scss'
import ProductCard from '../product-card/product-card';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

function ReservationProducts(props){

    const {productType, userType, isOverview, setIsEscapeRoom} = props

    const allProducts = useSelector((state) => state.productsSession.allProducts)
    const selectedDate = useSelector((state) => state.reservationSession.date)
    const availabilities = useSelector((state) => state.productsSession.availabilities)
    const loadingProducts = useSelector((state) => state.productsSession.loadingProducts)

    const [products, setProducts] = useState([])

    useEffect(()=>{
        //console.log(allProducts)
        var prod = []
        allProducts.forEach(p => {
            if(p.productTypeId === productType)
            {
                prod.push(p)
            }
                
        });
        setProducts(allProducts)
    },[allProducts])

    return(
        <div className="reservation-products-main-container">
            <div className="reservation-products-container">
                
                    {products && products.map((product,index)=>
                        <ProductCard key={index} product={product} isOverview={isOverview} userType={userType} setIsEscapeRoom={setIsEscapeRoom}/>
                    )}
                
            </div>
        </div>
    )
}

export default ReservationProducts;