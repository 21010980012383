import {useState} from 'react'
import { Link } from "react-router-dom";
import { backendRoot } from "../../../User/assets/apiConstants/ApiConstants";
import '../../assets/styles/AdminNavbar/AdminNavbarV2.scss'
import { List } from "react-bootstrap-icons";
import axios from 'axios';
import { useCookies } from 'react-cookie';

import * as UserActionCreators from '../../store/actionCreators/user';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import {PersonFill, BoxArrowInLeft} from 'react-bootstrap-icons'
import { useEffect } from 'react';


function AdminNavbar(){

    const userSession = useSelector((state) => state.userSession)
    const dispatch = useDispatch();
    const { TryLogout } = bindActionCreators({ ...UserActionCreators}, dispatch)

    const [accountMenuIsOpen, setAccountMenuIsOpen] = useState(false)
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const handleLogout = () => {
        TryLogout()
    }

    const OpenMenu = () =>{
        var element = document.getElementById('admin-left-menu')

        if(element !== null && element !== undefined)
        {
            element.className = "left-admin-panel-menu open-menu-v2"
        }
    }

    return(
        <div className="admin-navbar-container">
            <List onClick={OpenMenu}/>
            <div>
                <div className="admin-navbar-account-container">
                    <span className="d-none d-sm-none d-md-none d-lg-block">{userSession.data && userSession.data.name + ' ' + userSession.data.lastname}</span>
                    <div className="admin-navbar-account-container-img" style={{backgroundImage: userSession.data && "url("+backendRoot + "ProfileImages/" + userSession.data.imageName + ")" , backgroundSize: "cover", backgroundPosition: "50% 50%"}}  onClick={()=>{setAccountMenuIsOpen(!accountMenuIsOpen);setMenuIsOpen(false)}}/>
                </div>
                {accountMenuIsOpen &&
                <div className="admin-navbar-account-menu">
                    <Link className="admin-navbar-account-option" to="admin/profile"><PersonFill/> Profile</Link>
                    <div className="admin-navbar-account-option" onClick={handleLogout}><BoxArrowInLeft/> Log out</div>
                </div>
                }
            </div>
        </div>
    )
}

export default AdminNavbar;