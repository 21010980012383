

function ConfirmationModal(props){

    const {handleYes, handleNo, message, isOpen} = props

    return (
        <div style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)", display: isOpen ? "flex":"none", justifyContent: "center", alignItems: "center"}}>
            <div style={{width: "95%", maxWidth: "500px", backgroundColor: "white"}}>
                <div style={{width: "100%", padding: "10px", backgroundColor: "rgb(220,53,69)", color: "white", fontSize: "large"}}>
                    <b>Are you sure?</b>
                </div>
                <div style={{color: "rgb(150,150,150)", padding: "20px 10px"}}>
                    {message}
                </div>
                <div style={{}}>
                    <button onClick={handleYes} style={{backgroundColor: "rgb(220,53,69)", border: "1px solid rgb(220,53,69)", color: "white", borderRadius: "5px", margin: "10px", width: "60px", padding: "10px"}}>Yes</button>
                    <button onClick={handleNo} style={{backgroundColor: "white", border: "1px solid rgb(150,150,150)", color: "rgb(150,150,150)", borderRadius: "5px", margin: "10px", width: "60px", padding: "10px"}}>No</button>
                </div>
            </div>
            
        </div>
    )
}

export default ConfirmationModal;