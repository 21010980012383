import React, { useEffect, useState } from "react";

import "../../../User/assets/styles/Reservation/Reservation.scss"

import * as ProductsActionCreators from "../../../User/store/actionCreators/products"
import * as ReservationActionCreators from '../../../User/store/actionCreators/reservation';

import { useSelector, useDispatch, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios'
import { google } from "google-map-react"
import { useTranslation } from "react-i18next";
import ReservationCalendar from "../../../User/components/reservation-calendar/reservation-calendar";
import ReservationProducts from "../../../User/components/reservation-products/reservation-products";
import Loading from "../../../User/components/loading/loading";
import { backendRoot } from "../../../User/assets/apiConstants/ApiConstants";
import ReceiptCard from "../receipt-card/receipt-card";
import ReceiptCardDetails from "../receipt-card/receipt-card-details";
import ConfirmationModal from "../confirmation-modal/confirmation-modal";




function ReservationCalendarOverview(props) {

    const { sendMessage, message, setMessage, clientKey, userType, isOverview, daysWithoutAppointments } = props

    const { t } = useTranslation()


    const productsSession = useSelector((state) => state.productsSession)
    const reservationSession = useSelector((state) => state.reservationSession)
    const dispatch = useDispatch();
    const { setReservationDate, FetchProductsData } = bindActionCreators({ ...ProductsActionCreators, ...ReservationActionCreators }, dispatch)

    const [loading, setLoading] = useState(false)

    const [isEscapeRoom, setIsEscapeRoom] = useState(true)

    const [receipts, setReceipts] = useState(null)
    const [receipt, setReceipt] = useState(null)
    const [receiptsByDate, setReceiptsByDate] = useState(null)
    const [receiptIdToDelete, setReceiptIdToDelete] = useState(null)

    const [productsByDate, setProductsByDate] = useState(null)

    const GetProductsByDate = async () => {
        if(reservationSession.date.year !== 0)
        {
        var selectedDate = new Date(reservationSession.date.year, reservationSession.date.month - 1, reservationSession.date.date)
        setLoading(true)
        await axios.post(backendRoot + "api/Products/Get?Year=2023&Month=1&Day=4", {},{
            'Access-Control-Allow-Origin': 'https://localhost:3000'
        })
            .then((response) => {
                console.log(response)
                setProductsByDate(response.data)
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
            })
        }
    }

    const GetReceiptsByDate = async () => {
        if(reservationSession.date.year !== 0)
        {
        var selectedDate = new Date(reservationSession.date.year, reservationSession.date.month - 1, reservationSession.date.date)
        setLoading(true)
        await axios.get(backendRoot + "api/Receipts/GetByDate/" + selectedDate.toDateString())
            .then((response) => {
                console.log(response)
                setReceiptsByDate(response.data)
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        if (reservationSession.date.year !== 0) {
            //GetProductsByDate()
            GetReceiptsByDate()
        }
    }, [reservationSession.date])

    const filterReceipts = () => {
        if (receiptsByDate !== null && reservationSession.time.hour !== null && reservationSession.time.minute !== null && reservationSession.product !== null) {
            var disolayedReceipt = receiptsByDate.filter(receipt => receipt?.appointmentReservation?.reservedAppointmentTime?.hour === reservationSession.time.hour && receipt?.appointmentReservation?.reservedAppointmentTime?.minute === reservationSession.time.minute && receipt?.appointmentReservation?.productId === reservationSession.product.id)
            setReceipt(disolayedReceipt[0] === undefined ? null : disolayedReceipt[0])
        }
    }

    useEffect(() => {
        if (reservationSession.time.hour !== null && reservationSession.time.minute !== null) {
            filterReceipts()
        }
        else
            setReceipt(null)
    }, [reservationSession.time])

    const DeleteReceipt = async (id) => {
        setLoading(true)
        await axios.post(backendRoot + "api/Reservation/Delete/" + id,{},{
            withCredentials: true
        })
            .then((response) => {
                //console.log(response.data)
                setReceipts(null)
                setLoading(false)
                FetchProductsData("admin")
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                //console.log(error);
            })
    }

    const handleUpdate = () => {
        FetchProductsData("admin")
    }

    return (
        <>
            {productsSession.loadingProducts && <Loading />}
            {loading && <Loading />}
            <div className="reservation-main-admin-container">

                <div className="reservation-admin-container">
                <div className="section-title">
                Select date
            </div>
                    <ReservationCalendar daysWithoutAppointments={daysWithoutAppointments} isOverview={isOverview}/>

                    <ReservationProducts productType={1} userType={"admin"} isOverview={isOverview} setIsEscapeRoom={setIsEscapeRoom} />

                    <div>
                        {receipt !== null &&
                        <ReceiptCardDetails receipt={receipt} DeleteReceipt={()=>{setReceiptIdToDelete(receipt.id)}} handleUpdate={handleUpdate}/>
                        }
                    </div>
                </div>
            </div>
            <ConfirmationModal isOpen={receiptIdToDelete !== null} message={"Are you sure you want to delete this reservation?"} handleYes={()=>{DeleteReceipt(receiptIdToDelete)}} handleNo={()=>{setReceiptIdToDelete(null)}}/>
        </>
    );
}

export default ReservationCalendarOverview