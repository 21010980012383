import { useState, useEffect } from 'react'
import axios from 'axios'
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants'

function VoucherMessage() {

    const [currentMessage, setCurrentMessage] = useState(null)

    const [message, setMessage] = useState("")
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const getMessage = () => {
        axios.get(backendRoot + "api/Vouchers/GetMessage")
            .then((response) => {
                //console.log(response)
                setCurrentMessage(response.data)
            })
            .catch((error) => {
                // handle error
                //console.log(error);
            })
    }


    useEffect(() => {
        if (currentMessage === null)
            getMessage()
    }, [message])

    const SetMessage = () => {
        if (message !== "" && startDate !== null && endDate !== null) {
            axios.post(backendRoot + "api/Vouchers/SetMessage", {
                message: message,
                startDate: startDate,
                endDate: endDate
            })
                .then((response) => {
                    //console.log(response)
                    getMessage()
                })
                .catch((error) => {
                    // handle error
                    //console.log(error);
                })
        }
    }

    return (
        <>
            <div className="admin-panelpage-title">
                Vouchers / Voucher message
            </div>
            <div className="left-admin-panel-menu-logo">
                Current message
            </div>
            <div style={{ backgroundColor: "white", margin: 10, borderRadius: 5, padding: 10, color: "rgb(150,150,150)" }}>

                Message: {currentMessage?.message}<br /><br />
                Start date: {currentMessage?.startDate}
                <br /><br />
                End date: {currentMessage?.endDate}

            </div>
            <div className="left-admin-panel-menu-logo">
                Set new message
            </div>
            <div style={{ backgroundColor: "white", margin: 10, borderRadius: 5, padding: 10, color: "rgb(150,150,150)" }}>
                Enter message:
                <br />
                <input type="text" value={message} onChange={(e) => { setMessage(e.target.value) }} /><br /><br />
                Start date:<br />
                <input type="date" onChange={(e) => { setStartDate(e.target.value) }} /><br /><br />
                End date:<br />
                <input type="date" onChange={(e) => { setEndDate(e.target.value) }} />
                <br /><br />
                <button onClick={SetMessage} style={{ border: "1px solid rgb(60, 75, 100)", backgroundColor: "rgb(60, 75, 100)", margin: 5, color: "white", borderRadius: 5 }}>Set message</button>
            </div>
        </>
    )
}

export default VoucherMessage