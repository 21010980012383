import react, {useState, useEffect} from "react";
import '../../assets/styles/Home/Home.scss'
import DownArrow from '../../assets/images/Home/down_arrow.png'
import '../../assets/styles/HomeAccordion/HomeAccordion.scss'
import { useTranslation } from 'react-i18next';

function HomeAccordion(){

    const {t,i18n} = useTranslation()

    const [reverse,setReverse] = useState(false)
    const [accordionItem1Height,setAccordionItem1Height] = useState(0)
    const [accordionItem2Height,setAccordionItem2Height] = useState(0)
    const [accordionItem3Height,setAccordionItem3Height] = useState(0)
    const [accordionItem4Height,setAccordionItem4Height] = useState(0)
    const [accordionItem5Height,setAccordionItem5Height] = useState(0)

    const [accordionItem1Reverse,setAccordionItem1Reverse,] = useState(false)
    const [accordionItem2Reverse,setAccordionItem2Reverse,] = useState(false)
    const [accordionItem3Reverse,setAccordionItem3Reverse,] = useState(false)
    const [accordionItem4Reverse,setAccordionItem4Reverse,] = useState(false)
    const [accordionItem5Reverse,setAccordionItem5Reverse,] = useState(false)

    useEffect(()=>{
        if(accordionItem1Height === 0)
        {
            setAccordionItem1Height(document.getElementById("accordion-item-1").clientHeight);
            setAccordionItem2Height(document.getElementById("accordion-item-2").clientHeight);
            setAccordionItem3Height(document.getElementById("accordion-item-3").clientHeight);
            setAccordionItem4Height(document.getElementById("accordion-item-4").clientHeight);
            setAccordionItem5Height(document.getElementById("accordion-item-5").clientHeight);
        }
        if(accordionItem1Height === 0)
        {
            document.getElementById("accordion-item-1").style.height = "0px";
            document.getElementById("accordion-item-2").style.height = "0px";
            document.getElementById("accordion-item-3").style.height = "0px";
            document.getElementById("accordion-item-4").style.height = "0px";
            document.getElementById("accordion-item-5").style.height = "0px";
        }
    })
    const OpenCloseAcordionItem1 = (item) =>{
        var arrow = document.getElementById("image-2-1")
        var interval;
        var running = false;
        var element = document.getElementById("accordion-item-1")
        var height;

        if(accordionItem1Reverse)
            height = accordionItem1Height;
        else 
            height = 0
        
        //console.log(element.style.height)
        if ( running === false ) {
            running = true;
            interval = setInterval(function(){
                if ( accordionItem1Reverse === false) {
                    arrow.style.transform = "rotate(180deg)"
                    if(height < accordionItem1Height){
                        height += 10;
                    }
                    else{
                        element.style.height = accordionItem1Height+"px"
                        setAccordionItem1Reverse(true)
                        clearInterval(interval);
                        return
                    }
                }
                else {
                    arrow.style.transform = "rotate(0deg)"
                    if(height > 0)
                    {
                    height -= 10;
                    }
                    else{
                        element.style.height = "0px"
                        setAccordionItem1Reverse(false)
                        clearInterval(interval);
                        return
                    }
                }
              
              element.style.height = Math.round(height) + "px";
            },1);
          }
    }

    const OpenCloseAcordionItem2 = (item) =>{
        var arrow = document.getElementById("image-2-2")
        var interval;
        var running = false;
        var element = document.getElementById("accordion-item-2")
        var height;
        if(accordionItem2Reverse)
            height = accordionItem2Height;
        else 
            height = 0
        
        //console.log(element.style.height)
        if ( running === false ) {
            running = true;
            interval = setInterval(function(){
              if ( accordionItem2Reverse === false) {
                    arrow.style.transform = "rotate(180deg)"
                    if(height < accordionItem2Height)
                    height += 10;
                    else{
                        element.style.height = accordionItem2Height+"px"
                        setAccordionItem2Reverse(true)
                        clearInterval(interval);
                        return
                    }
              }
              else {
                    arrow.style.transform = "rotate(0deg)"
                    if(height > 0)
                    {
                    height -= 10;
                    }
                    else{
                        element.style.height = "0px"
                        setAccordionItem2Reverse(false)
                        clearInterval(interval);
                        return
                    }
              }
              
              element.style.height = Math.round(height) + "px";
            },1);
          }
    }

    const OpenCloseAcordionItem3 = (item) =>{
        var arrow = document.getElementById("image-2-3")
        var interval;
        var running = false;
        var element = document.getElementById("accordion-item-3")
        var height;
        if(accordionItem3Reverse)
            height = accordionItem3Height;
        else 
            height = 0
        
        //console.log(element.style.height)
        if ( running === false ) {
            running = true;
            interval = setInterval(function(){
              if ( accordionItem3Reverse === false) {
                    arrow.style.transform = "rotate(180deg)"
                    if(height < accordionItem3Height)
                    height += 10;
                    else{
                        element.style.height = accordionItem3Height+"px"
                        setAccordionItem3Reverse(true)
                        clearInterval(interval);
                        return
                    }
              }
              else {
                    arrow.style.transform = "rotate(0deg)"
                    if(height > 0)
                    {
                    height -= 10;
                    }
                    else{
                        element.style.height = "0px"
                        setAccordionItem3Reverse(false)
                        clearInterval(interval);
                        return
                    }
              }
              
              element.style.height = Math.round(height) + "px";
            },1);
          }
    }

    const OpenCloseAcordionItem4 = (item) =>{
        var arrow = document.getElementById("image-2-4")
        var interval;
        var running = false;
        var element = document.getElementById("accordion-item-4")
        var height;
        if(accordionItem4Reverse)
            height = accordionItem4Height;
        else 
            height = 0
        
        //console.log(element.style.height)
        if ( running === false ) {
            running = true;
            interval = setInterval(function(){
              if ( accordionItem4Reverse === false) {
                    arrow.style.transform = "rotate(180deg)"
                    if(height < accordionItem4Height)
                    height += 10;
                    else{
                        element.style.height = accordionItem4Height+"px"
                        setAccordionItem4Reverse(true)
                        clearInterval(interval);
                        return
                    }
              }
              else {
                    arrow.style.transform = "rotate(0deg)"
                    if(height > 0)
                    {
                    height -= 10;
                    }
                    else{
                        element.style.height = "0px"
                        setAccordionItem4Reverse(false)
                        clearInterval(interval);
                        return
                    }
              }
              
              element.style.height = Math.round(height) + "px";
            },1);
          }
    }

    const OpenCloseAcordionItem5 = (item) =>{
        var arrow = document.getElementById("image-2-5")
        var interval;
        var running = false;
        var element = document.getElementById("accordion-item-5")
        var height;
        if(accordionItem5Reverse)
            height = accordionItem5Height;
        else 
            height = 0
        
        //console.log(element.style.height)
        if ( running === false ) {
            running = true;
            interval = setInterval(function(){
              if ( accordionItem5Reverse === false) {
                    arrow.style.transform = "rotate(180deg)"
                    if(height < accordionItem5Height)
                    height += 10;
                    else{
                        element.style.height = accordionItem5Height+"px"
                        setAccordionItem5Reverse(true)
                        clearInterval(interval);
                        return
                    }
              }
              else {
                    arrow.style.transform = "rotate(0deg)"
                    if(height > 0)
                    {
                    height -= 10;
                    }
                    else{
                        element.style.height = "0px"
                        setAccordionItem5Reverse(false)
                        clearInterval(interval);
                        return
                    }
              }
              
              element.style.height = Math.round(height) + "px";
            },1);
          }
    }
    return(
        <div className="home-accordion-container">
            <h1 className="home-accordion-heading">FAQ</h1>
            <div className="home-accordion-wrapper">
            <div className="home-accordion-item">
                <div className="home-accordion-item-trigger">
                    <h4 className="home-accordion-item-heading">{t('AccordionTip1')}</h4><img src={DownArrow} sizes="30px" alt="" className="home-accordion-item-arrow-image" id="image-2-1" onClick={OpenCloseAcordionItem1}/>
                </div>
                <div className="home-accordion-item-content" id="accordion-item-1">
                    <div className="home-accordion-item-content-text">{t('AccordionTip2')}</div>
                </div>
            </div>
            <div className="home-accordion-item">
                <div className="home-accordion-item-trigger">
                    <h4 className="home-accordion-item-heading">{t('AccordionTip3')}</h4><img src={DownArrow} sizes="30px" alt="" className="home-accordion-item-arrow-image" id="image-2-2" onClick={OpenCloseAcordionItem2}/></div>
                <div className="accordion-item-content" id="accordion-item-2">
                    <div className="home-accordion-item-content-text" id="text-block-8">{t('AccordionTip4')}</div>
                </div>
            </div>
            <div className="home-accordion-item">
                <div className="home-accordion-item-trigger">
                <h4 className="home-accordion-item-heading">{t('AccordionTip5')}</h4><img src={DownArrow} sizes="30px" alt="" className="home-accordion-item-arrow-image" id="image-2-3" onClick={OpenCloseAcordionItem3}/></div>
                <div className="home-accordion-item-content" id="accordion-item-3">
                <div className="home-accordion-item-content-text">{t('AccordionTip6')}<br/></div>
                </div>
            </div>
            <div className="home-accordion-item">
                <div className="home-accordion-item-trigger">
                <h4 className="home-accordion-item-heading">{t('AccordionTip7')}</h4><img src={DownArrow} sizes="30px" alt="" className="home-accordion-item-arrow-image" id="image-2-4" onClick={OpenCloseAcordionItem4}/></div>
                <div className="home-accordion-item-content" id="accordion-item-4">
                <div className="home-accordion-item-content-text">{t('AccordionTip8')}<a href="https://escapequest.rs/poklon-vaucer/"><strong></strong></a></div>
                </div>
            </div>
            <div className="home-accordion-item">
                <div className="home-accordion-item-trigger">
                <h4 className="home-accordion-item-heading">{t('AccordionTip9')}</h4><img src={DownArrow}  sizes="30px" alt="" className="home-accordion-item-arrow-image" id="image-2-5" onClick={OpenCloseAcordionItem5}/></div>
                <div className="home-accordion-item-content" id="accordion-item-5">
                <div className="home-accordion-item-content-text">{t('AccordionTip10')}<a href="mailto:reservation@escapequest.rs" target="_blank"></a></div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default HomeAccordion