
import {
    RESERVATION_DATE_SET,
    RESERVATION_TIME_SET,
    RESERVATION_MAIN_COMPONENT_ID_SET,
    RESERVATION_STARTING_APPOINTMENT_TIME,
    RESERVATION_APPOINTMENT_RESERVATION,
    RESERVATION_USER,
    RESERVATION_RECEIPT,
    RESERVATION_TIME_CLEAR,
    RESERVATION_CLEAR_ALL,
    RESERVATION_START_LOADING,
    RESERVATION_FINISH_LOADING,
    RESERVATION_SET_ERROR,
    RESERVATION_CREATE,
    RESERVATION_DELETE
} from '../actionTypes/actionTypes'

const initialState = {
    date: {year: 0, month: 0, date: 0, day: 0},
    time: {hour: null, minute: null},
    product: null,
    reservedAppointmentTime: null,
    appointmentReservation: null,
    user: null,
    receipt: null,
    loading: false,
    message: "",
    error: ""
}

export default (state = initialState, action) => {
    switch(action.type){
        case RESERVATION_DATE_SET:
            return {...state, date: {...state.date, year: action.payload.year, month: action.payload.month, date: action.payload.date, day: action.payload.day}}
        case RESERVATION_TIME_SET:
            return {...state, time: {...state.time, hour: action.payload.hour, minute: action.payload.minute}}
        case RESERVATION_TIME_CLEAR:
            return {...state, time: {...state.time, hour: null, minute: null}}
        case RESERVATION_MAIN_COMPONENT_ID_SET:
            return {...state, product: action.payload}
        case RESERVATION_STARTING_APPOINTMENT_TIME:
            return {...state, reservedAppointmentTime: action.payload}
        case RESERVATION_APPOINTMENT_RESERVATION:
            return {...state, appointmentReservation: action.payload}
        case RESERVATION_USER:
            return {...state, user: action.payload}
        case RESERVATION_RECEIPT:
            return {...state, receipt: action.payload}
        case RESERVATION_CLEAR_ALL:
            return {...state, receipt: null, user: null, appointmentReservation: null, reservedAppointmentTime: null, product: null}
        case RESERVATION_START_LOADING:
            return {...state, message: action.payload, loading: true}
        case RESERVATION_FINISH_LOADING:
            return {...state, message: "", loading: false}
        case RESERVATION_FINISH_LOADING:
            return {...state, message: "", loading: false}
        case RESERVATION_SET_ERROR:
            return {...state, error: action.payload}
        default:
            return state
    }
}