import '../../assets/styles/ProductCard/ProductCard.scss'
import Divider from '../divider/divider';
import { useState } from 'react';
import { useEffect } from 'react';

import * as ProductsActionCreators from '../../store/actionCreators/products';
import * as ReservationActionCreators from '../../store/actionCreators/reservation';

import { useSelector, useDispatch, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { backendRoot } from '../../assets/apiConstants/ApiConstants';



function ProductCard(props) {

    const dispatch = useDispatch();
    const { setReservationTime, setReservationProduct } = bindActionCreators({ ...ReservationActionCreators }, dispatch)

    const { t, i18n } = useTranslation()

    const selectedDate = useSelector((state) => state.reservationSession.date)
    const selectedTime = useSelector((state) => state.reservationSession.time)
    const selectedProduct = useSelector((state) => state.reservationSession.product)
    const { product, userType, isOverview, setIsEscapeRoom } = props

    const [reservedTimes, setReservedTimes] = useState([])
    const [availableTimes, setAvailableTimes] = useState([])
    const [displayedTimes, setDisplayedTimes] = useState([])
    const [loading, setLoading] = useState(false)



    const setData = () => {

        //console.log("SELECTED DATE: ",selectedDate)
        setDisplayedTimes([])

        var displayTimes = []
        var Availability;
        product.mainProductComponent.availabilities.forEach(availability => {
            if (availability.date !== null) {
                var availabilityDate = new Date(availability.date)
                if (availabilityDate.getFullYear() === selectedDate.year && availabilityDate.getMonth() + 1 === selectedDate.month && availabilityDate.getDate() === selectedDate.date)
                    Availability = availability
            }
            else {
                if (availability.dayId === selectedDate.day)
                    Availability = availability
            }
        });

        if (Availability !== null && Availability !== undefined)
            setAvailableTimes(Availability.startingAppointemntTimes)

        var reservedForDate = []

        product.mainProductComponent.reservedAppointmentTimes.forEach(reservedTime => {
            var reservedTimeDate = new Date(reservedTime.date)
            if (reservedTimeDate.getFullYear() === selectedDate.year && reservedTimeDate.getMonth() + 1 === selectedDate.month && reservedTimeDate.getDate() === selectedDate.date)
                reservedForDate.push(reservedTime)
        });

        setReservedTimes(reservedForDate)

        if (Availability !== null && Availability !== undefined) {
            Availability.startingAppointemntTimes.forEach(startTime => {
                var displayTime = { type: "available", hour: startTime.hour, minute: startTime.minute }
                reservedForDate.forEach(reservedTime => {
                    if (startTime.hour === reservedTime.hour && startTime.minute === reservedTime.minute)
                        displayTime = { type: "reserved", hour: startTime.hour, minute: startTime.minute }
                });
                displayTimes.push(displayTime)
            });

            /*var todayDate = new Date()
            if(todayDate.getFullYear() === selectedDate.year && todayDate.getMonth() + 1 === selectedDate.month && todayDate.getDate()===selectedDate.date)
            {
                var newDisplayedTimes = []
                displayTimes.forEach( time => {
                    if(time.hour > todayDate.getHours()+1)
                        newDisplayedTimes.push(time)
                })
                setDisplayedTimes(newDisplayedTimes)
            }
            else*/
            setDisplayedTimes(displayTimes)
        }

    }

    useEffect(() => {
        setData()
    }, [selectedDate, product, selectedProduct])

    useEffect(() => {
        setReservationTime(null, null)
    }, [selectedDate])

    const handleSetTime = (hour, minute) => {
        setReservationTime(hour, minute)
        setReservationProduct(product)
        setIsEscapeRoom(product.productTypeName === "escape_room")
    }

    var images = []
    images.push(product.mainProductComponent.imageName)

    product.productByAditionalComponents.length > 0 &&
        product.productByAditionalComponents.map((component) => images.push(component.aditionalProductComponent.imageName))




    return (
        <div className="product-card-container">
            {isOverview ?
                <div className="product-card-images">
                    <img style={{ background: "url(" + backendRoot + "Images/" + product.imageName + ")", backgroundSize: "cover", backgroundPositionX: "50%" }} className="product-card-image" />
                </div>
                :
                <Link to={`/user/details/${product.id}`}>

                    <div className="product-card-images">
                        <img style={{ background: "url(" + backendRoot + "Images/" + product.imageName + ")", backgroundSize: "cover", backgroundPositionX: "50%" }} className="product-card-image" />
                    </div>
                </Link>
            }
            <div className="product-card-primary-text">
                {product.nameTranslation.en}
            </div>
            {isOverview ?
                <div className="product-card-primary-text" style={{ color: "red" }}>
                    Reserved times
                </div>
                :
                <>
                    <div className="product-card-text">
                        Min {product.minNumberOfPersons}, Max {product.maxNumberOfPersons} {t('Persons')}
                    </div>
                    <div className="product-card-primary-text">
                        {t('AvailableTimes')}
                    </div>
                </>
            }
            {
                displayedTimes.length > 0 ?

                    <div className="product-card-available-times">
                        {
                            displayedTimes.map((time, index) =>
                                <div key={index}>
                                    {
                                        time.type === "available"
                                            ?
                                            <div className="product-card-available-time" style={{
                                                border: selectedTime.hour === time.hour && selectedTime.minute === time.minute && selectedProduct && selectedProduct.id === product.id && "1px solid rgb(84, 44, 85)",
                                                backgroundColor: selectedTime.hour === time.hour && selectedTime.minute === time.minute && selectedProduct && selectedProduct.id === product.id && "rgb(84, 44, 85)",
                                                color: selectedTime.hour === time.hour && selectedTime.minute === time.minute && selectedProduct && selectedProduct.id === product.id && "white",
                                                cursor: !isOverview ? "pointer" : "no-drop"
                                            }} onClick={() => { !isOverview && handleSetTime(time.hour, time.minute) }}>
                                                {time.hour < 10 ? "0" + time.hour : time.hour}:{time.minute < 10 ? "0" + time.minute : time.minute}
                                            </div>
                                            :
                                            <div className="product-card-available-time" style={{ filter: !isOverview && "blur(2px)", borderColor: isOverview ? "red": "black", color: isOverview && "red", cursor: isOverview ? "pointer" : "no-drop" }} onClick={() => { isOverview && handleSetTime(time.hour, time.minute) }}>
                                                {time.hour < 10 ? "0" + time.hour : time.hour}:{time.minute < 10 ? "0" + time.minute : time.minute}
                                            </div>
                                    }
                                </div>
                            )
                        }
                    </div>
                    :
                    <div style={{ color: "rgb(88,88,88)", textAlign: "center", width: "100%", padding: 30, fontWeight: "bold" }}>{t('NoAvailableTimes')}</div>
            }
        </div>
    );
}

export default ProductCard;