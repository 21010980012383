import react, {useEffect,useState} from 'react';
import { useParams } from "react-router-dom";
import '../../assets/styles/Details/Details.scss'
import axios from 'axios';
import { backendRoot } from '../../assets/apiConstants/ApiConstants';
import Loading from '../../components/loading/loading';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/footer/footer';
import { Link } from 'react-router-dom';

function Details(){

    const {id} = useParams()
    const [product, setProduct] = useState()
    const [loading, setLoading] = useState(true)

    const {t,i18n} = useTranslation()

    const [minPrice, setMinPrice] = useState("")


    const getProduct = (id) => {
        //console.log(id)
        axios.get(backendRoot + "api/Products/Get/" + id)
            .then((response) =>{
                setProduct(response.data)
                setValues(response.data)
                setLoading(false)
            })
            .catch((error) =>{
                //console.log(error);
            })
    }

    useEffect(()=>{
        //console.log(id)
        if(id !== undefined)
            getProduct(id)
    },[id])

    const [images, setImages] = useState([])
    const setValues = (product) => {
        var discount = (parseInt(product.maxNumberOfPersons) - parseInt(product.discountIfMoreThan)) * (parseInt(product.discountPerPerson))
            var price = (parseInt(product.pricePerPerson) * parseInt(product.maxNumberOfPersons)) - discount * parseInt(product.maxNumberOfPersons)
        var price2 = parseInt(parseInt(price/product.maxNumberOfPersons)/100)*100
        if (product.productTypeName === "virtual_reality")
        {
            setMinPrice(product.pricePerPerson+" RSD")
        }
        else
        {
            setMinPrice(price2+" RSD")
        }
        
        var images = []
        images.push(product.mainProductComponent.imageName)
        
        product.productByAditionalComponents.length > 0 && 
        product.productByAditionalComponents.map((component)=> images.push(component.aditionalProductComponent.imageName))
        //console.log(images)
        setImages(images)
    }
    


    

    return(
        <>
        {loading ? <Loading/> :
        <div className="details-main-container">
            <div className="details-container">
                <div className="details-image">
                {/*images.map((image,index)=>{
                    return <img key={index} style={{background: "url("+backendRoot+"Images/" + image +")", backgroundSize: "cover", backgroundPositionX: "50%"}}/>
                })*/}
                <img style={{background: "url(" + backendRoot + "Images/" + product.imageName + ")", backgroundSize: "cover", backgroundPositionX: "50%"}}/>
                </div>
                
                <div className="details">
                    <h1>{i18n.language === "rs" ? product.nameTranslation.sr : product.nameTranslation.en}</h1>
                    <h5>Min {product.minNumberOfPersons}, Max {product.maxNumberOfPersons} {t('Persons')}</h5>
                    <h4>{t('From')} {minPrice}</h4>
                    <Link to={product && product.productType.name === "Single" ? "/user/reservation":"/user/package"}><button>Rezervisite sada</button></Link>
                    <br/>
                    <p>{i18n.language === "rs" ? product.descriptionTranslation.sr : product.descriptionTranslation.en}</p>
                </div>
            </div>
            <Footer/>
        </div>
        }
        </>
    )
}

export default Details;