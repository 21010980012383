import { combineReducers } from "redux";
import ProductReducer from './User/store/reducers/products'
import ReservationReducer from './User/store/reducers/reservation'
import UserReducer from './Admin/store/reducers/user'

const reducers = combineReducers({
    productsSession: ProductReducer,
    reservationSession: ReservationReducer,
    userSession: UserReducer
});

export default reducers