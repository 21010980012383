import '../../assets/styles/Loading/Loading.scss'

function Loading(){
    return(
        <div className="loading-container">
            <div className="circle">
                <div className="circle-2">
                </div>
            </div>
        </div>
    );
}

export default Loading