import '../../assets/styles/AdminPrices/AdminPrices.scss'
import { useTranslation } from 'react-i18next'

function AdminPriceCard(props){
    const {product} = props

    const [ t, i18n ] = useTranslation()

    var min = product.minNumberOfPersons
    var max = product.maxNumberOfPersons

    var people = []
    var pricePP = []
    var priceTotal = []
    var minPrice = ""

    for(var i = min; i <= max; i++)
    {
        people.push(i)
        if(i >= product.discountIfMoreThan)
        {
            var discount = (parseInt(product.pricePerPerson)*parseInt(i))*(parseInt(product.discountPerPerson)/100)
            var price = (parseInt(product.pricePerPerson)*parseInt(i))-(discount*(parseInt(i)-product.discountIfMoreThan))
            priceTotal.push(price+" RSD")
            pricePP.push(price/parseInt(i)+" RSD")
            if(i==max)
                minPrice = price/parseInt(i)+" RSD"
        }
        else
        {
            var price = (parseInt(product.pricePerPerson)*parseInt(i))
            priceTotal.push(price+" RSD")
            pricePP.push(price/parseInt(i)+" RSD")
            if(i==max)
                minPrice = price/parseInt(i)+" RSD"
        }
    }

    return(
        <div className="admin-prices-card">
            <div className="">
                <b>{product.nameTranslation.sr}</b>
            </div>
            {t('From')} {minPrice}
            <div className="prices-card-content">
                <div>
                    <b>{t('People')}</b><br/><br/>
                    {people.map((i,index)=><h6 key={index}>{i}</h6>)}
                </div>
                <div>
                    <b>{t('PricePP')}</b><br/><br/>
                    {pricePP.map((i,index)=><h6 key={index}>{i}</h6>)}       
                </div>
                <div>
                    <b>{t('PriceTotal')}</b><br/><br/>
                    {priceTotal.map((i,index)=><h6 key={index}>{i}</h6>)}         
                </div>
            </div>
        </div>
    )
}

export default AdminPriceCard