import { useState } from "react";
import "../../assets/styles/Contact/Contact.scss";
import Footer from "../../components/footer/footer";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { backendRoot } from "../../assets/apiConstants/ApiConstants";
import ReservationStep from "../../components/reservation-step/reservation-step";

function Contact() {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const [loading, setLoading] = useState(false);

  const ClearData = () => {
    setName("");
    setLastname("");
    setEmail("");
    setPhone("");
    setSubject("");
    setBody("");
  };

  const handleSend = () => {
    setLoading(true);
    axios
      .post(backendRoot + "api/EmailForSendingNotifications/Create", {
        name: name,
        lastname: lastname,
        email: email,
        phone: phone,
        title: subject,
        message: body,
        answered: false,
      })
      .then((response) => {
        //console.log(response)
        setLoading(false);
        ClearData();
      })
      .catch((error) => {
        // handle error
        setLoading(false);
        //console.log(error);
      });
  };

  const [nameError, setNameError] = useState("");
  const [lastnameError, setLastnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [bodyError, setBodyError] = useState("");

  const validateInput = (value) => {
    if (value === "") return true;
    return false;
  };

  const validateData = () => {
    setNameError(validateInput(name));
    setLastnameError(validateInput(lastname));
    setEmailError(validateInput(email));
    setPhoneError(validateInput(phone));
    setSubjectError(validateInput(subject));
    setBodyError(validateInput(body));
  };

  const handleSubmit = () => {
    validateData();
    if (
      name !== "" &&
      lastname !== "" &&
      email !== "" &&
      phone !== "" &&
      subject !== "" &&
      body !== ""
    )
      handleSend();
  };

  return (
    <div className="contact-main-container">
      <div className="contact-container">
        <div className="contact-form-2">
          {/* <>
            <input
              type="text"
              className="contact-form-input-3"
              placeholder={t("Name")}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            {nameError && (
              <div className="error-message">
                {t("Name")}
                {t("RequiredField")}
              </div>
            )}
            <input
              type="text"
              className="contact-form-input-3"
              placeholder={t("Lastname")}
              value={lastname}
              onChange={(e) => {
                setLastname(e.target.value);
              }}
            />
            {lastnameError && (
              <div className="error-message">
                {t("Lastname")}
                {t("RequiredField")}
              </div>
            )}
            <input
              type="email"
              className="contact-form-input-3"
              placeholder={t("Email")}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {emailError && (
              <div className="error-message">
                {t("Email")}
                {t("RequiredField")}
              </div>
            )}
            <input
              type="text"
              className="contact-form-input-3"
              placeholder={t("Phone")}
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
            {phoneError && (
              <div className="error-message">
                {t("Phone")}
                {t("RequiredField")}
              </div>
            )}
            <input
              type="text"
              className="contact-form-input-3"
              placeholder={t("Subject")}
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
            />
            {subjectError && (
              <div className="error-message">
                {t("Subject")}
                {t("RequiredField")}
              </div>
            )}
            <textarea
              type="text"
              className="contact-form-input-4"
              placeholder={t("Message")}
              value={body}
              onChange={(e) => {
                setBody(e.target.value);
              }}
            />
            {bodyError && (
              <div className="error-message">
                {t("Message")}
                {t("RequiredField")}
              </div>
            )}
            <button className="contact-form-button-2" onClick={handleSubmit}>
              {t("Submit")}
            </button>
          </> */}
          <ReservationStep
            stepNumber={"Step 3"}
            text={t("Phone: +381611574924")}
          />
          <ReservationStep
            stepNumber={"Step 3"}
            text={t("Instagram: @escaperoomcegar")}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
