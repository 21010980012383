import axios from 'axios'
import {useState, useEffect} from 'react'
import ProductComponentCard from '../product-component-card/product-component-card';
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants';

function Rooms(props){

    const {setRoomToEdit,setAdditinalToEdit} = props

    //main product components
    const [mainProductComponents, setMainProductComponents] = useState([])

    const GetMainProductComponents = async () => { 
        await axios.get(backendRoot + "api/MainProductComponents/Get")
            .then((response) =>{
                setMainProductComponents(response.data)
            })
            .catch((error) =>{
                //console.log(error);
            })
    }

    useEffect(()=>{
        if(mainProductComponents.length === 0)
        {
            GetMainProductComponents()
        }
    },[mainProductComponents])

    const [additionalProductComponents, setAdditionalProductComponents] = useState([])

    const GetAdditionalProductComponents = async () => { 
        await axios.get(backendRoot + "api/AdditionalProductComponents/Get")
            .then((response) =>{
                setAdditionalProductComponents(response.data)
            })
            .catch((error) =>{
                //console.log(error);
            })
    }

    useEffect(()=>{
        if(additionalProductComponents.length === 0)
        {
            GetAdditionalProductComponents()
        }
    },[additionalProductComponents])

    

    return(
        <div style={{padding: 10}}>
        <br/><h5>Rooms</h5>
        <div className="create-product-main-components-container">
            {mainProductComponents && mainProductComponents.map((component,index)=>{
                return (
                <ProductComponentCard key={index} component={component} index={index} edit={true} setComponentToEdit={setRoomToEdit} GetMainProductComponents={GetMainProductComponents}/>
                )
            })}
        </div>
        <br/><h5>Additionals</h5>
        <div className="create-product-main-components-container">
            {additionalProductComponents && additionalProductComponents.map((component,index)=>{
                return (
                <ProductComponentCard key={index} component={component} index={index} edit={true} setComponentToEdit={setAdditinalToEdit} GetMainProductComponents={GetAdditionalProductComponents}/>
                )
            })}
        </div>
        </div>
    )
}

export default Rooms;