import react from "react";
import '../../assets/styles/HomeTheme/HomeTheme.scss'
import Divider from "../divider/divider";
import { useTranslation } from 'react-i18next';

function HomeTheme(){

    const {t,i18n} = useTranslation()

    return(
        <div className="home-theme-container">
            <div className="home-theme-child">
                <div>{t('ThemeTip1')}</div>
                <div>{t('ThemeTip2')}<br/>{t('ThemeTip3')}</div>
            </div>
            
            <div className="home-theme-child-2">
                <h4 className="home-theme-child-2-heading">{t('ThemeTip4')}</h4>
                <p>{t('ThemeTip5')}</p>
                <Divider/>
                <p><strong>{t('ThemeTip6')}</strong></p>
            </div>
        </div>
    );
}

export default HomeTheme