import '../../assets/styles/AdminProducts/AdminProducts.scss'

import * as UserActionCreators from '../../store/actionCreators/user';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useEffect, useState } from 'react';
import AdminProductCard from '../../components/admin-product-card/admin-product-card';
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";
import CreateProduct from '../../components/create-product/create-product';
import Rooms from '../../components/rooms/rooms';
import CreateComponent from '../../components/create-component/create-component';

function AdminProducts(){

    const allProducts = useSelector((state) => state.productsSession.allProducts)

    const [selectedOption, setSelectedOption] = useState(1)



    const [showMenu, setShowMenu] = useState(false)

    const [roomToEdit, setRoomToEdit] = useState(null)
    const [additinalToEdit, setAdditinalToEdit] = useState(null)

    useEffect(()=>{
        if(roomToEdit !== null)
        {
            setSelectedOption(4)
        }
    },[roomToEdit])

    useEffect(()=>{
        if(additinalToEdit !== null)
        {
            setSelectedOption(4)
        }
    },[additinalToEdit])

    useEffect(()=>{
        if(selectedOption !== 4)
        {
            setRoomToEdit(null)
            setAdditinalToEdit(null)
        }
            
    },[selectedOption])

    return(
        <div className="admin-products-main-container">
            <div className="open-menu">
                <CaretRightFill onClick={()=>{setShowMenu(true)}}/>
            </div>
            <div className="admin-products-menu">
                <div><span onClick={()=>{setSelectedOption(1)}}>All products</span></div> 
                <div><span onClick={()=>{setSelectedOption(2)}}>Create product</span></div>
                <div className="admin-products-menu-divider"></div>
                <div><span onClick={()=>{setSelectedOption(3)}}>All components</span></div>
                <div><span onClick={()=>{setSelectedOption(4)}}>Create component</span></div>
            </div>
            {showMenu &&
            <div className="admin-products-menu-2">
                <CaretLeftFill onClick={()=>{setShowMenu(false)}} style={{marginLeft:"95%"}}/>
                <div><span onClick={()=>{setSelectedOption(1)}}>All products</span></div>
                <div><span onClick={()=>{setSelectedOption(2)}}>Create product</span></div>
                <div className="admin-products-menu-divider"></div>
                <div><span onClick={()=>{setSelectedOption(3)}}>All components</span></div>
                <div><span onClick={()=>{setSelectedOption(4)}}>Create component</span></div>
            </div>}
            <div className="admin-products-container">
                {selectedOption === 1 && 
                <>
                {allProducts.map((product,index)=>
                    <AdminProductCard key={index} product={product}/>
                )}
                </>}
                {selectedOption === 2 && <CreateProduct setSelectedOption={setSelectedOption}/>}
                {selectedOption === 3 && <Rooms setRoomToEdit={setRoomToEdit} roomToEdit={roomToEdit} setAdditinalToEdit={setAdditinalToEdit} additinalToEdit={additinalToEdit}/>}
                {selectedOption === 4 && <CreateComponent setSelectedOption={setSelectedOption} roomToEdit={roomToEdit} additinalToEdit={additinalToEdit}/>}
            </div>
        </div>
    )
}

export default AdminProducts;