import "../../assets/styles/Prices/Prices.scss";
import { useSelector } from "react-redux";
import PriceCard from "../../components/price-card/price-card";
import Footer from "../../components/footer/footer";
import Loading from "../../components/loading/loading";
import pricesEasy from "../../assets/images/pricesEasy.png";
import pricesDifficult from "../../assets/images/pricesDifficult.png";
import pricesVR from "../../assets/images/pricesVR.png";
import pricesERVR from "../../assets/images/pricesERVR.png";

function Prices() {
  const allProducts = useSelector((state) => state.productsSession.allProducts);
  console.log(allProducts);
  const productsSession = useSelector((state) => state.productsSession);

  return (
    <>
      {productsSession.loadingProducts && <Loading />}
      <div className="prices-main-container">
        <div className="prices-container">
          {allProducts.length ? (
            allProducts.map((product, index) => {
              return <PriceCard key={index} product={product} />;
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                gap: 15,
                flexWrap: "wrap",
              }}
            >
              <img src={pricesEasy} style={{ width: "45%" }} />
              <img src={pricesDifficult} style={{ width: "45%" }} />
              <img src={pricesVR} style={{ width: "45%" }} />
              <img src={pricesERVR} style={{ width: "45%" }} />
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Prices;
