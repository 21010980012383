import { useEffect, useState } from 'react';
import '../../assets/styles/CreateVoucher/CreateVoucher.scss'
import axios from 'axios';
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants';
import Loading from '../../../User/components/loading/loading';

import { useSelector, useDispatch } from 'react-redux';

function CreateVoucher(props) {

    const allProducts = useSelector((state) => state.productsSession.allProducts)

    const { setSelectedOption } = props

    const [code, setCode] = useState("")
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [voucherAmountId, setVoucherAmountId] = useState(null)
    const [discount, setDiscount] = useState(0)
    const [discountType, setDiscountType] = useState(0)
    const [voucherType, setVoucherType] = useState(0)
    const [voucher, setVoucher] = useState(null)
    const [numberOfPersons, setNumberOfPersons] = useState(0)
    const [userEmail, setUserEmail] = useState("")
    const [name, setName] = useState("")
    const [lastname, setLastName] = useState("")
    const [loading, setLoading] = useState(false)
    const [selectedVoucherAmount, setSelectedVoucherAmount] = useState(null)

    const [notification, setNotification] = useState("")

    const [voucherAmounts, setVoucherAmounts] = useState([])

    const [productId, setPorductId] = useState(null)

    const [createNewAmount, setCreateNewAmount] = useState(false)

    const GetVoucherAmounts = () => {
        setLoading(true)
        axios.get(backendRoot + "api/VoucherAmounts/Get")
            .then((response) => {
                //console.log(response)
                setVoucherAmounts(response.data)
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                //console.log(error);
            })
    }

    const ClearData = () => {
        setVoucherType(0)
        setDiscountType(0)
        setVoucherAmountId(null)
        setStartDate(null)
        setEndDate(null)
        setDiscount(0)
        setUserEmail("")
        setName("")
        setLastName("")
        setSelectedVoucherAmount(null)
        setCode("")
    }

    const CreateVoucher = () => {
        setLoading(true)
        if (true) {
            var startdate = null
            var enddate = null
            if (startDate !== null && endDate !== null && code !== "") {
                startdate = new Date(startDate)
                enddate = new Date(endDate)
            }
            //console.log(startDate, endDate, startdate, enddate)
            axios.post(backendRoot + "api/Vouchers/Create", {
                code: code === "" ? null : code,
                isPromoCode: voucherType === 1 ? true : false,
                isUsed: false,
                startDate: startDate !== null ? startDate : null,
                endDate: endDate !== null ? endDate : null,
                voucherAmountId: voucherAmountId !== null ? voucherAmountId : null,
                discount: discount !== null ? discount : null,
                approved: true,
                numberOfPersons: numberOfPersons === 0 ? null : numberOfPersons,
                productId: productId !== null ? productId : null
            })
                .then((response) => {
                    setVoucher(response.data)
                    //console.log(response.data)
                    if (voucherType === 2) {
                        CreateUser(response.data)
                    }

                    setNotification("Successfuly created voucher!")
                    setTimeout(() => { setNotification("") }, 3000)
                    ClearData()

                    setLoading(false)
                })
                .catch((error) => {
                    // handle error
                    setLoading(false)
                    setNotification("Error!")
                    setTimeout(() => { setNotification("") }, 3000)
                    //console.log(error);
                })
        }
    }

    const SendEmail = (receipt) => {

        setLoading(true)
        axios.post(backendRoot + "api/SendEmails/SendEmail",
            {
                language: "RS",
                receiptId: receipt.id
            })
            .then((response) => {
                //console.log(response)
                setNotification("Successfuly created voucher!")
                setTimeout(() => { setNotification("") }, 3000)
                ClearData()
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                //console.log(error);
            })
    }

    const CreateReceipt = async (voucher, user) => {
        setLoading(true)
        await axios.post(backendRoot + "api/Receipts/Create", {
            paymentTypeId: 1,
            voucherId: voucher.id,
            individualId: user.id,
            price: selectedVoucherAmount != null ? selectedVoucherAmount.amount : 0,
            isPaid: true
        })
            .then((response) => {
                //console.log(response.data)
                SendEmail(response.data)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                setNotification("Error!")
                setTimeout(() => { setNotification("") }, 3000)
                //console.log(error);
            })
    }

    const CreateUser = async (voucher) => {
        setLoading(true)
        await axios.post(backendRoot + "api/Individuals/Create", {
            name: name,
            lastname: lastname,
            email: userEmail,
            phone: "000000000"
        })
            .then((response) => {
                //console.log(response.data)
                CreateReceipt(voucher, response.data)
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                setNotification("Error!")
                setTimeout(() => { setNotification("") }, 3000)
                //console.log(error);
            })
    }

    const [disableCreateButton, setDisableCreateButton] = useState(true)

    const DisableCreateButton = () => {
        if (startDate !== null && endDate !== null && code !== "" && voucherType === 1 && discountType === 1 && voucherAmountId !== null) {
            setDisableCreateButton(false)
            return
        }
        if (startDate !== null && endDate !== null && code !== "" && voucherType === 1 && discountType === 2 && discount !== 0) {
            setDisableCreateButton(false)
            return
        }
        if (voucherType === 2 && userEmail !== "" && voucherAmountId !== null && name !== "" && lastname !== "") {
            setDisableCreateButton(false)
            return
        }
    }

    useEffect(() => {
        DisableCreateButton()
    }, [startDate, endDate, code, voucherType, discountType, voucherAmountId, userEmail, discount, name, lastname])

    const [isGet, setIsGet] = useState(false)

    useEffect(() => {
        if (voucherAmounts.length === 0 && !isGet) {
            setIsGet(true)
            GetVoucherAmounts()
        }

    }, [voucherAmounts])

    const [newVoucherAmount, setNewVoucherAmount] = useState(null)


    const CreateVoucherAmount = async () => {
        if(newVoucherAmount !== null)
        {
            setLoading(true)
            await axios.post( backendRoot + "api/VoucherAmounts/Create",
                {
                    amount: newVoucherAmount
                })
                .then((response) =>{
                    //console.log(response)
                    setNotification("Successfully created!")
                    setTimeout(() => {setNotification("")},3000)
                    setLoading(false)
                    GetVoucherAmounts()
                    setCreateNewAmount(false)
                })
                .catch((error) =>{
                    // handle error
                    setLoading(false)
                    setCreateNewAmount(false)
                    //console.log(error);
                })
        }
    }

    const DeleteVoucherAmount = async (id) => {
        setLoading(true)
            await axios.delete( backendRoot + "api/VoucherAmounts/Delete/" + id)
                .then((response) =>{
                    //console.log(response)
                    GetVoucherAmounts()
                    setNotification("Successfully deleted!")
                    setTimeout(() => {setNotification("")},1500)
                    setLoading(false)
                    
                })
                .catch((error) =>{
                    // handle error
                    setLoading(false)
                    //console.log(error);
                })
    }


    return (
        <>
            {loading && <Loading />}
            <div className="admin-panelpage-title">
                Vouchers / Create voucher
            </div>
            <div className="create-vouchers-container">
                {notification !== "" && <div className="notification-container" style={{backgroundColor: notification === "Error!" ? "rgb(220,53,69)" : "rgb(40,167,69)"}}>{notification}</div>}
                <div className="create-vouchers-container-item">
                    Select voucher type:
                    <br /><br />
                    <div>
                        <input type="radio" name="voucherType" checked={voucherType === 1} onChange={() => { setVoucherType(1); setDiscountType(0); setDiscount(null); setVoucherAmountId(null) }} /> Promo code
                        <br />
                        <input type="radio" name="voucherType" checked={voucherType === 2} onChange={() => { setVoucherType(2); setDiscountType(1); setDiscount(null); setVoucherAmountId(null) }} /> User voucher
                    </div>
                </div>

                <div className="create-vouchers-container-item">
                    Select discount type:
                    <br /><br />
                    <div>
                        <input type="radio" name="discountType" onClick={() => { setDiscountType(1); setDiscount(null) }} /> Amount
                        <br />
                        <input type="radio" name="discountType" onClick={() => { setDiscountType(2); setVoucherAmountId(null) }} /> Percent (%)
                    </div>
                </div>

                {
                    discountType === 1 &&
                    <div className="create-vouchers-container-item">
                        Select voucher amount:
                        <br /><br />
                        {voucherAmounts && voucherAmounts.map((amount, index) => {
                            return (
                                <div>
                                    <input type="radio" name="amount" key={index} onClick={() => { setVoucherAmountId(amount.id); setSelectedVoucherAmount(amount) }} /> {amount.amount} RSD
                                </div>
                            )
                        })}
                        <button onClick={() => { setCreateNewAmount(true) }}>New amount</button>
                        { createNewAmount &&
                            <div className="create-vouchers-container">
                                <br/>
                                
                                
                                <br />
                                Create new voucher amount<br/>
                                Enter voucher amount:<br/>
                                <input type="number" value={newVoucherAmount && newVoucherAmount} onChange={(e) => { setNewVoucherAmount(e.target.value) }} />
                                <br />
                                <button onClick={CreateVoucherAmount}>Create</button><button onClick={()=>{setCreateNewAmount(false)}}>Cancel</button>

                            </div>
                        }
                    </div>
                }
                {
                    discountType === 2 &&
                    <div className="create-vouchers-container-item">
                        Enter discount (%):
                        <br />
                        <input type="number" value={discount} onChange={(e) => { setDiscount(e.target.value) }} />
                    </div>
                }
                <div className="create-vouchers-container-item">
                    Enter code:
                    <br />
                    <input type="text" value={code} onChange={(e) => { setCode(e.target.value) }} /><br /><br />
                    Start date:<br />
                    <input type="date" onChange={(e) => { setStartDate(e.target.value) }} /><br /><br />
                    End date:<br />
                    <input type="date" onChange={(e) => { setEndDate(e.target.value) }} />
                </div>
                <div className="create-vouchers-container-item">
                    Select product for voucher:<br /><br />
                    {allProducts && allProducts.map((product, index) => {
                        return (
                            <div>
                                <input type="radio" name="product" key={index} onClick={() => { setPorductId(product.id) }} /> {product?.nameTranslation.sr}
                            </div>
                        )
                    })}


                    <br />Number of persons:<br />
                    <input type="text" value={numberOfPersons} onChange={(e) => { setNumberOfPersons(e.target.value) }} />
                </div>
                <div className="create-vouchers-container-item">
                    {
                        voucherType === 2 &&
                        <>
                            Enter user E-mail:<br />
                            <input type="text" value={userEmail} onChange={(e) => { setUserEmail(e.target.value) }} /><br /><br />
                            Enter user name:<br />
                            <input type="text" value={name} onChange={(e) => { setName(e.target.value) }} /><br /><br />
                            Enter user lastname:<br />
                            <input type="text" value={lastname} onChange={(e) => { setLastName(e.target.value) }} /><br /><br />
                        </>
                    }

                    <br />

                    <button onClick={CreateVoucher} >Create voucher</button>
                    <br /><br />
                </div>
            </div>
        </>
    )
}

export default CreateVoucher;