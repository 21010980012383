import react, {useState, useEffect} from "react";
import '../../assets/styles/ReservationCalendar/ReservationCalendar.scss'
import { CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons';

import * as ProductsActionCreators from '../../store/actionCreators/products';
import * as ReservationActionCreators from '../../store/actionCreators/reservation';

import { useSelector, useDispatch, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { backendRoot } from "../../assets/apiConstants/ApiConstants";

function ReservationCalendar(props){
    const {daysWithoutAppointments, isOverview} = props

    const {t,i18n} = useTranslation()

    const reservationSession = useSelector((state) => state.reservationSession)
    const dispatch = useDispatch();
    const { persistProductsAvailabilities, setReservationDate, clearReservationTime } = bindActionCreators({ ...ProductsActionCreators, ...ReservationActionCreators}, dispatch)

    const daysArray = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]
    const handleClick = (date) => {
        setReservationDate(date.getFullYear(),date.getMonth()+1,date.getDate(),date.getDay() === 0 ? 7:date.getDay())
        clearReservationTime()
        var calendar = document.getElementById('calendar')
        window.scrollTo(0,calendar.clientTop+calendar.clientHeight+50)
        //persistProductsAvailabilities(date.getFullYear(),date.getMonth()+1,date.getDate(),date.getDay()-1)
        /*console.log("DATE: ",date.getFullYear()," ",date.getMonth()+1," ", date.getDate())
        FetchProductsData(date.getFullYear(),date.getMonth()+1,date.getDate())*/
    }

    const [value, onChange] = useState(new Date());
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 1)
    var endDate = new Date();
    const [currantTiles, setCurrantTiles] = useState([])

    const months = i18n.language === "rs" ? ["Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"] : ["January", "February", "March", "April", "May", "Jun", "July", "August", "September", "Oktober", "November", "December"]
    const days = i18n.language === "rs" ? ["Ned","Pon", "Uto", "Sre", "Cet", "Pet", "Sub"] : ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"]

    const [currentCalendarDate,setCurrentCalendarDate] = useState(new Date(startDate))

    function createDatesForMonth(date, lowBorder) {
        var highBorder = new Date()
        highBorder.setMonth(new Date().getMonth()+3)
        
    
        const localeDate = new Date(date)
        const tiles = []
    
        localeDate.setDate(1);
        const startFillerTiles = localeDate.getDay();
        localeDate.setDate(0)
    
        if (startFillerTiles > 0) {
            localeDate.setDate(localeDate.getDate() - startFillerTiles)
            for (let i = 0; i < startFillerTiles; i++) {
                localeDate.setDate(localeDate.getDate() + 1)
                tiles.push({ id: i, type: isInRange(localeDate, lowBorder, highBorder) ? "previous" : "unused", date: new Date(localeDate) })
            }
        }
    
        localeDate.setDate(localeDate.getDate() + 1)
        localeDate.setMonth(localeDate.getMonth() + 1)
        localeDate.setDate(0)
    
        const mainTails = localeDate.getDate() + startFillerTiles
        localeDate.setDate(1)
        for (let i = startFillerTiles; i < mainTails; i++) {
            tiles.push({ id: i, type: isInRange(localeDate, lowBorder, highBorder) ? "current" : "unused", date: new Date(localeDate) })
            localeDate.setDate(localeDate.getDate() + 1)
        }
    
        const endFillTiles = 42
        localeDate.setMonth(localeDate.getMonth())
        localeDate.setDate(1)
        for (let i = mainTails; i < endFillTiles; i++) {
            tiles.push({ id: i, type: isInRange(localeDate, lowBorder, highBorder) ? "next" : "unused", date: new Date(localeDate) })
            localeDate.setDate(localeDate.getDate() + 1)
        }
        return tiles
    }
    function isInRange(date, lowBorder, highBorder) {

        if (date == null) return false
        if (lowBorder == null) return false
        if (highBorder == null) return false
    
        if (date.getTime() < lowBorder.getTime())
            return false
        if (highBorder.getTime() < date.getTime())
            return false
    
        return true
    }
    
    useEffect(() => {
        endDate.setMonth(startDate.getMonth() + 1)
        endDate.setDate(1)
        setCurrentCalendarDate(new Date(startDate))
        setCurrantTiles(createDatesForMonth(new Date(startDate),new Date(startDate)))
    }, [])

    const handleMonthBack = () => {
        
        const newDate = currentCalendarDate
        newDate.setDate(5)
        const d = startDate
        d.setDate(startDate.getDate()-1)

        //if(currentCalendarDate.getMonth() - 1 < startDate.getMonth()) return
        
        newDate.setMonth(currentCalendarDate.getMonth() - 1)
        setCurrentCalendarDate(newDate)
        setCurrantTiles(createDatesForMonth(new Date(newDate), new Date(d)))
    }

    function handleMonthForward() {
        const newDate = new Date(currentCalendarDate)
        newDate.setDate(5)
        const d = startDate
        d.setDate(startDate.getDate()-1)

        /*if(currentCalendarDate.getMonth() > startDate.getMonth()+2)
        {
            console.log("ne menjam")
            return
        } */
        /*if(startDate.getMonth() == 11){
            newDate.setMonth(0)
            newDate.setFullYear(currentCalendarDate.getFullYear() + 1);
        }
        else*/
            newDate.setMonth(currentCalendarDate.getMonth() + 1)

        setCurrentCalendarDate(newDate)
        setCurrantTiles(createDatesForMonth(new Date(newDate), new Date(d)))
    }

    /*const [daysWithoutAppointments, setDaysWithoutAppointments] = useState(null)

    const getDaysWithoutAppointments = () => {
        axios.get(backendRoot + "api/Days/GetDaysWithoutAppointments")
            .then((response) =>{
              console.log(response)
              var array = []
              response.data.forEach(element => {
                if(element === 7)
                  array.push(0)
                else
                  array.push(element)
              });
              setDaysWithoutAppointments(array)
            })
            .catch((error) =>{
                console.log(error);
            })
          }
    
      useEffect(() => {
        if (daysWithoutAppointments === null) {
    
          getDaysWithoutAppointments()
        }
      }, [daysWithoutAppointments])*/

    return(
        <>
        {daysWithoutAppointments !== null &&
        <>
        
        <div className="calendar-main-container" id="calendar">
            <div className="calendar-container">
                
                <div className="calendat-navbar">
                    <div className="calendat-navbar-button" onClick={handleMonthBack}><CaretLeftFill/></div>
                    <div className="calendat-navbar-label">{months[currentCalendarDate.getMonth()]} {currentCalendarDate.getFullYear()}</div>
                    <div className="calendat-navbar-button" onClick={handleMonthForward}><CaretRightFill/></div>
                </div>
                <div className="calendar-days">
                    {
                        days.map((day,index)=>(
                            <div key={index} className="calendar-day">{day}</div>
                        ))
                    }
                </div>
                <div className="calendar-items">
                    {
                        currantTiles.map((item, index) => {
                            return (
                                <div 
                                    onClick={() => {item.type == 'next' && handleClick(item.date) || item.type == 'current' && handleClick(item.date) || item.type == 'previous' && handleClick(item.date) || isOverview && handleClick(item.date)}} 
                                    className={
                                        item.type == 'current' && (item.date.getDate() === reservationSession.date.date && item.date.getMonth()+1 === reservationSession.date.month && item.date.getFullYear() === reservationSession.date.year? "calendar-item-used selected current" : "calendar-item-used current") || 
                                        item.type == 'next' && (item.date.getDate() === reservationSession.date.date && item.date.getMonth()+1 === reservationSession.date.month && item.date.getFullYear() === reservationSession.date.year? "calendar-item-used selected" : "calendar-item-used") || 
                                        item.type == 'previous' && (item.date.getDate() === reservationSession.date.date && item.date.getMonth()+1 === reservationSession.date.month && item.date.getFullYear() === reservationSession.date.year? "calendar-item-used selected" : "calendar-item-used") ||
                                        
                                        item.type == 'unused' && "calendar-item-unused"} 
                                    key={index} 
                                    style={{ cursor: item.type == 'current' || item.type == 'next' || item.type == 'previous' || isOverview ? "pointer" : "no-drop",fontWeight: item.type == "current" ? "bold" : "100",color: daysWithoutAppointments.includes(item.date.getDay()) ? "#bf0e02" : item.type == "current" ? "rgb(88,88,88)" : "grey"}}
                                >
                                    {item.date.getDate()}
                                </div>)})
                    }
                </div>
            </div>
        </div>
        {!isOverview && <div style={{width: "100%", textAlign: "center"}}>
        <b>{t('reserveForTheSameDay')}<a href='https://www.instagram.com/escaperoomcegar/' target={"_blank"}>@escaperoomcegar</a>{t('orPhone')}</b>
        </div>}
        </>}
        </>
    );
}

export default ReservationCalendar