import { useState } from 'react';
import '../../assets/styles/AdminGallery/AdminGallery.scss'
import ImageGallery from 'react-image-gallery';
import defaultImage from '../../assets/images/noImage.png'
import axios from 'axios';
import { useEffect } from 'react';
import Loading from '../../../User/components/loading/loading';
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants';

function AdminGallery(){

    const [loading, setLoading] = useState(false)

    const [images, setImages] = useState([])
    const [imagesPath, setImagesPath] = useState([])

    const [showAdd, setShowAdd] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const [imageSrc, setImageSrc] = useState("")
    const [imageName, setImageName] = useState("")
    const [imageFile, setImageFile] = useState(null)

    const GetImages = async () => {
        setLoading(true)
        await axios.get(backendRoot + "api/GalleryImages/Get")
            .then((response) =>{
                //console.log(response)
                setImages(response.data)
                setLoading(false)
            })
            .catch((error) =>{
                //console.log(error);
            })
    }

    useEffect(()=>{
        if(images.length === 0)
        {
            GetImages()
        }
        else
        {
            var imageArr = []
            //console.log(images)
            images.forEach((image) => {
                imageArr.push({original: backendRoot + "GalleryImages/" + image.imageName, thumbnail: backendRoot + "GalleryImages/" + image.imageName, imageName: image.imageName, id: image.id})
            })
            setImagesPath(imageArr)
        }
    },[images])

    const showPreview = e => {
        //console.log(e.target.files)
        if(e.target.files && e.target.files[0])
        {
            let imageFile = e.target.files[0]
            const reader = new FileReader()
            reader.onload = x=>{
                setImageFile(imageFile)
                setImageSrc(x.target.result)
            }
            reader.readAsDataURL(imageFile)
        }
    }


    const uploadImage = async () => {
        setLoading(true)
        const formData = new FormData()

        formData.append('imageFile', imageFile)

        await axios.post(backendRoot + "api/GalleryImages/Create",formData,
        {
            withCredentials: true
        })
            .then((response) =>{
                setImageSrc("")
                setImageFile(null)
                GetImages()
                setLoading(false)
            })
            .catch((error) =>{
                //console.log(error);
                setLoading(false)
            })
    }

    const handleCancel = () => {
        setImageSrc("")
        setImageFile(null)
        setShowAdd(false)
    }

    const [imageToDelete, setImageToDelete] = useState(null)

    const handelDeleteImage = async () => {
        if(imageToDelete !== null)
        {
        setLoading(true)
        await axios.post(backendRoot + "api/GalleryImages/RemoveImage",
        {
            id: imageToDelete.id,
            imageName: imageToDelete.imageName
        },
        {
            withCredentials: true
        })
            .then((response) =>{
                GetImages()
                setLoading(false)
            })
            .catch((error) =>{
                //console.log(error);
                setLoading(false)
            })
        }
    }
    return(
        <>
        {loading && <Loading/>}
        <div className="admin-gallery-slider">
            {imagesPath.length !== 0 && <ImageGallery items={imagesPath} />}
        </div>
        <div className="edit-gallery-buttons-container">
            <button onClick={()=>{setShowAdd(true);setShowDelete(false)}}>Add image</button>
            <button onClick={()=>{setShowAdd(false);setShowDelete(true)}}>Delete image</button>
        </div>
        <div className="edit-gallery-container">
        {
            showAdd &&
            <div className="add-image-container">
                <div style={{width: "95%", maxWidth: 500}}>
                <img style={{width: "100%"}} src={imageSrc === "" ? defaultImage : imageSrc} alt={"def image"}/>
            </div><br/>
            <input type={"file"} accept="image/*" onChange={showPreview}/>
            <button disabled={imageFile === null} onClick={uploadImage}>Save</button>
            <button onClick={handleCancel}>Cancel</button>
            </div>
        }
        {
            showDelete &&
            <div className="delete-image-container">
                Select an image to delete
                <div className="delete-images-container">
                    {imagesPath.map((image, index)=>{
                        return (
                            <>
                            <img className={imageToDelete && imageToDelete.id === image.id ? "selected-image-to-delete":"image-to-delete"} key={index} src={image.original} alt="image" onClick={()=>{setImageToDelete(image)}}/>
                            </>)
                    })}
                </div>
                <button onClick={handelDeleteImage} disabled={imageToDelete === null}>Delete</button>
            </div>
        }
        </div>
        </>
    )
}

export default AdminGallery;