import react from "react";
import '../../assets/styles/HomeDescription/HomeDescription.scss'
import { useTranslation } from 'react-i18next';

function HomeDescription(){

    const {t,i18n} = useTranslation()

    return(
        <div className="home-description-contaner">
            <h1 className="home-description-heading">{t('DescriptionTip1')}</h1>
            <p className="home-description-paragraph">{t('DescriptionTip2')}<br/>‍<br/>‍</p>
        </div>
    );
}

export default HomeDescription