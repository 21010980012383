import * as UserActionCreators from '../../store/actionCreators/user';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminPriceCard from '../admin-price-card/admin-price-card';

function AdminPrices(){

    const allProducts = useSelector((state) => state.productsSession.allProducts)

    return(
        <div>
            {
                allProducts.map((product,index)=>{
                    return(
                        <AdminPriceCard key={index} product={product}/>
                    )
                })
            }
        </div>
    )
}

export default AdminPrices;