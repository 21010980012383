import react from "react";
import '../../assets/styles/HomeAbout/HomeAbout.scss'
import Divider from "../divider/divider";
import { useTranslation } from 'react-i18next';

function HomeAbout(){

    const {t,i18n} = useTranslation()
    
    return(
        <div className="home-about-container">
            <div className="home-about-heading">{t('AboutTip1')}</div>
            <div className="home-about-content">
                <p>{t('AboutTip2')}</p>
                <p><strong>{t('AboutTip3')}</strong></p>
                <Divider/>
                <p>{t('AboutTip4')}</p>
                <Divider/>
                <p>{t('AboutTip5')} </p>
                <Divider/>
            </div>
        </div>
    );
}

export default HomeAbout