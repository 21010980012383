import React, { useState } from 'react';
import './App.css';

import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom"
import HomeContact from './User/components/home-contact/home-contact';
import Navbar from './User/components/navbar/navbar';
import Home from './User/containers/Home/Home'
import Reservation from './User/containers/Reservation/Reservation'
import Footer from './User/components/footer/footer'
import { useEffect } from 'react';

import * as ProductsActionCreators from './User/store/actionCreators/products';
import * as ReservationActionCreators from './User/store/actionCreators/reservation';

import { useSelector, useDispatch, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from './User/components/loading/loading';

import { HubConnectionBuilder } from "@microsoft/signalr";
import Contact from './User/containers/Contact/Contact';
import Prices from './User/containers/Prices/Prices';
import Package from './User/containers/Package/Package';
import Vouchers from './User/containers/Vouchers/Vouchers';
import { backendRoot } from './User/assets/apiConstants/ApiConstants';
import AdminHome from './Admin/containers/AdminHome/AdminHome';
import Details from './User/containers/Details/Details';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';
import AdminNavbar from './Admin/components/admin-navbar-v2/admin-navbar';
import AdminProducts from './Admin/containers/AdminProducts/AdminProducts';
import AdminLogin from './Admin/containers/AdminLogin/AdminLogin';

import { useCookies } from 'react-cookie';

import axios from 'axios';
import AdminProfile from './Admin/containers/AdminProfile/AdminProfile';
import Gallery from './User/containers/Gallery/Gallery';
import AdminOptions from './Admin/containers/AdminOptions/AdminOptions';
import AdminVouchers from './Admin/containers/AdminVouchers/AdminVouchers';
import AdminQuestions from './Admin/containers/AdminQuestions/AdminQuestions';

import { useTranslation } from 'react-i18next';
import PrivacyPolicyEN from './User/containers/PrivacyPolicyEN/PrivacyPolicyEN';
import PrivacyPolicyRS from './User/containers/PrivacyPolicyRS/PrivacyPolicyRS';
import AdminPanel from './Admin/containers/AdminPanel/AdminPanel';

function App() {

  const { t } = useTranslation()

  const [clientKey, setClientKey] = useState('')

  const userSession = useSelector((state) => state.userSession)
  const reservationSession = useSelector((state) => state.reservationSession)
  const selectedDate = useSelector((state) => state.reservationSession.date)
  const selectedTime = useSelector((state) => state.reservationSession.time)

  const [isSet, setIsSet] = useState(false)
  const [isSetP, setIsSetP] = useState(false)

  const [message, setMessage] = useState()

  const [daysWithoutAppointments, setDaysWithoutAppointments] = useState(null)


  const dispatch = useDispatch();
  const {
    FetchProductsData,
    setReservationDate
  } = bindActionCreators({ ...ProductsActionCreators, ...ReservationActionCreators }, dispatch)

  useEffect(() => {

    if (!isSetP) {
      FetchProductsData(navigate.pathname.includes("admin") ? "admin" : "user")
      setClientKey(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15))
      setIsSetP(true)
    }
  })

  const getDaysWithoutAppointments = () => {
    axios.get(backendRoot + "api/Days/GetDaysWithoutAppointments")
        .then((response) =>{
          //console.log(response)
          var array = []
          response.data.forEach(element => {
            if(element === 7)
              array.push(0)
            else
              array.push(element)
          });
          setDaysWithoutAppointments(array)
        })
        .catch((error) =>{
            //console.log(error);
        })
      }

  useEffect(() => {
    if (daysWithoutAppointments === null) {

      getDaysWithoutAppointments()
    }
  })

  useEffect(() => {
    if (!isSet && daysWithoutAppointments !== null) {

      var date = new Date();
      var day = date.getDay()+1
      date.setDate(date.getDate() + 1)

      if(daysWithoutAppointments.includes(date.getDay())) {
        date.setDate(date.getDate() + 1)
        day = day + 1
      }

      setReservationDate(date.getFullYear(), date.getMonth() + 1, date.getDate(), day)
      setIsSet(true)
    }
  }, [isSet, daysWithoutAppointments])

  const [connection, setConnection] = useState(null);

  useEffect(() => {
    if (connection === null) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(backendRoot + 'hubs/chat')
        .build();

      setConnection(newConnection);
    }
  }, []);


  useEffect(() => {
    if (connection) {
      connection.start()
        .then(result => {
          //console.log('Connected!');

          connection.on('ReceiveMessage', message => {
            /*const updatedChat = [];
            updatedChat.push(message);
        
            setChat(updatedChat);*/
            /*console.log("MESSAGE: ",message)
            var date = new Date(message.date)
            if(date.getFullYear() === selectedDate.year && date.getMonth() === selectedDate.month - 1 && date.getDate() === selectedDate.date)
            {
              console.log(date)
              FetchProductsData()
            }*/

            setMessage(message)
            FetchProductsData(navigate.pathname.includes("admin") ? "admin" : "user")
          });
        })
        .catch(e => console.log('Connection failed: ', e));
    }
  }, [connection]);

  const sendMessage = async () => {
    var newDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.date, parseInt(selectedTime.hour) + 2, parseInt(selectedTime.minute), 0, 0)
    const chatMessage = {

      date: newDate,
      message: clientKey
    };

    if (connection) {
      try {
        await connection.send('SendMessage', chatMessage);
      }
      catch (e) {
        //console.log(e);
      }
    }
    else {
      alert('No connection to server yet.');
    }
    FetchProductsData(navigate.pathname.includes("admin") ? "admin" : "user")
  }

  const navigate = useLocation()

  const [isAdmin, setIsAdmin] = useState(false)
  const [pp, setPp] = useState(false)

  useEffect(() => {
    if (navigate.pathname === '/admin' || navigate.pathname === '/admin/login' || navigate.pathname === '/admin/home' || navigate.pathname === '/admin/products' || navigate.pathname === '/admin/profile' || navigate.pathname === '/admin/admin-panel')
      setIsAdmin(true)
    if (navigate.pathname === '/privacy-policy-EN' || navigate.pathname === '/privacy-policy-RS')
      setPp(true)
  })

  const [authenticatedAdmin, setAuthenticatedAdmin] = useState(false)
  const [adminAccount, setAdminAccout] = useState()

  /*useEffect(()=>{
    if(!authenticatedAdmin)
      handleAuthentication()
  },[authenticatedAdmin])

  /*const handleAuthentication = () => {
    axios.post(backendRoot + "api/UserAccounts/User",{},{
      withCredentials: true
  })
        .then((response) =>{
            setAdminAccout(response.data)
            setAuthenticatedAdmin(true)
        })
        .catch((error) =>{
            setAuthenticatedAdmin(false)
            console.log(error);
        })
}*/

  const [modalIsOpen, setIsOpen] = useState(false);
  const openDate = new Date(2022, 10, 1)
  const currentDate = new Date()

  const [showed, setShowed] = useState(false)

  useEffect(() => {
    if (currentDate.getFullYear() <= openDate.getFullYear() && currentDate.getMonth() < openDate.getMonth() && !showed && navigate.pathname !== '/privacy-policy-EN' && navigate.pathname !== '/privacy-policy-RS') {

      setIsOpen(true)
      setTimeout(() => { setIsOpen(false) }, 3000)
      setShowed(true)
    }
  }, [showed])

  const [getNumberVisitors, setGetNumberVisitors] = useState(0)

  const [numberVisitors, setNumberVisitors] = useState(0)

  /*const addVisit = () => {
    axios.post(backendRoot + "api/Visitors/Add")
      .then((response) => {
        console.log("dodat posetilac")
        setGetNumberVisitors(1)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const getVisit = () => {
    axios.get(backendRoot + "api/Visitors/Get")
      .then((response) => {
        console.log("ukupno posetilaca", response)
        setNumberVisitors(response.data)
        setGetNumberVisitors(2)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(()=>{
    if(getNumberVisitors === 0)
      addVisit()
    if(getNumberVisitors === 1)
      getVisit()
  },[getNumberVisitors])*/

  return (
    <>
      {/*!pp ? (isAdmin ? <>{userSession.isLogin && null}</> : <Navbar />) : null*/}
      {!pp ? (isAdmin ? <>{userSession.isLogin && <AdminNavbar />}</> : <Navbar />) : null}
      <Routes>
        <Route path="/" element={<Navigate to="/user/home" />} />
        <Route path="/privacy-policy-EN" element={<PrivacyPolicyEN />} />
        <Route path="/privacy-policy-RS" element={<PrivacyPolicyRS />} />
        <Route path="/user">
          <Route path="/user" element={<Navigate to="/user/home" />} />
          <Route path="home" element={<Home numberVisitors={numberVisitors} />} />
          <Route path="prices" element={<Prices />} />
          <Route path="reservation" element={<Reservation daysWithoutAppointments={daysWithoutAppointments} sendMessage={sendMessage} message={message} setMessage={setMessage} clientKey={clientKey} />} />
          {/*<Route path="package" element={<Package sendMessage={sendMessage} message={message} setMessage={setMessage} clientKey={clientKey}/>}/>*/}
          <Route path="details/:id" element={<Details />} />
          <Route path="vouchers" element={<Vouchers/>}/>
          <Route path="gallery" element={<Gallery />} />
          <Route path="contact" element={<Contact />} />
        </Route>
        <Route path="/admin">
          {
            !userSession.isLogin ?
              <>
                <Route path="/admin" element={<Navigate to="/admin/login" />} />
                {/*<Route path="/admin/home" element={<Navigate to="/admin/login" />} />
                <Route path="/admin/products" element={<Navigate to="/admin/login" />} />
                <Route path="/admin/profile" element={<Navigate to="/admin/login" />} />
                <Route path="/admin/admin-options" element={<Navigate to="/admin/login" />} />
                <Route path="/admin/admin-vouchers" element={<Navigate to="/admin/login" />} />
          <Route path="/admin/admin-questions" element={<Navigate to="/admin/login" />} />*/}
                <Route path="/admin/admin-panel" element={<Navigate to="/admin/login" />} />
                <Route path="login" element={<AdminLogin />} />
              </>
              :
              <>

                <Route path="/admin" element={<Navigate to="/admin/admin-panel" />} />
                <Route path="/admin/login" element={<Navigate to="/admin/admin-panel" />} />
                {/*<Route path="home" element={<AdminHome daysWithoutAppointments={daysWithoutAppointments} sendMessage={sendMessage} message={message} setMessage={setMessage} clientKey={clientKey} />} />
                <Route path="products" element={<AdminProducts />} />
                <Route path="profile" element={<AdminProfile />} />
                <Route path="admin-options" element={<AdminOptions />} />
                <Route path="admin-vouchers" element={<AdminVouchers />} />
        <Route path="admin-questions" element={<AdminQuestions />} />*/}
                <Route path="admin-panel" element={<AdminPanel daysWithoutAppointments={daysWithoutAppointments} sendMessage={sendMessage} message={message} setMessage={setMessage} clientKey={clientKey} />} />
              </>

          }

        </Route>
      </Routes>
      {/*
        modalIsOpen &&
        <div className="home-modal" >
          <div className="home-modal-content">
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
              <div className="home-modal-content-div-x" onClick={() => { setIsOpen(false) }}>X</div>
            </div>

            <div className="home-modal-content-div-1">-20%</div>
            <div className="home-modal-content-div-2">PROMO KOD: SEPTEMBAR2022</div>
            <div className="">za rezervacije u SEPTEMBRU</div>

          </div>
        </div>
        */}
    </>
  );
}

export default App;
