import { useState } from 'react';
import '../../assets/styles/Vouchers/Vouchers.scss'
import Footer from '../../components/footer/footer';
import axios from 'axios';
import PopUpNotification from '../../components/popup-notification/popup-notification';
import Loading from '../../components/loading/loading';

import { backendRoot } from '../../assets/apiConstants/ApiConstants'

import { useTranslation } from 'react-i18next';

function Vouchers() {




    const [name, setName] = useState('')
    const [lastname, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [amount, setAmount] = useState(0)
    const [loading, setLoading] = useState(false)

    const [code, setCode] = useState('')
    const [voucher, setVoucher] = useState(null)
    const [voucherNotExist, setVoucherNotExist] = useState(false)



    const CreateVoucherAmount = async () => {
        if (amount !== 0) {
            setLoading(true)
            await axios.post(backendRoot + "api/VoucherAmounts/Create",
                {
                    amount: amount
                })
                .then((response) => {
                    console.log(response)
                    CreateVoucher(response.data.id)
                    setLoading(false)
                })
                .catch((error) => {
                    // handle error
                    setLoading(false)
                    console.log(error);
                })
        }
    }


    const CreateVoucher = (amountId) => {
        setLoading(true)
        axios.post(backendRoot + "api/Vouchers/Create", {
            isPromoCode: false,
            isUsed: false,
            createdAtDate: new Date(),
            modifiedAtDate: new Date(),
            voucherAmountId: amountId,
            approved: false
        })
            .then((response) => {
                createUser(response.data)
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                console.log(error);
            })
    }


    const createUser = (voucher) => {
        setLoading(true)
        axios.post(backendRoot + "api/Individuals/Create", {
            name: name,
            lastname: lastname,
            email: email,
            phone: phone
        })
            .then((response) => {
                createReceipt(voucher, response.data)
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                console.log(error);
            })
    }

    const createReceipt = (voucher, user) => {
        setLoading(true)



        axios.post(backendRoot + "api/Receipts/Create", {
            paymentTypeId: 1,
            voucherId: voucher.id,
            individualId: user.id,
            price: amount,
            isPaid: false
        })
            .then((response) => {
                console.log(response.data)
                sendEmail(response.data)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                console.log(error);
            })
    }

    const [showEmailNotification, setShowEmailNotification] = useState(false)

    const sendEmail = (receipt) => {

        const headers = {
            'Access-Control-Allow-Origin': '*'
        };
        setLoading(true)
        axios.post(backendRoot + "api/SendEmails/SendEmail",
            {
                language: i18n.language === "en" ? "EN" : "RS",
                receiptId: receipt.id
            }, { headers })
            .then((response) => {
                //console.log(response)
                setShowEmailNotification(true)
                setTimeout(() => { setShowEmailNotification(false) }, 4500)
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                //console.log(error);
            })
    }

    const { t, i18n } = useTranslation()

    const [nameError, setNameError] = useState(false)
    const [lastnameError, setLastNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)

    const validateInput = (value) => {
        if (value === '')
            return true
        return false
    }

    const validatePhone = (_phone) => {
        /*if (!(parseInt(_phone) > 9999999 && parseInt(_phone) < 10000000000)) {
            setDataIsValid(false)
            return true
        }
        return false*/
        var mailformat = /[0-9]|[+]/gi
        if (_phone !== '' && _phone.match(mailformat) && _phone?.length === _phone.match(mailformat)?.length) {

            return false
        }
        return true
    }

    function ValidateEmail(email) {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.match(mailformat)) {
            return false;
        }
        else {
            return true;
        }
    }

    const validateData = () => {
        setNameError(validateInput(name))
        setLastNameError(validateInput(lastname))
        setEmailError(ValidateEmail(email))
        setPhoneError(validatePhone(phone))
    }

    const handleSubmit = () => {
        validateData()
        if (name !== '' && lastname !== '' && !ValidateEmail(email) && !validatePhone(phone) && amount > 0)
            CreateVoucherAmount()
    }

    const GetVoucherByCode = async () => {
        if (code != '') {
            setVoucherNotExist(false)
            setLoading(true)
            await axios.get(backendRoot + "api/Vouchers/GetByCode/" + code)
                .then((response) => {
                    //setVoucher(response.data)
                    if (response.status === 200)
                        setVoucher(response.data)
                    else
                        setVoucherNotExist(true)
                    setLoading(false)
                })
                .catch((error) => {
                    // handle error
                    setLoading(false)
                    //console.log(error);
                })
        }
    }

    const validateVoucher = (voucher) => {
        if (!voucher.isPromoCode && !voucher.approved)
            return <>{t('UnpaidVoucher')}</>
        if (!voucher.isPromoCode && voucher.isUsed)
            return <>{t('UsedVoucher')}</>
        if (!voucher.isPromoCode && !voucher.isUsed) {
            if (voucher.endDate !== null) {
                var dateNow = new Date()
                var endDate = new Date(voucher.endDate)
                if (dateNow.getFullYear() === endDate.getFullYear() && dateNow.getDate() > endDate.getDate() && dateNow.getMonth() === endDate.getMonth() || dateNow.getFullYear() >= endDate.getFullYear() && dateNow.getMonth() > endDate.getMonth())
                    return <>{t('ExpiredVoucher')}</>
            }
            return <>{t('ActiveVoucher')}</>
        }
        if (voucher.isPromoCode) {
            if (voucher.endDate !== null) {
                var dateNow = new Date()
                var endDate = new Date(voucher.endDate)
                if (dateNow.getFullYear() === endDate.getFullYear() && dateNow.getDate() > endDate.getDate() && dateNow.getMonth() === endDate.getMonth() || dateNow.getFullYear() > endDate.getFullYear() || dateNow.getFullYear() === endDate.getFullYear() && dateNow.getMonth() > endDate.getMonth())
                    return <>{t('ExpiredPromoCode')}</>
            }
            return <>{t('ActivePromoCode')}</>
        }
    }


    return (
        <>
            {loading && <Loading />}
            <div className="vouchers-main-container">
                <div className="vouchers-container">
                    <div className="vouchers-container-background">


                        <div className="vouchers-container-right">
                            <h1>{t('OrderVoucher')}</h1>
                            <p>{t('VoucherDescribe')}</p>
                            <p><b>{t('VoucherDescribe2')}</b></p>
                            <h2>{t('SelectAmount')}:</h2>
                            <div className="reservation-user-form">
                                <input type="number" className="reservation-user-input" value={amount} onChange={(e) => { setAmount(e.target.value) }} />
                            </div>
                            <h2>{t('EnterYourData')}:</h2>
                            <div className="reservation-user-form">
                                <input type="text" className="reservation-user-input" placeholder={t('Name')} value={name} onChange={(e) => { setName(e.target.value) }} />
                                {nameError && <div className="error-message">{t('Name')}{t('RequiredField')}</div>}
                                <input type="email" className="reservation-user-input" placeholder={t('Lastname')} value={lastname} onChange={(e) => { setLastName(e.target.value) }} />
                                {lastnameError && <div className="error-message">{t('Lastname')}{t('RequiredField')}</div>}
                                <input type="text" className="reservation-user-input" placeholder={t('Email')} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                {emailError && <div className="error-message">{t('Email')}{t('RequiredField')}</div>}
                                <input maxLength="15" type="text" className="reservation-user-input" placeholder={t('Phone')} pattern="[0-9]+" required value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                                {phoneError ? (phone !== '' ? <div className="error-message">{t('IncorrectInput')}</div> : <div className="error-message">{t('Phone')}{t('RequiredField')}</div>) : ""}
                                <button className="reservation-user-button" onClick={handleSubmit}>{t('Submit')}</button>
                            </div>
                        </div>
                        <div className="vouchers-container-right">
                            <h1>{t('VoucherDescribe3')}</h1>
                            <p>{t('VoucherDescribe4')}</p>
                            <h2>{t('EnterCode')}:</h2>
                            <div className="reservation-user-form">
                                <input type="text" className="reservation-user-input" placeholder={t('PromoCode')} value={code} onChange={(e) => { setCode(e.target.value) }} />
                                {nameError && <div className="error-message">{t('Name')}{t('RequiredField')}</div>}
                                <button className="reservation-user-button" onClick={GetVoucherByCode}>{t('CheckVoucher')}</button>
                            </div>
                            {
                                voucher !== null &&
                                <>
                                    <br />
                                    <h2>{voucher.code}</h2>
                                    {
                                        voucher.startDate !== null &&
                                        <>
                                            <b>{t('ValidFrom')} </b>{voucher.startDate.split('-')[2].split("T")[0]}/{voucher.startDate.split('-')[1]}/{voucher.startDate.split('-')[0]}
                                            <br />
                                        </>
                                    }
                                    {
                                        voucher.endDate !== null &&
                                        <>
                                            <b>{t('ValidUntil')} </b>{voucher.endDate.split('-')[2].split("T")[0]}/{voucher.endDate.split('-')[1]}/{voucher.endDate.split('-')[0]}
                                            <br />
                                        </>
                                    }
                                    {
                                        voucher.voucherAmount !== null &&
                                        <>
                                            <b>{t('Credit')} </b>{voucher.voucherAmount.amount} RSD
                                            <br />
                                        </>
                                    }
                                    {
                                        voucher.discount !== null &&
                                        <>
                                            <b>{t('Discount')} </b>{voucher?.discount} %
                                            <br />
                                        </>
                                    }


                                    <b>{t('Status')} </b>{validateVoucher(voucher)}
                                </>
                            }
                            {
                                voucherNotExist && 
                                <h2>{t('VoucherNotExist')}</h2>
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <PopUpNotification isShow={showEmailNotification} type={"success"} />
        </>
    )
}

export default Vouchers;