import { useTranslation } from 'react-i18next';

function PrivacyPolicyRS(){

    const {t} = useTranslation()

    return(
        <div style={{height: "100%"}}>
        <div style={{width: "100%", padding: "15px", backgroundColor: "black", color: "white", fontSize: "2rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
        Escape room Čegar - Pravila o privatnosti
        </div>
        <div style={{width: "100%", maxWidth: "1000px", margin: "0 auto", padding: "10px 10px 50px 10px"}}>
            <br/>
            <p>Naša pravila privatnosti objašnjavaju kako prikupljamo, koristimo, štitimo i rukujemo vašim Ličnim Podacima (LP) koje delite sa nama na našoj veb stranici ili na lokaciji naše sobe Escape Room Boj na Cegru.</p>
            <p>Kada rezervišete našu sobu (escape room Boj na Cegru) na našem (veb)sajtu, od vas se traži da unesete svoje ime, adresu e-pošte, broj telefona ili druge detalje kao što su adresa ili lični identifikacioni broj, kako bismo mogli da vam ponudimo našu uslugu i osiguramo da dobijete najbolje iskustvo.</p>

            <br/><br/>
            <p><b>Kada prikupljamo LP?</b></p>
            <p>Mi prikupljamo informacije od vas kada naručite, odnosno popunite formular na lokaciji sobe za bekstvo ili unesete informacije na našu veb stranicu.</p>

            <br/><br/>
            <p><b>Kako koristimo vaše LP?</b></p>
            <p>Sakupljamo i koristimo vaše lične podatke kada obavljate rezervaciju da bismo potvrdili vašu porudžbinu i poslali vam detalje o porudžbini. Možete nas kontaktirati da zatražite uklanjanje vaši LP ili zatražite detalje o tome koje informacije posedujemo.</p>

            <br/><br/>
            <p><b>Kako štitimo vaše  LP?</b></p>
            <p>•	Koristimo najbolje bezbednosne prakse za našu veb stranicu i redovno je skeniramo u potrazi za bezbednosnim rupama i poznatim ranjivostima kako bismo je učinili što bezbednijom.</p>
            <p>•	Vaše LP se nalaze iza zaštićenih mreža i dostupne su samo ograničenom broju našeg osoblja od kojih se traži da čuvaju poverljivost informacija. Pored toga, svi LP se komuniciraju šifrovani korišćenjem tehnologije Secure Socket Layer (SSL).</p>
            <p>•	Kada rezervišete sobu, mi sprovodimo mere bezbednosti da očuvamo bezbednost vaših LP.</p>
            <p>•	Našu veb stranicu hostuje komercijalni provajder u oblaku i sve transakcije se tamo obrađuju i ne čuvaju ili obrađuju na našim serverima.</p>

            <br/><br/>
            <p><b>Deljenje LP sa trećim stranama</b></p>
            <p>Ne prodajemo, ne trgujemo niti na drugi način prenosimo vaše LP na spoljne strane.</p>

            <br/><br/>
            <p><b>Veze na treće strane</b></p>
            <p>Ne uključujemo niti nudimo proizvode ili usluge trećih strana na našoj veb stranici.</p>

            <br/><br/>
            <p><b>Fotografije</b></p>
            <p>Uz vašu dozvolu možemo da otpremimo fotografije snimljene u našoj sobi na društvenim mrežama, osim ako one uključuje osobu mlađu od 16 godina. Možete zatražiti uklanjanje u bilo kom trenutku tako što ćete nas kontaktirati koristeći detalje ispod.</p>

            <br/><br/>
            <p><b>Kontakt informacije</b></p>
            <p>U vezi sa svim pitanjima u vezi sa ovom politikom privatnosti, možete nas kontaktirati koristeći dole navedene informacije.</p>
            <p>5M Zabava Pr</p>
            <p>Orlovića Pavla 12</p>
            <p>18000 Niš</p>
            <p>Srbija</p>
            <p>zabava@5mict.com</p>
            <p>Zadnji put ažurirano 16-09-2022</p>
        </div>
        </div>
    )
}

export default PrivacyPolicyRS;