import { useState } from 'react'
import axios from 'axios'
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants'


function ReceiptCard(props) {

    const { receipt, DeleteReceipt } = props


    return (

        <div className="receipt-container-v2">
            <div className="receipt-container-v2-product-name"><b>{receipt?.appointmentReservation?.product?.nameTranslation?.sr}</b></div>
            <div><b>Team name: </b>{receipt?.appointmentReservation?.teamName}</div>
            <div><b>Date: </b>{receipt?.appointmentReservation?.reservedAppointmentTime?.date.toString().substring(0, 10)}</div>
            <div><b>Time: </b>{receipt?.appointmentReservation?.reservedAppointmentTime?.hour}:{receipt?.appointmentReservation?.reservedAppointmentTime?.minute < 10 ? "0" + receipt?.appointmentReservation?.reservedAppointmentTime?.minute : receipt?.appointmentReservation?.reservedAppointmentTime?.minute} h</div>
            <div><b>Price: </b>{receipt?.price}</div>
            <br />
            <div><b>About the client </b></div>
            {
                receipt?.individual !== null ?
                    <>
                        <div><b>Type: </b>Individual</div>
                        <div><b>Full name: </b>{receipt?.individual?.name + " " + receipt?.individual?.lastname}</div>
                        <div><b>Email: </b>{receipt?.individual?.email}</div>
                        <div><b>Phone: </b>{receipt?.individual?.phone}</div>
                    </>
                    :
                    <>
                        <div><b>Type: </b>Legal entity</div>
                        <div><b>Company: </b>{receipt?.legalEntity?.companyName}</div>
                        <div><b>PIB: </b>{receipt?.legalEntity?.pib}</div>
                        <div><b>Full name: </b>{receipt?.legalEntity?.name + " " + receipt?.legalEntity?.lastname}</div>
                        <div><b>Email: </b>{receipt?.legalEntity?.email}</div>
                        <div><b>Phone: </b>{receipt?.legalEntity?.phone}</div>
                    </>
            }
            <div>
                <button onClick={() => { DeleteReceipt(receipt.id) }} style={{ backgroundColor: "rgb(220,53,69)", border: "1px solid rgb(220,53,69)" }}>Delete</button>
                <button>Edit</button>
            </div>
        </div>
    )
}

export default ReceiptCard;