

function PrivacyPolicyEN(){

    return(
        <>
        <div style={{width: "100%", padding: "15px", backgroundColor: "black", color: "white", fontSize: "2rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
        Escape room Čegar - Privacy Policy
        </div>
        <div style={{width: "100%", maxWidth: "1000px", margin: "0 auto", padding: "10px 10px 50px 10px"}}>
            <br/>
            <p>Our privacy policy explains how we collect, use, protect and handle your Personally Identifiable Information (PII) which you share with us on our website or at the location of our escape room.</p>
            <p>When booking our escape room on our (web)site, you are asked to enter your name, email address, phone number or other details such as address or ID number, so that we can offer you our service and ensure you get the best experience.</p>

            <br/><br/>
            <p><b>When do we collect PII?</b></p>
            <p>We collect information from you when you place an order, i.e. fill out a form at the location of the escape room or enter information on our website.</p>

            <br/><br/>
            <p><b>How do we use your PII?</b></p>
            <p>We will collect and use your PII when you make a purchase to confirm your order and send you the details of your order only. You may contact us to request removal of your PII or ask for details on what exact information we hold.</p>

            <br/><br/>
            <p><b>How do we protect your PII?</b></p>
            <p>•	We use best security practices for our website and scan it on a regular basis for security holes and known vulnerabilities to make it as safe as possible.</p>

            <p>•	Your PII is contained behind secured networks and is only accessible by a limited number of persons who are required to keep the information confidential. In addition, all PII is communicated encrypted via Secure Socket Layer (SSL) technology.</p>
            <p>•	We implement security measures when you place an order to maintain the safety of your PII.</p>
            <p>•	Our web-site is hosted by a commercial cloud provider and all transactions are processed there and are not stored or processed on our servers.</p>

            <br/><br/>
            <p><b>Third-party disclosure</b></p>
            <p>We do not sell, trade, or otherwise transfer to outside parties your PII.</p>

            <br/><br/>
            <p><b>We do not sell, trade, or otherwise transfer to outside parties your PII.</b></p>
            <p>We do not include or offer third-party products or services on our website.</p>

            <br/><br/>
            <p><b>We do not include or offer third-party products or services on our website.</b></p>
            <p>We might upload photographs taken at our venue to social media with your permission, unless it includes a person under the age of 16. You may request removal at any time by contacting us using the details below.</p>

            <br/><br/>
            <p><b>Contact information</b></p>
            <p>With respect to any questions regarding this privacy policy you may contact us using the information below.</p>
            <p>5M Zabava Pr</p>
            <p>Orlovića Pavla 12</p>
            <p>18000 Niš</p>
            <p>Serbia</p>
            <p>zabava@5mict.com</p>
            <p>Last Edited on 16-09-2022</p>
        </div>
        </>
    )
}

export default PrivacyPolicyEN;