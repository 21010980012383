import { useState } from 'react';
import '../../assets/styles/ReservationUser/ReservationUser.scss'

import * as ProductsActionCreators from '../../store/actionCreators/products';
import * as ReservationActionCreators from '../../store/actionCreators/reservation';

import { useSelector, useDispatch, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useEffect } from 'react';
import axios from 'axios';
import PopUpNotification from '../popup-notification/popup-notification';
import { backendRoot } from '../../assets/apiConstants/ApiConstants';
import { CheckCircleFill, Dice1, XCircleFill } from 'react-bootstrap-icons'

import Loading from '../loading/loading';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { isBefore } from 'compare-dates';

function ReservationUser(props) {

    const { t, i18n } = useTranslation()


    const { sendMessage, message, setMessage, clientKey, setLoading, isEscapeRoom } = props

    const reservationSession = useSelector((state) => state.reservationSession)

    const dispatch = useDispatch();
    const { clearReservationTime, clearReservation } = bindActionCreators({ ...ReservationActionCreators, ...ProductsActionCreators }, dispatch)

    const [hour, setHour] = useState('')
    const [minute, setMinute] = useState('')
    const [userType, setUserType] = useState(1)

    const [teamName, setTeamName] = useState('')
    const [numberOfPersons, setNumberOfPersons] = useState('')

    const [duration, setDuration] = useState(1)

    //promo code
    const [promoCode, setPromoCode] = useState("")
    const [loadingCheckCode, setLoadingCheckCode] = useState(false)
    const [voucher, setVoucher] = useState(null)
    const [codeIsChecked, setCodeIsChecked] = useState(false)

    //price

    const [totalPrice, setTotalPrice] = useState('')
    const [pricePP, setPricePP] = useState('')


    //user
    const [name, setName] = useState('')
    const [lastname, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [companyName, setCompanyName] = useState('')
    const [pib, setPib] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')

    //level
    const [level, setLevel] = useState(0)


    const [showForm, setShowForm] = useState(false)


    useEffect(() => {
        if (reservationSession.time.hour !== null && reservationSession.time.minute !== null) {
            setHour(reservationSession.time.hour)
            setMinute(reservationSession.time.minute)
            setShowForm(true)
        }
        else {
            setShowForm(false)
        }
    }, [reservationSession])

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        setVoucher({})
    }, [promoCode])

    const ValidateDate = (start, end) => {
        if (start === null && end === null)
            return true

        //("nije null")
        var dateNow = new Date(reservationSession.date.year, reservationSession.date.month - 1, reservationSession.date.date)

        var startDate = new Date(start)

        var endDate = new Date(end)


        if (dateNow.getFullYear() == startDate.getFullYear() && dateNow.getDate() >= startDate.getDate() && dateNow.getMonth() == startDate.getMonth() || dateNow.getFullYear() >= startDate.getFullYear() && dateNow.getMonth() > startDate.getMonth())
            if (dateNow.getFullYear() == endDate.getFullYear() && dateNow.getDate() <= endDate.getDate() && dateNow.getMonth() == endDate.getMonth() || dateNow.getFullYear() <= endDate.getFullYear() && dateNow.getMonth() < endDate.getMonth())
                return true
        return false
    }
    const [voucherIsValid, setVoucherIsValid] = useState(null)

    useEffect(() => {
        if (isEscapeRoom) {

            if (reservationSession.product !== undefined && reservationSession.product !== null && numberOfPersons !== '') {
                if (!loadingCheckCode && codeIsChecked && voucherIsValid != null && voucherIsValid)
                    if (numberOfPersons > reservationSession.product.discountIfMoreThan) {
                        var discount = 0
                        var price = 0
                        if (voucher.discount === null) {
                            discount = (parseInt(numberOfPersons) - parseInt(reservationSession.product.discountIfMoreThan)) * (parseInt(reservationSession.product.discountPerPerson))
                            price = (parseInt(reservationSession.product.pricePerPerson) * parseInt(numberOfPersons)) - discount * parseInt(numberOfPersons) - ((parseInt(voucher.voucherAmount.amount)))
                        }
                        else {
                            discount = (parseInt(numberOfPersons) - parseInt(reservationSession.product.discountIfMoreThan)) * (parseInt(reservationSession.product.discountPerPerson))
                            var price1 = (parseInt(reservationSession.product.pricePerPerson) * parseInt(numberOfPersons)) - discount * parseInt(numberOfPersons)

                            if (voucher.productId !== null && reservationSession.product.id === voucher.productId && voucher.numberOfPersons !== null) {
                                var price3 = Math.ceil(price1 / parseInt(numberOfPersons))
                                var discount2 = ((price3 * voucher.numberOfPersons) * parseFloat(parseInt(voucher.discount) / 100))
                                price = price1 - discount2
                            }
                            else {
                                price = price1 - (price1 * parseFloat(parseInt(voucher.discount) / 100))
                            }

                        }
                        if (level === 1) {
                            price = price + 1000
                            setTotalPrice(Math.ceil(price))
                            setPricePP(Math.ceil(price / parseInt(numberOfPersons)))
                        }
                        else{
                            setTotalPrice(Math.ceil(price))
                            setPricePP(Math.ceil(price / parseInt(numberOfPersons)))
                        }

                        
                    }
                    else {
                        var discount = 0
                        var price = 0
                        if (voucher.discount === null) {
                            discount = (parseInt(voucher.voucherAmount.amount))
                            price = (parseInt(reservationSession.product.pricePerPerson) * parseInt(numberOfPersons)) - discount
                        }
                        else {
                            discount = (parseInt(reservationSession.product.pricePerPerson) * parseInt(numberOfPersons)) * (parseInt(voucher.discount) / 100)
                            price = (parseInt(reservationSession.product.pricePerPerson) * parseInt(numberOfPersons)) - discount
                        }

                        if (level === 1) {
                            price = price + 1000
                            setTotalPrice(Math.ceil(price))
                            setPricePP(Math.ceil(price / parseInt(numberOfPersons)))
                        }
                        else{
                            setTotalPrice(Math.ceil(price))
                            setPricePP(Math.ceil(price / parseInt(numberOfPersons)))
                        }
                    }
                else
                    if (numberOfPersons > reservationSession.product.discountIfMoreThan) {
                        discount = (parseInt(numberOfPersons) - parseInt(reservationSession.product.discountIfMoreThan)) * (parseInt(reservationSession.product.discountPerPerson))
                        //console.log(discount)

                        price = (parseInt(reservationSession.product.pricePerPerson) * parseInt(numberOfPersons)) - discount * parseInt(numberOfPersons)

                        if (level === 1) {
                            price = price + 1000
                            setTotalPrice(Math.ceil(price))
                            setPricePP(Math.ceil(price / parseInt(numberOfPersons)))
                        }
                        else{
                            setTotalPrice(Math.ceil(price))
                            setPricePP(Math.ceil(price / parseInt(numberOfPersons)))
                        }
                    }
                    else {
                        var price = (parseInt(reservationSession.product.pricePerPerson) * parseInt(numberOfPersons))

                        if (level === 1) {
                            price = price + 1000
                            setTotalPrice(Math.ceil(price))
                            setPricePP(Math.ceil(price / parseInt(numberOfPersons)))
                        }
                        else{
                            setTotalPrice(Math.ceil(price))
                            setPricePP(Math.ceil(price / parseInt(numberOfPersons)))
                        }
                    }
            }
            else {
                setTotalPrice('')
                setPricePP('')
            }

        }
        else {

            if (reservationSession.product !== undefined && reservationSession.product !== null) {
                if (!loadingCheckCode && codeIsChecked && voucherIsValid != null && voucherIsValid) {
                    var discount = 0
                    var price = 0
                    if (voucher.discount === null) {
                        discount = (parseInt(voucher.voucherAmount.amount))
                        var price2 = (parseInt(reservationSession.product.pricePerPerson) * duration)
                        var price3 = duration === 2 ? (parseInt(price2) - reservationSession.product.discountPerPerson) : parseInt(price2)
                        price = (parseInt(price3)) - discount < 0 ? 0 : (parseInt(price3)) - discount
                    }
                    else {
                        var price2 = (parseInt(reservationSession.product.pricePerPerson) * duration)
                        var price3 = duration === 2 ? (parseInt(price2) - reservationSession.product.discountPerPerson) : parseInt(price2)

                        if (voucher.productId !== null && voucher.productId === reservationSession.product.id) {
                            discount = (parseInt(price3)) * (parseInt(voucher.discount) / 100)
                            price = (parseInt(price3)) - discount
                        }
                        else {
                            discount = (parseInt(price3)) * (parseInt(voucher.discount) / 100)
                            price = (parseInt(price3)) - discount
                        }

                    }

                    setTotalPrice(Math.ceil(price))
                    setPricePP(Math.ceil(price))
                }
                else {
                    var price2 = (parseInt(reservationSession.product.pricePerPerson) * duration)
                    var price = duration === 2 ? (parseInt(price2) - reservationSession.product.discountPerPerson) : parseInt(price2)

                    setTotalPrice(Math.ceil(price))
                    setPricePP(Math.ceil(price))
                }
            }
            else {
                setTotalPrice('')
                setPricePP('')
            }
        }
    }, [reservationSession, numberOfPersons, voucher, promoCode, duration, loadingCheckCode, codeIsChecked, voucherIsValid, voucherIsValid, level])

    //popup 

    const [showEmailNotification, setShowEmailNotification] = useState(false)
    const [showWarningNotification, setShowWarningNotification] = useState(false)
    const [showErrorNotification, setShowErrorNotification] = useState(false)



    //promo code
    const handleGetCode = () => {
        if (promoCode != '') {
            setLoadingCheckCode(true)
            setCodeIsChecked(true)

            axios.get(backendRoot + "api/Vouchers/GetByCode/" + promoCode)
                .then((response) => {
                    setLoadingCheckCode(false)
                    setVoucher(response.data)

                })
                .catch((error) => {
                    // handle error
                    //console.log(error);
                    setLoadingCheckCode(false)
                })
        }

    }

    const handleCheckCode = () => {
        setLoadingCheckCode(true)
        //console.log(voucher)
        if (voucher?.code && voucher?.approved && !voucher?.isUsed && ValidateDate(voucher?.startDate, voucher?.endDate)) {
            if (voucher?.productId !== null) {
                if (voucher?.productId === reservationSession.product.id)
                    setVoucherIsValid(true)
                else
                    setVoucherIsValid(false)
            }
            else
                setVoucherIsValid(true)
        }
        else
            setVoucherIsValid(false)
        setLoadingCheckCode(false)
    }

    useEffect(() => {
        if (voucher !== null)
            handleCheckCode()
    }, [voucher, reservationSession])

    useEffect(() => {
        if (message !== "" && message !== null && message !== undefined) {
            var newDate = new Date(message.date)
            //console.log(hour, ">", newDate.getHours() - 2, "-----", minute, ">", newDate.getMinutes())
            if (parseInt(hour) === newDate.getHours() - 2 && parseInt(minute) === newDate.getMinutes() && message.message !== clientKey) {
                clearReservationTime()
                setShowWarningNotification(true)
                setTimeout(() => { setShowWarningNotification(false) }, 4500)
            }
        }
    }, [message])

    const handleSetNumberOfPersons = (value) => {
        if (parseInt(value) < parseInt(reservationSession.product.minNumberOfPersons))
            setNumberOfPersons(reservationSession.product.minNumberOfPersons)
        else
            if (parseInt(value) > parseInt(reservationSession.product.maxNumberOfPersons))
                setNumberOfPersons(reservationSession.product.maxNumberOfPersons)
            else
                setNumberOfPersons(value)
    }



    const clearData = () => {
        setCodeIsChecked(false)
        setPromoCode("")
        goToTop()
        setShowForm(false)
        setVoucher(null)
        setAcceptPP(false)
        clearReservation()
        clearReservationTime()
        setName("")
        setLastName("")
        setEmail("")
        setTeamName("")
        setNumberOfPersons("")
        setPhone("")
    }

    const [teamNameError, setTeamNameError] = useState(false)
    const [numberOfPersonsError, setNumberOfPersonsError] = useState(false)

    const [nameError, setNameError] = useState(false)
    const [lastnameError, setLastNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)

    const [companyNameError, setCompanyNameError] = useState(false)
    const [pibError, setPibError] = useState(false)
    const [addressError, setAddressError] = useState(false)
    const [cityError, setCityError] = useState(false)
    const [postalCodeError, setPostalCodeError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)

    const [dataIsValid, setDataIsValid] = useState(true)

    const validateInput = (value) => {
        var mailformat = /[A-Z]/gi
        if (value !== '' && value.match(mailformat) && value?.length === value.match(mailformat)?.length) {

            return false
        }
        setDataIsValid(false)
        return true
    }

    const validateInputNumber = (value) => {
        if (value !== '' && parseInt(value) > 0) {

            return false
        }
        setDataIsValid(false)
        return true
    }

    const validateInputWithDigits = (value) => {
        var mailformat = /[A-Z]|[0-9]|[' ']/gi
        if (value !== '' && value.match(mailformat) && value?.length === value.match(mailformat)?.length) {

            return false
        }
        setDataIsValid(false)
        return true
    }

    const validatePhone = (_phone) => {
        /*if (!(parseInt(_phone) > 9999999 && parseInt(_phone) < 10000000000)) {
            setDataIsValid(false)
            return true
        }
        return false*/
        var mailformat = /[0-9]|[+]/gi
        if (_phone !== '' && _phone.match(mailformat) && _phone?.length === _phone.match(mailformat)?.length) {

            return false
        }
        setDataIsValid(false)
        return true
    }

    function ValidateEmail(email) {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.match(mailformat)) {
            return false;
        }
        else {
            return true;
        }
    }

    /*const ValidateString = (text) => {
        var mailformat = /[A-Z]/gi;
        console.log(name.match(mailformat), name.length, name.match(mailformat).length)
        if (name.match(mailformat) && name?.length === name.match(mailformat)?.length) {
            console.log("true")
        }
        else {
            console.log("false")
        }
    }*/

    const validateData = async () => {

        if (userType === 1) {
            if (isEscapeRoom) {
                setTeamNameError(validateInputWithDigits(teamName))
                setNumberOfPersonsError(validateInputNumber(numberOfPersons))
            }
            setNameError(validateInput(name))
            setLastNameError(validateInput(lastname))
            setEmailError(ValidateEmail(email))
            setPhoneError(validatePhone(phone))
            setTimeout(() => { setTeamNameError(false) }, 2000)
            setTimeout(() => { setNumberOfPersonsError(false) }, 2000)
            setTimeout(() => { setNameError(false) }, 2000)
            setTimeout(() => { setLastNameError(false) }, 2000)
            setTimeout(() => { setEmailError(false) }, 2000)
            setTimeout(() => { setPhoneError(false) }, 2000)
        }
        else {
            if (isEscapeRoom) {
                setTeamNameError(validateInputWithDigits(teamName))
                setNumberOfPersonsError(validateInputNumber(numberOfPersons))
            }
            setNameError(validateInput(name))
            setLastNameError(validateInput(lastname))
            setEmailError(ValidateEmail(email))
            setCompanyNameError(validateInputWithDigits(companyName))
            setPibError(validateInputNumber(pib))
            setAddressError(validateInputWithDigits(address))
            setCityError(validateInput(city))
            setPostalCodeError(validateInputNumber(postalCode))
            setPhoneError(validatePhone(phone))
            setTimeout(() => { setTeamNameError(false) }, 2000)
            setTimeout(() => { setNumberOfPersonsError(false) }, 2000)
            setTimeout(() => { setNameError(false) }, 2000)
            setTimeout(() => { setLastNameError(false) }, 2000)
            setTimeout(() => { setEmailError(false) }, 2000)
            setTimeout(() => { setCompanyNameError(false) }, 2000)
            setTimeout(() => { setPibError(false) }, 2000)
            setTimeout(() => { setAddressError(false) }, 2000)
            setTimeout(() => { setCityError(false) }, 2000)
            setTimeout(() => { setPostalCodeError(false) }, 2000)
            setTimeout(() => { setPhoneError(false) }, 2000)
        }
    }

    const createReservation = () => {
        setLoading(true)
        var newDate = new Date(reservationSession.date.year, reservationSession.date.month - 1, reservationSession.date.date + 1)

        axios.post(backendRoot + "api/Reservation/Create", {
            mainProductComponentId: reservationSession.product.mainProductComponent.id,
            date: newDate,
            hour: hour,
            minute: minute,
            productId: reservationSession.product.id,
            teamName: isEscapeRoom ? teamName : "-",
            numberOfPersons: isEscapeRoom ? numberOfPersons : 1,
            userType: userType,
            companyName: companyName ? companyName : null,
            pib: pib ? pib : null,
            address: address ? address : null,
            city: city ? city : null,
            postalCode: postalCode ? postalCode : null,
            name: name,
            lastname: lastname,
            email: email,
            phoneNumber: phone,
            totalPrice: totalPrice,
            language: i18n.language === "en" ? "EN" : "RS",
            isER: isEscapeRoom,
            duration: !isEscapeRoom ? duration : null,
            voucherId: voucher !== null ? voucher.id : null,
            isPromoCode: voucher !== null ? voucher.isPromoCode : null,
            level: level
        })
            .then((response) => {
                setShowEmailNotification(true)
                setTimeout(() => { setShowEmailNotification(false) }, 4500)
                setLoading(false)
                clearData()
                sendMessage()
            })
            .catch((error) => {
                // handle error
                //console.log("ERROR")
                setShowErrorNotification(true)
                setTimeout(() => { setShowErrorNotification(false) }, 4500)
                setLoading(false)
                //console.log(error);
            })
    }

    const handleSubmit = async () => {
        await validateData()

        if (userType === 1) {
            if (!validateInputWithDigits(teamName) && !validateInputNumber(numberOfPersons) && !validateInput(name) && !validateInput(lastname) && !ValidateEmail(email) && !validatePhone(phone) && isEscapeRoom)
                createReservation()
            if (!validateInput(name) && !validateInput(lastname) && !ValidateEmail(email) && !validatePhone(phone) && !isEscapeRoom)
                createReservation()
        }
        else {
            if (!validateInputWithDigits(teamName) && !validateInput(numberOfPersons) && !validateInput(name) && !validateInput(lastname) && !ValidateEmail(email) && !validatePhone(phone) && !validateInput(companyName) && !validateInputNumber(pib) && !validateInput(address) && !validateInput(city) && !validateInput(postalCode) && isEscapeRoom)
                createReservation()
            if (!validateInput(name) && !validateInput(lastname) && !ValidateEmail(email) && !validatePhone(phone) && !validateInputWithDigits(companyName) && !validateInputNumber(pib) && !validateInput(address) && !validateInput(city) && !validateInput(postalCode) && !isEscapeRoom)
                createReservation()
        }
    }

    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked(!checked);
    };

    const [participants, setParticipants] = useState([]);

    const [participantsEmail, setParticipantsEmail] = useState('');

    const handleAddParticipant = () => {
        setParticipants([...participants, participantsEmail])
        setParticipantsEmail('')
        //console.log(participants)
    }

    const [acceptPP, setAcceptPP] = useState(false)

    const selectedDate = useSelector((state) => state.reservationSession.date)
    const selectedTime = useSelector((state) => state.reservationSession.time)

    const [available60, setAvailable60] = useState(false)

    const setAvailableTimeDurations = () => {
        var displayTimes = []
        var Availability;
        reservationSession.product.mainProductComponent.availabilities.forEach(availability => {
            if (availability.date !== null) {
                var availabilityDate = new Date(availability.date)
                if (availabilityDate.getFullYear() === selectedDate.year && availabilityDate.getMonth() + 1 === selectedDate.month && availabilityDate.getDate() === selectedDate.date)
                    Availability = availability
            }
            else {
                if (availability.dayId === selectedDate.day)
                    Availability = availability
            }
        });


        var reservedForDate = []

        reservationSession.product.mainProductComponent.reservedAppointmentTimes.forEach(reservedTime => {
            var reservedTimeDate = new Date(reservedTime.date)
            if (reservedTimeDate.getFullYear() === selectedDate.year && reservedTimeDate.getMonth() + 1 === selectedDate.month && reservedTimeDate.getDate() === selectedDate.date)
                reservedForDate.push(reservedTime)
        });


        if (Availability !== null && Availability !== undefined) {
            Availability.startingAppointemntTimes.forEach(startTime => {
                var displayTime = { type: "available", hour: startTime.hour, minute: startTime.minute }
                reservedForDate.forEach(reservedTime => {
                    if (startTime.hour === reservedTime.hour && startTime.minute === reservedTime.minute)
                        displayTime = { type: "reserved", hour: startTime.hour, minute: startTime.minute }
                });
                displayTimes.push(displayTime)
            });

            displayTimes.forEach((dTime, index) => {
                if (dTime.hour === hour && dTime.minute === minute) {
                    if (displayTimes[index + 1] !== undefined && displayTimes[index + 1].type === "available") {
                        setAvailable60(true)
                    }
                    else {
                        setAvailable60(false)
                        setDuration(1)
                    }
                }
            })
        }

    }

    useEffect(() => {
        if (!isEscapeRoom) {
            setAvailableTimeDurations()

        }
    }, [hour, minute])

    useEffect(() => {
        setCodeIsChecked(false)
    }, [promoCode])


    return (
        <>
            <div className="reservation-user-main-container">
                {hour !== "" && minute !== "" && showForm &&
                    <div className="reservation-user-container">
                        <div className="reservation-product-info">

                            <div className="reservation-product-primary-text">
                                {reservationSession.product && <>{i18n.language === "rs" ? reservationSession.product.nameTranslation.sr : reservationSession.product.nameTranslation.en}</>}
                            </div>
                            <div className="reservation-product-primary-text">
                                {t('Date')}: {reservationSession.date && <>{reservationSession.date.date}.{reservationSession.date.month}.{reservationSession.date.year}</>}
                            </div>
                            <div className="reservation-product-primary-text">
                                {t('Time')}: {hour !== '' && minute !== '' ? <>{hour < 10 ? "0" + hour : hour}:{minute < 10 ? "0" + minute : minute} h</> : <>--:--</>}
                            </div>

                            <div className="reservation-user-form">
                                {
                                    isEscapeRoom &&
                                    <>
                                        <input type="text" className="reservation-user-input" placeholder={t('TeamName')} value={teamName} onChange={(e) => { setTeamName(e.target.value) }} />
                                        {teamNameError && <div className="error-message">{t('TeamName')}{t('RequiredField')}</div>}
                                        <input type="number" className="reservation-user-input" placeholder={t('NoOfPersons')} value={numberOfPersons} onChange={(e) => { handleSetNumberOfPersons(e.target.value) }} />
                                        {numberOfPersonsError && (numberOfPersons !== '' ? <div className="error-message">{t('IncorrectInput')}</div> : <div className="error-message">{t('NoOfPersons')}{t('RequiredField')}</div>)}

                                    </>

                                }

                                {/*<div className="reservation-user-participatns">
                                    <div><input className="reservation-user-participatns-checkbox" type="checkbox" checked={checked} onChange={handleChange}/>Obavesti ucesnike</div>
                                    {checked &&
                                    <div>
                                        {participants && participants.map(participant => {<div>{participant}</div>})}
                                        <input type="text" className="reservation-user-input" placeholder={t('Email')} value={participantsEmail} onChange={(e)=>{setParticipantsEmail(e.target.value)}}/>
                                        <button  className="reservation-user-button" onClick={handleAddParticipant}>DODAJ</button>
                                        {teamNameError && <div className="error-message">{t('TeamName')}{t('RequiredField')}</div>}
                                    </div>
                                    }
                                </div>*/}

                            </div>
                            {
                                isEscapeRoom &&
                                <div className="reservation-user-radio">
                                    <input type="radio" name="duration" checked={level === 0} onChange={() => { setLevel(0) }} /><span>{t('level1')}</span><br />
                                    <input type="radio" name="duration" checked={level === 1} onChange={() => { setLevel(1) }} /><span>{t('level2')}</span>
                                </div>
                            }
                            {
                                !isEscapeRoom &&
                                <>
                                    <br />
                                    <div className="reservation-product-primary-text">
                                        {t('AppointmrntDuration')}
                                    </div>
                                </>
                            }
                            {
                                !isEscapeRoom &&

                                <div className="reservation-user-radio">
                                    <input type="radio" name="duration" checked={duration === 1} onChange={() => { setDuration(1) }} /><span>30 min</span><br />
                                    <input type="radio" name="duration" checked={duration === 2} disabled={!available60} onChange={() => { setDuration(2) }} /><span>60 min {!available60 && <>{t('AppointmentIsReserved')}</>}</span>
                                </div>
                            }
                            <div className="reservation-product-primary-text">
                                {t('PromoCode')}
                            </div>
                            <div className="reservation-user-form">
                                <div>
                                    <input type="text" className="reservation-user-input" placeholder={t('PromoCode')} value={promoCode} onChange={(e) => { setPromoCode(e.target.value) }} />
                                    {loadingCheckCode && <span><div></div></span>}
                                    {!loadingCheckCode && codeIsChecked && voucherIsValid != null && !voucherIsValid && <span style={{ color: "red" }}><XCircleFill /></span>}
                                    {!loadingCheckCode && codeIsChecked && voucherIsValid != null && voucherIsValid && <span style={{ color: "green" }}><CheckCircleFill /></span>}
                                </div>
                                <button className="reservation-user-button" onClick={handleGetCode} disabled={loadingCheckCode || promoCode === ''}>{t('ApplyCode')}</button>
                            </div>

                            <div className="reservation-product-primary-text">
                                {totalPrice !== '' && <>{t('PriceTotal')}: {totalPrice} RSD</>}
                            </div>
                            <div className="reservation-product-primary-text">
                                {pricePP !== '' && <>{t('PricePP')}: {pricePP} RSD</>}
                            </div>
                            {
                                !isEscapeRoom &&
                                <div className="reservation-product-primary-text">
                                    {t('DiscountForStudents')}
                                </div>
                            }
                        </div>
                        <br />

                        <div className="reservation-user-radio">
                            <input type="radio" name="user-type" checked={userType === 1 && true} onChange={() => { setUserType(1) }} /><span>{t('Individual')}</span><br />
                            <input type="radio" name="user-type" checked={userType === 2 && true} onChange={() => { setUserType(2) }} /><span>{t('LegalEntity')}</span>
                        </div>

                        {
                            userType === 1 ?
                                <div className="reservation-user-form">
                                    <input maxLength="30" type="text" className="reservation-user-input" placeholder={t('Name')} value={name} onChange={(e) => { setName(e.target.value) }} />
                                    {nameError ? (name === '' ? <div className="error-message">{t('Name')}{t('RequiredField')}</div> : <div className="error-message">{t('IncorrectInput')}</div>) : ""}
                                    <input maxLength="30" type="text" className="reservation-user-input" placeholder={t('Lastname')} value={lastname} onChange={(e) => { setLastName(e.target.value) }} />
                                    {lastnameError ? (lastname === '' ? <div className="error-message">{t('Lastname')}{t('RequiredField')}</div> : <div className="error-message">{t('IncorrectInput')}</div>) : ""}
                                    <input maxLength="100" type="text" className="reservation-user-input" placeholder={t('Email')} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                    {emailError ? (email === '' ? <div className="error-message">{t('Email')}{t('RequiredField')}</div> : <div className="error-message">{t('IncorrectInput')}</div>) : ""}
                                    <input maxLength="15" type="text" className="reservation-user-input" placeholder={t('Phone')} pattern="[0-9]+" required value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                                    {phoneError ? (phone !== '' ? <div className="error-message">{t('IncorrectInput')}</div> : <div className="error-message">{t('Phone')}{t('RequiredField')}</div>) : ""}
                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: "0px" }}>
                                        <input type="checkbox" checked={acceptPP} onChange={() => { setAcceptPP(!acceptPP) }} style={{ margin: "10px", width: "20px", height: "15px" }} />{t('PrivacyPolicy0')}&nbsp;<b><Link style={{ textDecoration: "none", color: "rgb(56,152,236)" }} to={i18n.language === "rs" ? "/privacy-policy-RS" : "/privacy-policy-EN"} target="_blank">{t('PrivacyPolicy01')}</Link></b>
                                    </div>
                                    <button className="reservation-user-button" onClick={handleSubmit} disabled={loadingCheckCode || !acceptPP}>{t('Submit')}</button>
                                </div>
                                :
                                <div className="reservation-user-form">
                                    <input maxLength="30" type="text" className="reservation-user-input" placeholder={t('Name')} value={name} onChange={(e) => { setName(e.target.value) }} />
                                    {nameError ? (name === '' ? <div className="error-message">{t('Name')}{t('RequiredField')}</div> : <div className="error-message">{t('IncorrectInput')}</div>) : ""}
                                    <input maxLength="30" type="text" className="reservation-user-input" placeholder={t('Lastname')} value={lastname} onChange={(e) => { setLastName(e.target.value) }} />
                                    {lastnameError ? (lastname === '' ? <div className="error-message">{t('Lastname')}{t('RequiredField')}</div> : <div className="error-message">{t('IncorrectInput')}</div>) : ""}
                                    <input maxLength="100" type="text" className="reservation-user-input" placeholder={t('Email')} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                    {emailError ? (email === '' ? <div className="error-message">{t('Email')}{t('RequiredField')}</div> : <div className="error-message">{t('IncorrectInput')}</div>) : ""}
                                    <input maxLength="50" type="text" className="reservation-user-input" placeholder={t('CompanyName')} value={companyName} onChange={(e) => { setCompanyName(e.target.value) }} />
                                    {companyNameError ? (companyName === '' ? <div className="error-message">{t('CompanyName')}{t('RequiredField')}</div> : <div className="error-message">{t('IncorrectInput')}</div>) : ""}
                                    <input maxLength="30" type="text" className="reservation-user-input" placeholder={t('TaxId')} value={pib} onChange={(e) => { setPib(e.target.value) }} />
                                    {pibError ? (pib === '' ? <div className="error-message">{t('TaxId')}{t('RequiredField')}</div> : <div className="error-message">{t('IncorrectInput')}</div>) : ""}
                                    <input maxLength="100" type="text" className="reservation-user-input" placeholder={t('Address')} value={address} onChange={(e) => { setAddress(e.target.value) }} />
                                    {addressError ? (address === '' ? <div className="error-message">{t('Address')}{t('RequiredField')}</div> : <div className="error-message">{t('IncorrectInput')}</div>) : ""}
                                    <input maxLength="50" type="text" className="reservation-user-input" placeholder={t('City')} value={city} onChange={(e) => { setCity(e.target.value) }} />
                                    {cityError ? (city === '' ? <div className="error-message">{t('City')}{t('RequiredField')}</div> : <div className="error-message">{t('IncorrectInput')}</div>) : ""}
                                    <input maxLength="10" type="text" className="reservation-user-input" placeholder={t('PostalCode')} value={postalCode} onChange={(e) => { setPostalCode(e.target.value) }} />
                                    {postalCodeError ? (postalCode === '' ? <div className="error-message">{t('PostalCode')}{t('RequiredField')}</div> : <div className="error-message">{t('IncorrectInput')}</div>) : ""}
                                    <input maxLength="15" type="text" className="reservation-user-input" placeholder={t('Phone')} value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                                    {phoneError ? (phone !== '' ? <div className="error-message">{t('IncorrectInput')}</div> : <div className="error-message">{t('Phone')}{t('RequiredField')}</div>) : ""}
                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: "0px" }}>
                                        <input type="checkbox" checked={acceptPP} onChange={() => { setAcceptPP(!acceptPP) }} style={{ margin: "10px", width: "20px", height: "15px" }} />{t('PrivacyPolicy0')}&nbsp;<b><Link style={{ textDecoration: "none", color: "rgb(56,152,236)" }} to={i18n.language === "rs" ? "/privacy-policy-RS" : "/privacy-policy-EN"} target="_blank">{t('PrivacyPolicy01')}</Link></b>
                                    </div>
                                    <button className="reservation-user-button" onClick={handleSubmit} disabled={loadingCheckCode || !acceptPP}>{t('Submit')}</button>
                                </div>
                        }
                    </div>
                }
            </div>
            <PopUpNotification isShow={showEmailNotification} type={"success"} />
            <PopUpNotification isShow={showWarningNotification} type={"warning"} />
            <PopUpNotification isShow={showErrorNotification} type={"error"} />
        </>
    );
}

export default ReservationUser