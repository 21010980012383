import { useState, useEffect } from 'react'
import axios from 'axios'
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants'
import { ChevronLeft, ChevronRight, Search, X, Check } from 'react-bootstrap-icons'
import { useSelector } from 'react-redux';

function ReceiptCardDetails(props) {

    const { receipt, DeleteReceipt, handleUpdate } = props

    const allProducts = useSelector((state) => state.productsSession.allProducts)

    const [teamName, setTeamName] = useState(receipt?.appointmentReservation?.teamName)
    const [succesfulyCompleted, setSuccesfulyCompleted] = useState(false)
    const [timeToFinish, setTimeToFinish] = useState(0)


    const [edit, setEdit] = useState(false)
    const [changeDateTime, setChangeDateTime] = useState(false)
    const [date, setDate1] = useState(new Date())
    const [displayDate, setDisplayDate] = useState("")
    const [displayedTimes, setDisplayedTimes] = useState([])

    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState({ hour: null, minute: null })

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setDisplayedTimes([])
        setSelectedTime({ hour: null, minute: null })
    }, [displayDate])

    useEffect(() => {
        if (receipt?.appointmentReservation.seccessfulltyCompleted !== null) {
            if (receipt?.appointmentReservation.seccessfulltyCompleted)
                setSuccesfulyCompleted(true)
            else
                setSuccesfulyCompleted(false)
        }
    }, [receipt])

    useEffect(() => {
        if (selectedDate === null) {
            //console.log({ year: date.getFullYear(), month: date.getMonth() + 1, date: date.getDate(), day: date.getDay() })
            setSelectedDate({ year: date.getFullYear(), month: date.getMonth() + 1, date: date.getDate(), day: date.getDay() })
        }

    }, [selectedDate])

    useEffect(() => {
        if (displayDate === "")
            setDisplayDate(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())
    }, [displayDate])

    const addDate = () => {
        setSelectedTime({ hour: null, minute: null })
        var newDate = date
        newDate.setDate(newDate.getDate() + 1)
        setDisplayDate(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())
        setSelectedDate({ year: date.getFullYear(), month: date.getMonth() + 1, date: date.getDate(), day: date.getDay() })
        setDate1(newDate)
    }

    const subDate = () => {
        setSelectedTime({ hour: null, minute: null })
        var newDate = date
        newDate.setDate(newDate.getDate() - 1)
        setDisplayDate(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())
        setSelectedDate({ year: date.getFullYear(), month: date.getMonth() + 1, date: date.getDate(), day: date.getDay() })
        setDate1(newDate)
    }

    const setData = () => {
        setDisplayedTimes([])
        var product = null

        allProducts.forEach((p) => {
            if (p.id === receipt?.appointmentReservation?.productId)
                product = p
        })

        if (product !== null && selectedDate !== null) {
            //console.log(selectedDate)
            var displayTimes = []
            var Availability;
            product.mainProductComponent.availabilities.forEach(availability => {
                if (availability.date !== null) {
                    var availabilityDate = new Date(availability.date)
                    if (availabilityDate.getFullYear() === selectedDate.year && availabilityDate.getMonth() + 1 === selectedDate.month && availabilityDate.getDate() === selectedDate.date)
                        Availability = availability
                }
                else {
                    if (availability.dayId === selectedDate.day)
                        Availability = availability
                }
            });

            /*if (Availability !== null && Availability !== undefined)
                setAvailableTimes(Availability.startingAppointemntTimes)*/

            var reservedForDate = []

            product.mainProductComponent.reservedAppointmentTimes.forEach(reservedTime => {

                var reservedTimeDate = new Date(reservedTime.date)
                if (reservedTimeDate.getFullYear() === selectedDate.year && reservedTimeDate.getMonth() + 1 === selectedDate.month && reservedTimeDate.getDate() === selectedDate.date)
                    reservedForDate.push(reservedTime)
            });

            //console.log(reservedForDate)

            if (Availability !== null && Availability !== undefined) {
                Availability.startingAppointemntTimes.forEach(startTime => {
                    var displayTime = { type: "available", hour: startTime.hour, minute: startTime.minute }
                    reservedForDate.forEach(reservedTime => {
                        if (startTime.hour === reservedTime.hour && startTime.minute === reservedTime.minute)
                            displayTime = { type: "reserved", hour: startTime.hour, minute: startTime.minute }
                    });
                    displayTimes.push(displayTime)
                });

                setDisplayedTimes(displayTimes)
            }
        }

    }

    const handleSave = async () => {
        if (receipt.appointmentReservation !== null && receipt.appointmentReservation.teamName !== null) {
            setLoading(true)
            await axios.post(backendRoot + "api/AppointmentReservations/Update",
                {
                    id: receipt.appointmentReservation.id,
                    productId: receipt.appointmentReservation.productId,
                    reservedAppointmentTimeId: receipt.appointmentReservation.reservedAppointmentTimeId,
                    teamName: receipt.appointmentReservation.teamName !== teamName ? teamName : receipt.appointmentReservation.teamName,
                    numberOfPersons: receipt.appointmentReservation.numberOfPersons,
                    timeToFinishInMinutes: timeToFinish,
                    seccessfulltyCompleted: succesfulyCompleted,
                })
                .then((response) => {
                    //console.log(response)
                    handleUpdate()
                    setLoading(false)
                })
                .catch((error) => {
                    // handle error
                    setLoading(false)
                })
        }
    }

    const handleChangeDateAndTime = async () => {
        if (receipt.appointmentReservation !== null && selectedTime.hour !== null && selectedTime.minute !== null) {
            setLoading(true)
            await axios.put(backendRoot + "api/ReservedAppointmentTimes/Update",
                {
                    id: receipt?.appointmentReservation.reservedAppointmentTimeId,
                    date: date,
                    hour: selectedTime?.hour,
                    minute: selectedTime?.minute,
                })
                .then((response) => {
                    //console.log(response)
                    handleUpdate()
                    setLoading(false)
                })
                .catch((error) => {
                    // handle error
                    setLoading(false)
                })
        }
    }


    return (

        <div className="receipt-container-v2">
            <div className="receipt-container-v2-product-name"><b>{receipt?.appointmentReservation?.product?.nameTranslation?.sr}</b></div>
            <div>
                <b>Team name: </b>
                {edit ?
                    <>
                        <input type="text" value={teamName} onChange={(e) => { setTeamName(e.target.value) }} />

                    </>
                    :
                    <>
                        {receipt?.appointmentReservation?.teamName}
                    </>
                }
            </div>
            <div><b>Date: </b>{receipt?.appointmentReservation?.reservedAppointmentTime?.date.toString().substring(0, 10)}</div>
            <div><b>Time: </b>{receipt?.appointmentReservation?.reservedAppointmentTime?.hour}:{receipt?.appointmentReservation?.reservedAppointmentTime?.minute < 10 ? "0" + receipt?.appointmentReservation?.reservedAppointmentTime?.minute : receipt?.appointmentReservation?.reservedAppointmentTime?.minute} h</div>


            {changeDateTime ?
                <div style={{ border: "1px solid rgb(60, 75, 100)", padding: "5px", borderRadius: "5px" }}>
                    <div><b>Change date and time</b></div>
                    <div className="reservation-overview-date-container-buttons">
                        <button onClick={subDate}><ChevronLeft /></button>{displayDate}<button onClick={addDate}><ChevronRight /></button><button onClick={setData}>Get available times</button>
                    </div>
                    <div><b>Available times</b></div>
                    {
                        displayedTimes.length > 0 &&
                        displayedTimes.map((time, index) =>
                            <div key={index}>
                                {
                                    time.type === "available"
                                        ?
                                        <div className="product-card-available-time" style={{
                                            border: selectedTime.hour === time.hour && selectedTime.minute === time.minute && "1px solid rgb(84, 44, 85)",
                                            backgroundColor: selectedTime.hour === time.hour && selectedTime.minute === time.minute && "rgb(84, 44, 85)",
                                            color: selectedTime.hour === time.hour && selectedTime.minute === time.minute && "white"
                                        }} onClick={() => { setSelectedTime({ hour: time.hour, minute: time.minute }) }}>
                                            {time.hour < 10 ? "0" + time.hour : time.hour}:{time.minute < 10 ? "0" + time.minute : time.minute}
                                        </div>
                                        :
                                        <div className="product-card-available-time" style={{ filter: "blur(2px)" }}>
                                            {time.hour < 10 ? "0" + time.hour : time.hour}:{time.minute < 10 ? "0" + time.minute : time.minute}
                                        </div>
                                }
                            </div>
                        )
                    }
                    {
                        selectedTime.hour !== null &&
                        <>
                            <button onClick={handleChangeDateAndTime}>Set date and time</button>

                        </>
                    }
                    <button onClick={() => { setChangeDateTime(false); setSelectedTime({ hour: null, minute: null }) }} style={{ backgroundColor: "rgb(220,53,69)", border: "1px solid rgb(220,53,69)" }}>Cancel</button>
                </div>
                :
                <>{
                    edit &&
                    <button onClick={() => { setChangeDateTime(true) }}>Change date and time</button>
                }</>

            }
            <div><b>Price: </b>{receipt?.price}</div>
            {edit && receipt.appointmentReservation !== null && receipt.appointmentReservation !== undefined ?
                <>
                    <div><b>Seccessfullty completed: </b><input checked={succesfulyCompleted} onChange={(e) => { setSuccesfulyCompleted(true) }} type="checkbox" /> Yes <input checked={!succesfulyCompleted} onChange={(e) => { setSuccesfulyCompleted(false) }} type="checkbox" /> No</div>
                    {succesfulyCompleted && <div><b>Time to finish: </b><input type="number" value={timeToFinish} onChange={(e) => { setTimeToFinish(e.target.value) }} />min</div>}
                </>
                :
                <>
                    <div><b>Seccessfullty completed: </b>{receipt?.appointmentReservation.seccessfulltyCompleted ? "Yes" : "No"}</div>
                    {receipt?.appointmentReservation.seccessfulltyCompleted && <div><b>Time to finish: </b>{receipt?.appointmentReservation.timeToFinishInMinutes}</div>}
                </>
            }
            <br />

            <br />
            <div><b>About the client </b></div>
            {
                receipt?.individual !== null ?
                    <>
                        <div><b>Type: </b>Individual</div>
                        <div><b>Full name: </b>{receipt?.individual?.name + " " + receipt?.individual?.lastname}</div>
                        <div><b>Email: </b>{receipt?.individual?.email}</div>
                        <div><b>Phone: </b>{receipt?.individual?.phone}</div>
                    </>
                    :
                    <>
                        <div><b>Type: </b>Legal entity</div>
                        <div><b>Company: </b>{receipt?.legalEntity?.companyName}</div>
                        <div><b>PIB: </b>{receipt?.legalEntity?.pib}</div>
                        <div><b>Full name: </b>{receipt?.legalEntity?.name + " " + receipt?.legalEntity?.lastname}</div>
                        <div><b>Email: </b>{receipt?.legalEntity?.email}</div>
                        <div><b>Phone: </b>{receipt?.legalEntity?.phone}</div>
                    </>
            }
            <div>
                {edit ?
                    <>
                        <button onClick={() => { setEdit(false); handleSave(); }}>Save</button>
                        <button onClick={() => { setEdit(false) }} style={{ backgroundColor: "rgb(220,53,69)", border: "1px solid rgb(220,53,69)" }}>Cancel</button>
                    </>
                    :
                    <>
                        <button onClick={() => { DeleteReceipt(receipt.id) }} style={{ backgroundColor: "rgb(220,53,69)", border: "1px solid rgb(220,53,69)" }}>Delete</button>
                        <button onClick={() => { setEdit(true) }}>Edit</button>
                    </>
                }
            </div>
        </div>
    )
}

export default ReceiptCardDetails;