
import { useState, useEffect } from 'react';
import '../../assets/styles/AdminPanel/AdminPanel.scss'

import { Search, PlusCircle, PersonPlus, Eye, Box, CurrencyExchange, DashCircle, ChevronLeft, BarChart } from 'react-bootstrap-icons'
import FindVoucher from '../../components/find-voucher/find-voucher-v2';
import CreateVoucher from '../../components/create-voucher/create-voucher-v2';
import ReservationOverview from '../../components/reservation-overview/reservation-overview';
import Reservation from '../../../User/containers/Reservation/Reservation';
import AdminPrices from '../../components/admin-prices/admin-prices';
import AdminProductCard from '../../components/admin-product-card/admin-product-card';
import { useSelector, useDispatch } from 'react-redux';
import CreateProduct from '../../components/create-product/create-product';
import Rooms from '../../components/rooms/rooms';
import CreateComponent from '../../components/create-component/create-component';
import AdminGallery from '../../components/admin-gallery/admin-gallery';
import ReservationCalendarOverview from '../../components/reservation-calendar-overview/reservation-calendar-overview';
import VoucherMessage from '../../components/voucher-message/voucher-message';
import AdminStatistics from '../../components/admin-statistics/admin-statistics';

function AdminPanel(props) {

    const allProducts = useSelector((state) => state.productsSession.allProducts)

    const { sendMessage, message, setMessage, clientKey, daysWithoutAppointments } = props

    const [selectedIndex, setSelectedIndex] = useState(17)

    const CloseMenu = () => {
        var element = document.getElementById('admin-left-menu')

        if (element !== null && element !== undefined) {
            element.className = "left-admin-panel-menu close-menu-v2"
        }
    }

    window.addEventListener("resize", () => {
        var element = document.getElementById('admin-left-menu')

        if (element !== null && element !== undefined) {
            element.className = "left-admin-panel-menu"
        }
    })

    const [roomToEdit, setRoomToEdit] = useState(null)
    const [additinalToEdit, setAdditinalToEdit] = useState(null)

    useEffect(()=>{
        if(roomToEdit !== null)
        {
            setSelectedIndex(7)
        }
    },[roomToEdit])

    useEffect(()=>{
        if(additinalToEdit !== null)
        {
            setSelectedIndex(7)
        }
    },[additinalToEdit])

    useEffect(()=>{
        if(selectedIndex !== 7)
        {
            setRoomToEdit(null)
            setAdditinalToEdit(null)
        }
            
    },[selectedIndex])

    return (
        <>
            <div className="left-admin-panel-menu" id="admin-left-menu">
                <div className="left-admin-panel-menu-logo">
                    Admin panel
                </div>
                <div className="left-admin-panel-menu-2">
                    <div className="left-admin-panel-menu-items">
                        <b>STATISTICS</b>
                        <div onClick={() => { setSelectedIndex(17) }}><span><BarChart /> Statistics</span></div>
                        <b>RESERVATIONS</b>
                        <div onClick={() => { setSelectedIndex(15) }}><span><Search /> Overview</span></div>
                        <div onClick={() => { setSelectedIndex(0) }}><span><Search /> Find reservation</span></div>
                        <div onClick={() => { setSelectedIndex(1) }}><span><PlusCircle /> New reservation</span></div>
                        {/*<div onClick={() => { setSelectedIndex(2) }}><span><Box /> Package</span></div>*/}
                        <div onClick={() => { setSelectedIndex(3) }}><span><CurrencyExchange /> Prices</span></div>
                        <b>PRODUCTS</b>
                        <div onClick={() => { setSelectedIndex(4) }}><span><Eye /> All products</span></div>
                        <div onClick={() => { setSelectedIndex(5) }}><span><PlusCircle /> Create product</span></div>
                        <div onClick={() => { setSelectedIndex(6) }}><span><Eye /> All components</span></div>
                        <div onClick={() => { setSelectedIndex(7) }}><span><PlusCircle /> Create component</span></div>
                        <b>VOUCHERS</b>
                        <div onClick={() => { setSelectedIndex(8) }}><span><Search /> Overview</span></div>
                        <div onClick={() => { setSelectedIndex(9) }}><span><PlusCircle /> Create voucher</span></div>
                        <div onClick={() => { setSelectedIndex(16) }}><span><PlusCircle /> Voucher message</span></div>
                        <b>GALERY</b>
                        <div onClick={() => { setSelectedIndex(11) }}><span><PlusCircle /> Add image</span></div>
                        <div onClick={() => { setSelectedIndex(12) }}><span><DashCircle /> Remove image</span></div>
                        <b>ACCOUNTS</b>
                        <div onClick={() => { setSelectedIndex(13) }}><span><Eye /> All accounts</span></div>
                        <div onClick={() => { setSelectedIndex(14) }}><span><PersonPlus /> Create new account</span></div>
                    </div>
                </div>
                <div className="left-admin-panel-menu-caret">
                    <ChevronLeft onClick={CloseMenu} />
                </div>
            </div>
            <div className="left-admin-panel-container">
            {selectedIndex === 17 && <AdminStatistics />}
                {selectedIndex === 15 && <ReservationCalendarOverview isOverview={true} daysWithoutAppointments={daysWithoutAppointments} userType={"admin"} sendMessage={sendMessage} message={message} setMessage={setMessage} clientKey={clientKey} />}
                {selectedIndex === 0 && <ReservationOverview />}
                {selectedIndex === 1 && <Reservation daysWithoutAppointments={daysWithoutAppointments} userType={"admin"} sendMessage={sendMessage} message={message} setMessage={setMessage} clientKey={clientKey} />}
                {selectedIndex === 3 && <AdminPrices />}
                {selectedIndex === 4 && 
                <>
                {allProducts.map((product,index)=>
                    <AdminProductCard key={index} product={product}/>
                )}
                </>}
                {selectedIndex === 5 && <CreateProduct setSelectedOption={setSelectedIndex}/>}
                {selectedIndex === 6 && <Rooms setRoomToEdit={setRoomToEdit} roomToEdit={roomToEdit} setAdditinalToEdit={setAdditinalToEdit} additinalToEdit={additinalToEdit}/>}
                {selectedIndex === 7 && <CreateComponent setSelectedOption={setSelectedIndex} roomToEdit={roomToEdit} additinalToEdit={additinalToEdit}/>}
                
                {selectedIndex === 3 && <AdminPrices />}
                {selectedIndex === 8 && <FindVoucher />}
                {selectedIndex === 9 && <CreateVoucher />}
                {selectedIndex === 16 && <VoucherMessage />}

                {selectedIndex === 11 && <AdminGallery />}
            </div>
        </>
    )
}

export default AdminPanel;