import '../../assets/styles/PopUpNotification/PopUpNotification.scss'
import SuccessIcon from '../../assets/images/icons/notification1.png'
import WarningIcon from '../../assets/images/icons/notification2.png'
import ErrorIcon from '../../assets/images/icons/notification3.png'

function PopUpNotification(props){
    const {isShow, text, type} = props
    return(
        <div className={isShow ? "popup-container show-container":"popup-container"} style={{backgroundColor: type === 'success' ? "rgb(78,211,78)":type === 'error' ?"rgb(255,57,46)":"rgb(255,158,20)"}}>
            <div className="popup-icon-container" style={{backgroundColor: type === 'success' ? "rgb(58,206,58":type === 'error' ?"rgb(255,32,21)":"rgb(250,146,0)"}}>
                <img className="bell-icon" src={type === 'success' ? SuccessIcon : type === 'error' ? ErrorIcon : WarningIcon} alt=""/>
            </div>
            <div className="popup-message-container">
                {type === 'success' ? <h4>Successfully</h4>:<>{type === 'error' ? <h4>Error</h4>:<h4>Warning</h4>}</>}
                {type === 'success' ? <div>Check your e-mail!</div>:<>{type === 'error' ? <div>An error occurred, please check the information you entered or try again later.</div>:<div>The appointment you have chosen has just been reserved</div>}</>}
            </div>
            
        </div>
    )
}

export default PopUpNotification