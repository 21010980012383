//PRODUCTS

export const PRODUCTS_ALL_PERSIST = "PRODUCTS_ALL_PERSIST";
export const PRODUCTS_AVAILABILITIES = "PRODUCTS_AVAILABILITIES";
export const PRODUCTS_START_LOADING = "PRODUCTS_START_LOADING";
export const PRODUCTS_FINISH_LOADING = "PRODUCTS_FINISH_LOADING";
export const PRODUCTS_CLEAR = "PRODUCTS_CLEAR";

//RESERVATION

export const RESERVATION_DATE_SET = "RESERVATION_DATE_SET";
export const RESERVATION_TIME_SET = "RESERVATION_TIME_SET";
export const RESERVATION_TIME_CLEAR = "RESERVATION_TIME_CLEAR";
export const RESERVATION_MAIN_COMPONENT_ID_SET = "RESERVATION_PRODUCT_ID_SET";
export const RESERVATION_STARTING_APPOINTMENT_TIME = "RESERVATION_STARTING_APPOINTMENT_TIME";
export const RESERVATION_APPOINTMENT_RESERVATION = "RESERVATION_APPOINTMENT_RESERVATION";
export const RESERVATION_USER = "RESERVATION_USER";
export const RESERVATION_RECEIPT = "RESERVATION_RECEIPT";
export const RESERVATION_CLEAR_ALL = "RESERVATION_CLEAR_ALL";
export const RESERVATION_START_LOADING = "RESERVATION_START_LOADING";
export const RESERVATION_FINISH_LOADING = "RESERVATION_FINISH_LOADING";
export const RESERVATION_SET_ERROR = "RESERVATION_SET_ERROR";
export const RESERVATION_CREATE = "RESERVATION_CREATE";
export const RESERVATION_DELETE = "RESERVATION_DELETE";


