import {useState} from 'react'
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants';
import Loading from '../../../User/components/loading/loading';
import '../../assets/styles/MainProductComponentCard/MainProductComponentCard.scss'
import axios from 'axios';

function ProductComponentCard(props){
    const {component, handleSelect, selected, GetMainProductComponents, edit, setComponentToEdit} = props

    const [loading, setLoading] = useState(false)

    const DeleteComponent = async () => {
        setLoading(true)
        await axios.delete(backendRoot + "api/MainProductComponents/Delete/" + component.id,
        {
            withCredentials: true
        })
            .then((response) =>{
                //console.log(response)
                GetMainProductComponents()
                setLoading(false)
            })
            .catch((error) =>{
                //console.log(error);
                setLoading(false)
            })
    }

    return(
        <>
        {loading && <Loading/>}
        <div  className={selected ? "create-product-main-component-container-selected":"create-product-main-component-container"}>
            <div>
                <img src={backendRoot + "Images/" + component.imageName} alt="image"/>
                <div><b>Name(EN): </b>{component.nameTranslation.en}</div>
                <div><b>Name(SR): </b>{component.nameTranslation.sr}</div><br/>
                <div><b>Description(EN): </b>{component.descriptionTranslation.en}</div><br/>
                <div><b>Description(SR): </b>{component.descriptionTranslation.sr}</div><br/>
            </div>
            <div>
                {/*newProductType.name === "Single" ? 
                <>
                <p>Room is used</p>
                </>
                :
                <>
                <button onClick={()=>{handleSelect(component)}}>Select</button>
                </>
    */}
                {edit ? 
                <>
                <button onClick={()=>{setComponentToEdit(component)}}>Edit</button>
                <button onClick={DeleteComponent}>Delete</button>
                </>
                :
                <button onClick={()=>{handleSelect(component)}}>Select</button>
                }
            </div>     
        </div>
        </>
    )
}

export default ProductComponentCard;