import {
    USER_LOGIN,
    USER_LOGOUT,
    USER_START_LOADING,
    USER_FINISH_LOADING
} from '../actionTypes/actionTypes'

const initialState = {
    data: null,
    isLogin: false,
    loading: false,
    message: ""
}

export default (state = initialState, action) => {
    switch(action.type){
        case USER_LOGIN:
            return {...state, data: action.payload, isLogin: true}
        case USER_LOGOUT:
            return {...state, data: null, isLogin: false}
        case USER_START_LOADING:
            return {...state, loading: true, message: action.payload}
        case USER_FINISH_LOADING:
            return {...state, loading: false, message: action.payload}
        default:
            return state
    }
}