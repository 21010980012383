import i18next from 'i18next';
import english from "../../assets/translations/english.json";
import serbian from "../../assets/translations/serbian.json";
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
    lng: 'rs',
    resources: {
        en: english,
        rs: serbian,
    },
    react: {
        useSuspense: false
    }
});

export default i18next;