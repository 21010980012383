import react from "react";
import '../../assets/styles/HomePromotional/HomePromotional.scss'
import { useTranslation } from 'react-i18next';
import * as Icon from 'react-bootstrap-icons';

function HomePromotional() {

    const { t, i18n } = useTranslation()

    return (
        <div className="home-promotional-container">
            <div className="home-promotional-card ">
                <Icon.PeopleFill style={{ color: "#f4ff9e", fontSize: "70px", marginBottom: "10px" }} />
                <h2 className="home-promotional-heading">{t('PromotionalTip11')}</h2>
                <div className="divider-yellow _3"></div>
                <p className="paragraph-2">{t('PromotionalTip1')}</p>
            </div>
            <div className="home-promotional-card">
                <Icon.Calendar2Event style={{ color: "#f4ff9e", fontSize: "70px", marginBottom: "10px"  }} />
                <h2 className="home-promotional-heading">{t('PromotionalTip22')}</h2>
                <div className="divider-yellow _3"></div>
                <p className="paragraph-2">{t('PromotionalTip2')}</p>
            </div>
            <div className="home-promotional-card">
                <Icon.Globe2 style={{ color: "#f4ff9e", fontSize: "70px", marginBottom: "10px"  }} />
                <h2 className="home-promotional-heading">{t('PromotionalTip33')}</h2>
                <div className="divider-yellow _3"></div>
                <p className="paragraph-2">{t('PromotionalTip3')}</p>
            </div>
        </div>
    );
}
export default HomePromotional