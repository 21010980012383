import React, { useState, useEffect } from "react";
import '../../assets/styles/Navbar/Navbar.scss'
import Asset153xp500 from "../../assets/images/Asset-153x-p-500.png"
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { List } from "react-bootstrap-icons";
import RS from '../../assets/images/Navbar/RS.png'
import EN from '../../assets/images/Navbar/EN.png'

import { useTranslation } from "react-i18next";
import i18next from '../../utils/i18n/i18n'
import { XCircle } from 'react-bootstrap-icons'
import axios from "axios";
import { backendRoot } from "../../assets/apiConstants/ApiConstants";

function Navbar() {

    const [t, i18n] = useTranslation();

    const handleChangeLanguage = (language) => {
        i18n.changeLanguage(language);
    }

    const OpenCloseMenu = () => {
        var menu = document.getElementById('menu')
        if (menu.style.display === "block")
            menu.style.display = "none";
        else
            menu.style.display = "block";
    }
    const [isRendered, setIsRendered] = useState(false)
    useEffect(() => {
        setIsRendered(true)
        if (isRendered)
            window.onscroll = function () { scrollFunction() };
    })



    function scrollFunction() {
        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
            document.getElementById("navbar").style.padding = showMessage ? "30px 0px 0px 0px" : "0px";
            document.getElementById("navbar").style.background = "black";
            document.getElementById("navbar").style.borderBottom = "1px solid rgb(244,255,158)";
            //document.getElementById("logo").style.fontSize = "25px";
        } else {
            document.getElementById("navbar").style.padding = "50px 0px 50px 0px";
            document.getElementById("navbar").style.background = "transparent";
            document.getElementById("navbar").style.borderBottom = "none";
            //document.getElementById("logo").style.fontSize = "35px";
        }
    }

    const [selectedMenuOptionIndex, setSelectedMenuOptionIndex] = useState(1)

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState(null)

    useEffect(() => {
        scrollFunction()
    }, [showMessage])

    const ValidateDate = (start, end) => {
        if (start !== null && end !== null) {
            var dateNow = new Date()
            var startDate = new Date(start.split("-")[0], start.split("-")[1] - 1, start.split("-")[2])
            var endDate = new Date(end.split("-")[0], end.split("-")[1] - 1, end.split("-")[2])

            //console.log(dateNow, startDate, endDate)

            if (dateNow.getFullYear() == startDate.getFullYear() && dateNow.getDate() >= startDate.getDate() && dateNow.getMonth() == startDate.getMonth() || dateNow.getFullYear() >= startDate.getFullYear() && dateNow.getMonth() > startDate.getMonth())
                if (dateNow.getFullYear() == endDate.getFullYear() && dateNow.getDate() <= endDate.getDate() && dateNow.getMonth() == endDate.getMonth() || dateNow.getFullYear() <= endDate.getFullYear() && dateNow.getMonth() < endDate.getMonth())
                    return true
        }
        return false
    }

    const getMessage = async () => {
        axios.get(backendRoot + "api/Vouchers/GetMessage")
            .then((response) => {
                if (ValidateDate(response.data.startDate, response.data.endDate)) {
                    setMessage(response.data.message)
                    setShowMessage(true)
                }
                else
                    setMessage("")
            })
            .catch((error) => {
                // handle error
                //console.log(error);
            })
    }

    const [messageIsGet, setMessageIsGet] = useState(false)


    useEffect(() => {
        if (message === null && !messageIsGet)
            getMessage()
        return function () {
            setMessageIsGet(true)
        };
    }, [message, messageIsGet])

    return (
        <>
            {/*<div className="header-container-lg d-none d-sm-none d-md-none d-lg-block">
            <div className="header-lg">
                <div className="nav-logo-lg">
                    <img className="nav-logo-img" src={Asset153xp500}/>
                    <span className="nav-logo-text-lg">ESCAPE ROOM</span>
                </div>
                <div className="nav-options-lg">
                        <Link className="nav-option-lg" to="home">Home</Link>
                        <Link className="nav-option-lg" to="reservation">Reservation</Link>
                        <Link className="nav-option-lg" to="home">Contact</Link>
                </div>

            </div>
    </div>*/}



            <div id="navbar" className="header-container-lg d-none d-sm-none d-md-none d-lg-block">
                {showMessage &&
                    <div style={{ width: "100%", color: "black", backgroundColor: "rgb(244,255,158)", textAlign: "center", padding: "3px", position: "absolute", top: 0 }}>
                        {message !== null && message}
                        <XCircle onClick={() => { setShowMessage(false) }} style={{ position: "absolute", right: 10, top: 5 }} />
                    </div>
                }
                <div className="container-lg-top">

                    {/*<div className="container-lg-top-item">
                    {i18next.t('CallUs')}: <br/> 018 523 632
                </div>
                <div className="container-lg-top-item">
                    <img className="nav-logo-img-lg" src={Asset153xp500}/>
                    <div id="logo" className="nav-logo-text-lg">ESCAPE ROOM</div>
</div>*/}
                    <div className="container-lg-bottom">
                        <Link className="nav-option-lg" style={{ color: selectedMenuOptionIndex === 1 && "#f4ff9e" }} to="user/home" onClick={() => { setSelectedMenuOptionIndex(1); goToTop() }}>{i18next.t('Home')}</Link>
                        <Link className="nav-option-lg" style={{ color: selectedMenuOptionIndex === 2 && "#f4ff9e" }} to="user/prices" onClick={() => { setSelectedMenuOptionIndex(2); goToTop() }}>{i18next.t('Prices')}</Link>
                        {/*<Link className="nav-option-lg" style={{color: selectedMenuOptionIndex === 3 && "#f4ff9e"}} to="user/package"  onClick={()=>{setSelectedMenuOptionIndex(3); goToTop()}}>{i18next.t('Package')}</Link>*/}
                        <Link className="nav-option-lg" style={{ color: selectedMenuOptionIndex === 4 && "#f4ff9e" }} to="user/reservation" onClick={() => { setSelectedMenuOptionIndex(4); goToTop() }}>{i18next.t('Reservation')}</Link>
                        <Link className="nav-option-lg" style={{ color: selectedMenuOptionIndex === 5 && "#f4ff9e" }} to="user/vouchers" onClick={() => { setSelectedMenuOptionIndex(5); goToTop() }}>{i18next.t('Vouchers')}</Link>
                        <Link className="nav-option-lg" style={{ color: selectedMenuOptionIndex === 6 && "#f4ff9e" }} to="user/gallery" onClick={() => { setSelectedMenuOptionIndex(6); goToTop() }}>{i18next.t('Gallery')}</Link>
                        <Link className="nav-option-lg" style={{ color: selectedMenuOptionIndex === 7 && "#f4ff9e" }} to="user/contact" onClick={() => { setSelectedMenuOptionIndex(6); goToTop() }}>{i18next.t('Contact')}</Link>
                    </div>
                    <div className="container-lg-top-item">
                        <div>
                            <img src={RS} alt={"RS"} width={50} onClick={() => { handleChangeLanguage("rs") }} />
                            <img src={EN} alt={"EN"} width={50} onClick={() => { handleChangeLanguage("en") }} />
                        </div>
                    </div>
                </div>
                {/*<div className="container-lg-bottom">
                    <Link className="nav-option-lg" style={{ color: selectedMenuOptionIndex === 1 && "#f4ff9e" }} to="user/home" onClick={() => { setSelectedMenuOptionIndex(1); goToTop() }}>{i18next.t('Home')}</Link>
                    <Link className="nav-option-lg" style={{ color: selectedMenuOptionIndex === 2 && "#f4ff9e" }} to="user/prices" onClick={() => { setSelectedMenuOptionIndex(2); goToTop() }}>{i18next.t('Prices')}</Link>
                    {/*<Link className="nav-option-lg" style={{color: selectedMenuOptionIndex === 3 && "#f4ff9e"}} to="user/package"  onClick={()=>{setSelectedMenuOptionIndex(3); goToTop()}}>{i18next.t('Package')}</Link>*/}
                {/*<Link className="nav-option-lg" style={{ color: selectedMenuOptionIndex === 4 && "#f4ff9e" }} to="user/reservation" onClick={() => { setSelectedMenuOptionIndex(4); goToTop() }}>{i18next.t('Reservation')}</Link>
                    {/*<Link className="nav-option-lg" style={{color: selectedMenuOptionIndex === 5 && "#f4ff9e"}} to="user/vouchers"  onClick={()=>{setSelectedMenuOptionIndex(5); goToTop()}}>{i18next.t('Vouchers')}</Link>*/}
                {/*<Link className="nav-option-lg" style={{ color: selectedMenuOptionIndex === 6 && "#f4ff9e" }} to="user/gallery" onClick={() => { setSelectedMenuOptionIndex(6); goToTop() }}>{i18next.t('Gallery')}</Link>
                    <Link className="nav-option-lg" style={{ color: selectedMenuOptionIndex === 7 && "#f4ff9e" }} to="user/contact" onClick={() => { setSelectedMenuOptionIndex(6); goToTop() }}>{i18next.t('Contact')}</Link>
                </div>*/}
            </div>
            <div className="header-container-xs-sm-md d-block d-sm-block d-md-block d-lg-none">
                {showMessage &&
                    <div style={{ width: "100%", color: "black", padding: "3px 30px", backgroundColor: "rgb(244,255,158)", textAlign: "center" }}>
                        {message !== null && message}
                        <XCircle onClick={() => { setShowMessage(false) }} style={{ position: "absolute", right: 10, top: 5 }} />
                    </div>
                }
                <div className="header-xs-sm-md">
                    <div className="nav-logo-xs-sm-md">
                        <img className="nav-logo-img" src={Asset153xp500} />
                        <span className="nav-logo-text-xs-sm-md">ESCAPE ROOM</span>
                    </div>
                    <button className="nav-button-xs-sm-md" onClick={OpenCloseMenu}>
                        <List />
                    </button>
                </div>
                <div className="menu-xs-sm-md" id="menu">
                    <div className="language-options">
                        <div>
                            <img src={RS} alt={"RS"} width={50} onClick={() => { handleChangeLanguage("rs") }} />
                            <img src={EN} alt={"EN"} width={50} onClick={() => { handleChangeLanguage("en") }} />
                        </div>
                    </div>
                    <div className="nav-options-xs-sm-md">
                        <Link className="nav-option-lg" to="user/home" onClick={() => { OpenCloseMenu(); goToTop() }}>{i18next.t('Home')}</Link>
                    </div>
                    <div className="nav-options-xs-sm-md">
                        <Link className="nav-option-lg" to="user/prices" onClick={() => { OpenCloseMenu(); goToTop() }}>{i18next.t('Prices')}</Link>
                    </div>
                    {/*<div className="nav-options-xs-sm-md">
                    <Link className="nav-option-lg" to="user/package" onClick={()=>{OpenCloseMenu(); goToTop()}}>{i18next.t('Package')}</Link>
</div>*/}
                    <div className="nav-options-xs-sm-md">
                        <Link className="nav-option-lg" to="user/reservation" onClick={() => { OpenCloseMenu(); goToTop() }}>{i18next.t('Reservation')}</Link>
                    </div>
                    <div className="nav-options-xs-sm-md">
                        <Link className="nav-option-lg" to="user/vouchers" onClick={() => { OpenCloseMenu(); goToTop() }}>{i18next.t('Vouchers')}</Link>
                    </div>
                    <div className="nav-options-xs-sm-md">
                        <Link className="nav-option-lg" to="user/gallery" onClick={() => { OpenCloseMenu(); goToTop() }}>{i18next.t('Gallery')}</Link>
                    </div>
                    <div className="nav-options-xs-sm-md">
                        <Link className="nav-option-lg" to="user/contact" onClick={() => { OpenCloseMenu(); goToTop() }}>{i18next.t('Contact')}</Link>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Navbar