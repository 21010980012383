import '../../assets/styles/HomeHero/HomeHero.scss'
import Divider from "../divider/divider";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import Asset153xp500 from "../../assets/images/Asset-153x-p-500.png"

function HomeHero(){

    const {t} = useTranslation()

    return(
        <div className="home-hero-container">
            <div className="home-hero-wrapper">
                <div className="home-hero-title">{t('HeroTip11')}</div>
                <div className="home-hero-title-2"><img style={{width: "1em", marginRight: "20px"}} src={Asset153xp500}/>{t('HeroTip1')}</div>
                <div className="home-hero-heading-1">{t('HeroTip2')}</div>
                <Divider/>
                <h3 className="home-hero-heading-2" style={{margin: "0 auto"}}>{t('HeroTip3')}</h3>
            </div>
        <Link to="/user/reservation"><button className="home-hero-button" >{t('BookToday')}</button></Link>
        <div className="home-hero-text">THE #1 ESCAPE ROOM & VR/AR EXPERIENCE IN NIŠ</div>
        </div>
    );
}

export default HomeHero