import ReceiptCard from "../receipt-card/receipt-card";
import { ChevronLeft, ChevronRight, Search, X, Check } from 'react-bootstrap-icons'
import ReceiptCardDetails from "../receipt-card/receipt-card-details";

function ReservationDetailsModal(props){

    const {handleDelete, handleUpdate, handleEdit, handleClose, receipt, isOpen} = props

    return (
        <div style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{width: "95%", maxWidth: "500px", backgroundColor: "white"}}>
                <div style={{display: "flex", justifyContent: "flex-end", padding: "10px"}}>
                    <X onClick={handleClose}/>
                </div>
                <ReceiptCardDetails receipt={receipt} DeleteReceipt={handleDelete} handleUpdate={handleUpdate}/>
            </div>
            
        </div>
    )
}

export default ReservationDetailsModal;