import '../../assets/styles/Prices/Prices.scss'
import icon from '../../assets/images/icons/vr.png'
import icon2 from '../../assets/images/icons/er.png'
import icon3 from '../../assets/images/icons/ervr.png'
import i18next from '../../utils/i18n/i18n'
import { useTranslation } from 'react-i18next'

function PriceCard(props) {
    const { product } = props

    const [t, i18n] = useTranslation()

    var min = product.minNumberOfPersons
    var max = product.maxNumberOfPersons

    var people = []
    var pricePP = []
    var priceTotal = []
    var pricePPLevel2 = []
    var priceTotalLevel2 = []
    var minPrice = ""

    //console.log(product)
    if (product.productTypeName === "virtual_reality") {
        people.push("30 min")
        var price = (parseInt(product.pricePerPerson))

        minPrice = parseInt(parseInt(price)) + " RSD"

        priceTotal.push(Math.ceil(price) + " RSD")
        pricePP.push(Math.ceil(price) + " RSD")

        people.push("60 min")
        var discount = (parseInt(i) - parseInt(product.discountIfMoreThan)) * (parseInt(product.discountPerPerson))
        var price = (parseInt(product.pricePerPerson)*2)-parseInt(product.discountPerPerson)

        priceTotal.push(Math.ceil(price) + " RSD")
        pricePP.push(Math.ceil(price) + " RSD")

    }
    else {
        for (var i = min; i <= max; i++) {
            people.push(i)
            if (i >= product.discountIfMoreThan) {
                var discount = (parseInt(i) - parseInt(product.discountIfMoreThan)) * (parseInt(product.discountPerPerson))
                var price = (parseInt(product.pricePerPerson) * parseInt(i)) - discount * parseInt(i)

                priceTotal.push(Math.ceil(price) + " RSD")
                pricePP.push(Math.ceil(price / parseInt(i)) + " RSD")

                priceTotalLevel2.push(Math.ceil(price + 1000) + " RSD")
                pricePPLevel2.push(Math.ceil((price + 1000) / parseInt(i)) + " RSD")
                if (i == max)
                    minPrice = parseInt(parseInt(price / parseInt(i))) + " RSD"
            }
            else {
                var price = (parseInt(product.pricePerPerson) * parseInt(i))
                priceTotal.push(price + " RSD")
                pricePP.push(price / parseInt(i) + " RSD")

                priceTotalLevel2.push(Math.ceil(price + 1000) + " RSD")
                pricePPLevel2.push(Math.ceil((price + 1000) / parseInt(i)) + " RSD")

                if (i == max)
                    minPrice = price / parseInt(i) + " RSD"
            }
        }
    }


    return (
        <div className="prices-card">
            <div className="prices-card-header">
                <img src={product.productTypeName === "virtual_reality" ? (product.nameTranslation.sr === "VR Escape room" ? icon3 : icon) : icon2} width={70} style={{ margin: 10 }} />
                <h4><b>{product.nameTranslation.sr}</b></h4>
            </div>
            <h4 className="prices-card-from">{t('From')} {minPrice}</h4>
            {product.productTypeName !== "virtual_reality" && <div style={{marginLeft: "20px"}}><b>{t('level1')}</b></div>}
            <div className="prices-card-content">
                
                {product.productTypeName === "virtual_reality" ? 
                <>
                <div>
                    <b>{t('AppointmrntDuration')}</b><br /><br />
                    {people.map((i, index) => <h6 key={index}>{i}</h6>)}
                </div>
                <div>
                    <b>{t('Price')}</b><br /><br />
                    {pricePP.map((i, index) => <h6 key={index}>{i}</h6>)}
                </div>
                </>
                :
                <>
                <div>
                    <b>{t('People')}</b><br /><br />
                    {people.map((i, index) => <h6 key={index}>{i}</h6>)}
                </div>
                <div>
                    <b>{t('PricePP')}</b><br /><br />
                    {pricePP.map((i, index) => <h6 key={index}>{i}</h6>)}
                </div>
                <div>
                    <b>{t('PriceTotal')}</b><br /><br />
                    {priceTotal.map((i, index) => <h6 key={index}>{i}</h6>)}
                </div>
                </>
                }
                
            </div>
            {product.productTypeName !== "virtual_reality" && <div style={{marginLeft: "20px"}}><b>{t('level2')}</b></div>}
            <div className="prices-card-content">
                
                {product.productTypeName !== "virtual_reality" &&
                
                <>
                <div>
                    <b>{t('People')}</b><br /><br />
                    {people.map((i, index) => <h6 key={index}>{i}</h6>)}
                </div>
                <div>
                    <b>{t('PricePP')}</b><br /><br />
                    {pricePPLevel2.map((i, index) => <h6 key={index}>{i}</h6>)}
                </div>
                <div>
                    <b>{t('PriceTotal')}</b><br /><br />
                    {priceTotalLevel2.map((i, index) => <h6 key={index}>{i}</h6>)}
                </div>
                </>
                }
                
            </div>
            {
                product.productTypeName === "virtual_reality" && 
                <h5 className="prices-card-from">{t('DiscountForStudents')}</h5>
            }
        </div>
    )
}

export default PriceCard;