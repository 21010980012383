import {useEffect, useState} from 'react'
import '../../assets/styles/CreateComponent/CreateComponent.scss'
import axios from 'axios'
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants'
import defaultImage from '../../assets/images/noImage.png'
import Loading from '../../../User/components/loading/loading'

function CreateComponent(props){

    const {setSelectedOption, roomToEdit, additinalToEdit} = props
    const [step, setStep] = useState(1)

    const [loading, setLoading] = useState(false)
    const [saved, setSaved] = useState(false)

    const [componentType, setComponentType] = useState(1)

    const [nameEN, setNameEN] = useState("")
    const [nameSR, setNameSR] = useState("")
    const [descriptionEN, setDescriptionEN] = useState("")
    const [descriptionSR, setDescriptionSR] = useState("")
    const [imageSrc, setImageSrc] = useState("")
    const [imageName, setImageName] = useState("")
    const [imageFile, setImageFile] = useState(null)


    const [nameTranslation, setNameTranslation] = useState()
    const [descriptionTranslation, setDescriptionTranslation] = useState()

    const CreateTranslation = async (uiName, sr, en) => { 
        setLoading(true)
        await axios.post(backendRoot + "api/Translations/Create",{
            uiName: uiName,
            sr: sr,
            en: en
        })
            .then((response) =>{
                //console.log(response)
                if(uiName === "MAIN_PRODUCT_COMPONENT_NAME" || uiName === "ADDITIONAL_PRODUCT_COMPONENT_NAME")
                {
                    setNameTranslation(response.data)
                    setLoading(false)
                } 
                else
                {
                    setSaved(true)
                    setDescriptionTranslation(response.data)
                    setLoading(false)
                }
            })
            .catch((error) =>{
                //console.log(error);
            })
    }

    

    const showPreview = e => {
        //console.log(e.target.files)
        if(e.target.files && e.target.files[0])
        {
            let imageFile = e.target.files[0]
            const reader = new FileReader()
            reader.onload = x=>{
                setImageFile(imageFile)
                setImageSrc(x.target.result)
            }
            reader.readAsDataURL(imageFile)
        }
    }

    //main product component

    const [mainProductComponent, setMainProductComponent] = useState({})

    const CreateMainProductComponent = async () =>{
        setLoading(true)
        await axios.post(backendRoot + "api/MainProductComponents/Create",{
            imageName: null,
            nameTranslationId: nameTranslation.id,
            descriptionTranslationId: descriptionTranslation.id
        },
        {
            withCredentials: true
        })
            .then((response) =>{
                //console.log(response)
                setMainProductComponent(response.data)
                setLoading(false)
                setStep(2)
            })
            .catch((error) =>{
                //console.log(error);
                setLoading(false)
            })
    }

    //additinal product component

    const [additionalProductComponent, setAdditionalProductComponent] = useState({})

    const CreateAdditionalProductComponent = async () =>{
        setLoading(true)
        await axios.post(backendRoot + "api/AdditionalProductComponents/Create",{
            imageName: null,
            nameTranslationId: nameTranslation.id,
            descriptionTranslationId: descriptionTranslation.id
        },
        {
            withCredentials: true
        })
            .then((response) =>{
                //console.log(response)
                setAdditionalProductComponent(response.data)
                setLoading(false)
                setStep(2)
            })
            .catch((error) =>{
                //console.log(error);
                setLoading(false)
            })
    }

    

    const handleStep2 = async () => {
        if(roomToEdit !== null)
        {
            setStep(2)
        }
        else
        {
            if(componentType === 1)
                CreateMainProductComponent()
            else
                CreateAdditionalProductComponent()
        }
    }


    const uploadImage = async () => {
        //console.log(imageFile)
        setLoading(true)
        const formData = new FormData()

        formData.append('id', componentType === 1 ? mainProductComponent.id : additionalProductComponent.id)
        formData.append('class', componentType === 1 ? 2 : 1)
        formData.append('imageFile', imageFile)

        await axios.post(backendRoot + "api/Images/AddImage",formData,
        {
            withCredentials: true
        })
            .then((response) =>{
                //console.log(response)
                setLoading(false)
                setSelectedOption(3)
            })
            .catch((error) =>{
                //console.log(error);
                setLoading(false)
            })
    }

    //edit

    const UpdateTranslation = async (id, uiName, sr, en) => { 
        setLoading(true)
        await axios.put(backendRoot + "api/Translations/Update",{
            id: id,
            uiName: uiName,
            sr: sr,
            en: en
        })
            .then((response) =>{
                //console.log(response)
                if(uiName === "MAIN_PRODUCT_COMPONENT_NAME" || uiName === "ADDITIONAL_PRODUCT_COMPONENT_NAME")
                {
                    setLoading(false)
                } 
                else
                {
                    setSaved(true)
                    setLoading(false)
                }
            })
            .catch((error) =>{
                //console.log(error);
            })
    }

    useEffect(()=>{
        if(roomToEdit !== null)
        {
            setMainProductComponent(roomToEdit)
            setNameEN(roomToEdit.nameTranslation.en)
            setNameSR(roomToEdit.nameTranslation.sr)
            setDescriptionEN(roomToEdit.descriptionTranslation.en)
            setDescriptionSR(roomToEdit.descriptionTranslation.sr)
            //console.log(roomToEdit)
        }
    },[roomToEdit])

    const [disabledSaveButton, setDisabledSaveButton] = useState(false)

    useEffect(()=>{
        setSaved(false)
        if(roomToEdit !== null && nameEN === roomToEdit.nameTranslation.en && nameSR === roomToEdit.nameTranslation.sr && descriptionEN === roomToEdit.descriptionTranslation.en && descriptionSR === roomToEdit.descriptionTranslation.sr)
        {
            setSaved(true)
            setDisabledSaveButton(true)
        }
        else
        {
            setDisabledSaveButton(false)
        }
    },[nameEN, nameSR, descriptionEN, descriptionSR])

    const handleSaveStep1 = async () => {
        //console.log(roomToEdit,additinalToEdit)
        if(nameEN !== "" && nameSR !== "" && descriptionEN !== "" && descriptionSR !== "")
        {
            if(roomToEdit !== null && additinalToEdit === null)
            {
                await UpdateTranslation(roomToEdit.nameTranslation.id,"MAIN_PRODUCT_COMPONENT_NAME",nameSR,nameEN)
                await UpdateTranslation(roomToEdit.descriptionTranslation.id,"MAIN_PRODUCT_COMPONENT_DESCRIPTION",descriptionSR,descriptionEN)
            }
            if(roomToEdit === null && additinalToEdit !== null)
            {
                await UpdateTranslation(roomToEdit.nameTranslation.id,"ADDITIONAL_PRODUCT_COMPONENT_NAME",nameSR,nameEN)
                await UpdateTranslation(roomToEdit.descriptionTranslation.id,"ADDITIONAL_PRODUCT_COMPONENT_DESCRIPTION",descriptionSR,descriptionEN)
            }
            if(roomToEdit === null && additinalToEdit === null && componentType === 1  )
            {
                await CreateTranslation("MAIN_PRODUCT_COMPONENT_NAME",nameSR,nameEN)
                await CreateTranslation("MAIN_PRODUCT_COMPONENT_DESCRIPTION",descriptionSR,descriptionEN)
            }
            if(roomToEdit === null && additinalToEdit === null && componentType === 2)
            {
                await CreateTranslation("ADDITIONAL_PRODUCT_COMPONENT_NAME",nameSR,nameEN)
                await CreateTranslation("ADDITIONAL_PRODUCT_COMPONENT_DESCRIPTION",descriptionSR,descriptionEN)
            }
        }
    }

    return(
        <>
        {loading && <Loading/>}
        <div className="create-product-container">
            {step === 1 && 
            <>
            <div>
                <h5>Create room</h5><input type="checkbox" checked={componentType === 1} onChange={()=>{setComponentType(1)}}/><br/><br/><h5>Create addition</h5><input type="checkbox" checked={componentType === 2} onChange={()=>{setComponentType(2)}}/><br/><br/>
            </div>
            <div><h5>Enter name(EN): </h5><input value={nameEN} onChange={(e)=>{setNameEN(e.target.value)}}/></div><br/>
            <div><h5>Enter name(SR): </h5><input value={nameSR} onChange={(e)=>{setNameSR(e.target.value)}}/></div><br/>
            <div style={{width: "100%"}}><h5>Enter description(EN): </h5><textarea value={descriptionEN} onChange={(e)=>{setDescriptionEN(e.target.value)}}/></div><br/>
            <div style={{width: "100%"}}><h5>Enter description(SR): </h5><textarea value={descriptionSR} onChange={(e)=>{setDescriptionSR(e.target.value)}}/></div><br/>
            <div className="create-room-buttons-container-1">
                <button onClick={()=>{setSelectedOption(3)}}>Cancel</button>
                <div>
                    <button onClick={handleSaveStep1} disabled={disabledSaveButton || saved}>Save</button>
                    <button onClick={handleStep2} disabled={!saved}>Next</button>
                </div>
            </div>
            </>}
            {step === 2 && 
            <>
            <div><h5>Upload image: </h5></div>
            <div style={{width: "95%", maxWidth: 500}}>
                <img style={{width: "100%"}} src={imageSrc === "" ? defaultImage : imageSrc} alt={"def image"}/>
            </div><br/>
            <input type={"file"} accept="image/*" onChange={showPreview}/><br/>
            <button onClick={()=>{setSelectedOption(3)}} disabled={imageFile !== null}>Skip</button>
            <button onClick={uploadImage} disabled={imageFile === null}>Finish</button>
            </>}
        </div>
        </>
    )
}

export default CreateComponent;