import { useState } from 'react'
import '../../assets/styles/AdminStatistics/AdminStatistics.scss'
import { Person, CalendarCheck, Building } from 'react-bootstrap-icons'
import Loading from '../../../User/components/loading/loading'
import axios from 'axios'
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants'
import { useEffect } from 'react'
import { reservationFinishLoading } from '../../../User/store/actionCreators/reservation'

function AdminStatistics() {

    const [loading, setLoading] = useState(false)
    const [receipts, setReceipts] = useState(null)
    const [filteredReceipts, setFilteredReceipts] = useState([])
    const [topTen, setTopTen] = useState(null)
    const [viewAll, setViewAll] = useState(false)

    const GetReceipts = async () => {
        setLoading(true)
        await axios.get(backendRoot + "api/Receipts/GetReceiptsForReservarions")
            .then((response) => {
                setReceipts(response.data)
                var arr = []
                response.data.forEach(element => {
                    if (element.appointmentReservation.timeToFinishInMinutes != 0)
                        arr.push(element)
                });
                setFilteredReceipts(arr)
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                //console.log(error);
            })
    }

    useEffect(() => {
        if (receipts === null)
            GetReceipts()
    }, [receipts])

    useEffect(() => {
        if (filteredReceipts.length > 0)
            setTopTen([...filteredReceipts].sort((a, b) => a.appointmentReservation.timeToFinishInMinute > b.appointmentReservation.timeToFinishInMinute))
    }, [filteredReceipts])
    useEffect(() => {
        console.log(topTen)
    }, [topTen])


    return (
        <>
            {loading && <Loading />}
            <div className="admin-panelpage-title">
                Statistics
            </div>
            <div className="statistics-first-container">
                <div>
                    <CalendarCheck fontSize={40} />
                    {receipts !== null && receipts.length}
                    <br />
                    <b style={{ color: "rgb(0,123,255)" }}>Reservations</b>
                </div>
                {/*<div>
                    <Person fontSize={40} />
                    53
                    <br />
                    <b style={{ color: "rgb(255,193,8)" }}>Individuals</b>
                </div>
                <div>
                    <Building fontSize={40} />
                    53
                    <br />
                    <b style={{ color: "rgb(40,167,69)" }}>Legal entities</b>
    </div>*/}

            </div>
            <div className="section-title">
                Best results
            </div>
            <div className="statistics-second-container">


                <div className="reservations-by-date-scroll-container">
                    <div className="reservations-by-date-container" style={{ minWidth: "900px" }}>
                        <div><b>Team name</b></div>
                        <div><b>Time</b></div>
                        <div><b>Email</b></div>
                        <div><b>User</b></div>
                    </div>
                    {
                        topTen !== null &&
                        topTen.map((receipt, index) =>
                            <>
                                {index < 10 &&
                                    <div className="reservations-by-date-container" style={{ minWidth: "900px" }}>
                                        <div>{receipt?.appointmentReservation?.teamName}</div>
                                        <div><b>{receipt.appointmentReservation.timeToFinishInMinutes}min</b></div>
                                        <div>{receipt?.individual !== null ? receipt?.individual?.email : receipt?.legalEntity?.email}</div>
                                        <div>{receipt?.individual !== null ? receipt?.individual?.name + " " + receipt?.individual?.lastname : receipt?.legalEntity?.name + " " + receipt?.legalEntity?.lastname}</div>
                                    </div>
                                }
                            </>
                        )

                    }
                </div>
            </div>
            <div className="section-title">
                All results
            </div>
            <div className='statistics-second-container'>
                {
                    viewAll ?
                        <div className="reservations-by-date-scroll-container">
                            <div className="reservations-by-date-container" style={{ minWidth: "900px" }}>
                                <div><b>Team name</b></div>
                                <div><b>Time</b></div>
                                <div><b>Email</b></div>
                                <div><b>User</b></div>
                            </div>
                            {
                                topTen !== null &&
                                topTen.map((receipt, index) =>
                                    <>
                                        {index < 10 &&
                                            <div className="reservations-by-date-container" style={{ minWidth: "900px" }}>
                                                <div>{receipt?.appointmentReservation?.teamName}</div>
                                                <div><b>{receipt.appointmentReservation.timeToFinishInMinutes}min</b></div>
                                                <div>{receipt?.individual !== null ? receipt?.individual?.email : receipt?.legalEntity?.email}</div>
                                                <div>{receipt?.individual !== null ? receipt?.individual?.name + " " + receipt?.individual?.lastname : receipt?.legalEntity?.name + " " + receipt?.legalEntity?.lastname}</div>
                                            </div>
                                        }
                                    </>
                                )

                            }
                            <button onClick={() => { setViewAll(false) }}>Clear</button>
                        </div>
                        :
                        <button onClick={() => { setViewAll(true) }}>View all</button>
                }
            </div>
        </>
    )
}

export default AdminStatistics