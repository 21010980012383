import { useState } from 'react';
import '../../assets/styles/FindVoucher/FindVoucherV2.scss'
import axios from 'axios';
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants';
import Loading from '../../../User/components/loading/loading';
import { useEffect } from 'react';
import { editableInputTypes } from '@testing-library/user-event/dist/utils';

function FindVoucher() {

    const [code, setCode] = useState("")
    const [voucher, setVoucher] = useState(null)
    const [vouchers, setVouchers] = useState(null)
    const [loading, setLoading] = useState(false)

    const [selectedFilter, setSelectedFilter] = useState(0)
    const [filteredVouchers, setFilteredVouchers] = useState([])

    const [voucherDetails, setVoucherDetails] = useState(null)

    const [editIndex, setEditIndex] = useState(false)

    const [newStartDate, setNewStartDate] = useState(null)
    const [newEndDate, setNewEndDate] = useState(null)

    const [voucherToEdit, setVoucherToEdit] = useState(null)

    useEffect(() => {
        if (vouchers !== null) {
            var newVouchers = []
            vouchers.forEach(element => {

            });
        }
    })

    const GetVoucherByCode = async () => {
        if (code != '') {
            setLoading(true)
            await axios.get(backendRoot + "api/Vouchers/GetByCode/" + code)
                .then((response) => {
                    setVoucher(response.data)
                    //console.log(response.data)
                    setLoading(false)
                })
                .catch((error) => {
                    // handle error
                    setLoading(false)
                    //console.log(error);
                })
        }
    }

    const GetVouchers = async () => {
        setLoading(true)
        await axios.get(backendRoot + "api/Vouchers/Get/")
            .then((response) => {
                setVouchers(response.data)
                setFilteredVouchers(response.data)
                setLoading(false)
            })
            .catch((error) => {
                // handle error
                setLoading(false)
                //console.log(error);
            })
    }

    const ApproveVoucher = async () => {
        if (voucher !== null) {
            setLoading(true)
            await axios.post(backendRoot + "api/Vouchers/Approve/" + voucher.id)
                .then((response) => {
                    //setVoucher(response.data)
                    //console.log(response.data)
                    GetVoucherByCode()
                    setLoading(false)
                })
                .catch((error) => {
                    // handle error
                    setLoading(false)
                    //console.log(error);
                })
        }
    }


    const validateVoucher = (voucher) => {
        if (!voucher.isPromoCode && voucher.isUsed)
            return 4
        if (!voucher.isPromoCode && !voucher.isUsed) {
            if (voucher.endDate !== null) {
                var dateNow = new Date()
                var endDate = new Date(voucher.endDate)
                if (dateNow.getFullYear() === endDate.getFullYear() && dateNow.getDate() > endDate.getDate() && dateNow.getMonth() === endDate.getMonth() || dateNow.getFullYear() >= endDate.getFullYear() && dateNow.getMonth() > endDate.getMonth())
                    return 4
            }
            return 3
        }
        if (voucher.isPromoCode) {
            if (voucher.endDate !== null) {
                var dateNow = new Date()
                var endDate = new Date(voucher.endDate)
                if (dateNow.getFullYear() === endDate.getFullYear() && dateNow.getDate() > endDate.getDate() && dateNow.getMonth() === endDate.getMonth() || dateNow.getFullYear() > endDate.getFullYear() || dateNow.getFullYear() === endDate.getFullYear() && dateNow.getMonth() > endDate.getMonth())
                    return 2
            }
            return 1
        }
    }

    useEffect(() => {
        if (vouchers === null)
            GetVouchers()
    }, [vouchers])

    useEffect(() => {
        if (vouchers !== null) {
            if (selectedFilter !== 0) {
                var newVouchers = []
                vouchers.forEach(element => {
                    if (selectedFilter === validateVoucher(element))
                        newVouchers.push(element)
                });
                setFilteredVouchers(newVouchers)
            }
            else
                setFilteredVouchers(vouchers)

        }
    }, [selectedFilter])

    const [loadingDetails, setLoadingDetails] = useState(false)

    const GetVoucherDetails = async (voucherId) => {
        setLoadingDetails(true)
        await axios.get(backendRoot + "api/Receipts/GetByVoucherId/" + voucherId)
            .then((response) => {
                setVoucherDetails(response.data)
                //console.log(response.data)
                setLoadingDetails(false)
            })
            .catch((error) => {
                // handle error
                setLoadingDetails(false)
                //console.log(error);
            })
    }

    const SetEditVoucher = (voucher, index) => {
        setVoucherToEdit(voucher)
        setEditIndex(index)

        var newStart = voucher.startDate.split('-')[0] + "-" + voucher.startDate.split('-')[1] + "-" + voucher.startDate.split('-')[2].split("T")[0]
        setNewStartDate(newStart.toString())
        var newEnd = voucher.endDate.split('-')[0] + "-" + voucher.endDate.split('-')[1] + "-" + voucher.endDate.split('-')[2].split("T")[0]
        setNewEndDate(newEnd)
    }

    const CancelEditVoucher = () => {
        setVoucherToEdit(null)
        setEditIndex(null)
        setNewStartDate(null)
        setNewEndDate(null)
    }

    const handleEdit = async () => {
        var newStart = new Date(newStartDate.split('-')[0],newStartDate.split('-')[1] - 1,newStartDate.split('-')[2])
        var newEnd = new Date(newEndDate.split('-')[0],newEndDate.split('-')[1] - 1,newEndDate.split('-')[2])
        newStart.setDate(newStart.getDate() + 1)
        console.log(newStart, newEnd)
        await axios.put(backendRoot + "api/Vouchers/Update", {
            id: voucherToEdit.id,
            productId: voucherToEdit.productId,
            code: voucherToEdit.code,
            numberOfPersons: voucherToEdit.numberOfPersons,
            isPromoCode: voucherToEdit.isPromoCode,
            isUsed: voucherToEdit.isUsed,
            discount: voucherToEdit.discount,
            startDate: newStart,
            endDate: newEnd,
            createdAtDate: voucherToEdit.createdAtDate,
            modifiedAtDate: new Date(),
            voucherAmountId: voucherToEdit.voucherAmountId,
            approved: voucherToEdit.approved,
        })
            .then((response) => {
                console.log(response)
                CancelEditVoucher()
                setVouchers(null)
                GetVouchers()
            })
            .catch((error) => {
                // handle error
                console.log(error);
            })
    }

    return (
        <>
            {loading && <Loading />}
            <div className="admin-panelpage-title">
                Vouchers / Overview
            </div>
            <div className="find-voucher-search">
                Enter code: <input type="text" value={code} onChange={(e) => { setCode(e.target.value) }} /><button onClick={GetVoucherByCode}>Search</button>
                <br /><br />
                Voucher legend
                <div className="find-voucher-legend-container">
                    <div style={{ backgroundColor: "rgb(0,123,255)" }}>Active promo code</div>
                    <div style={{ backgroundColor: "rgb(255,193,8)" }}>Expired promo code</div>
                    <div style={{ backgroundColor: "rgb(40,167,69)" }}>Active user voucher</div>
                    <div style={{ backgroundColor: "rgb(220,53,69)" }}>Expired or used user voucher</div>
                </div>
            </div>

            <div

            >
                {voucher !== null ?
                    <div className="voucher-info-container-v2"
                        style={{
                            borderLeft: validateVoucher(voucher) === 1 ? "4px solid rgb(0,123,255)" :
                                validateVoucher(voucher) === 2 ? "4px solid rgb(255,193,8)" : validateVoucher(voucher) === 3 ? "4px solid rgb(40,167,69)" : "4px solid rgb(220,53,69)"
                        }}
                    >
                        <div>
                            <div className="voucher-info">
                                <div
                                    style={{
                                        color: validateVoucher(voucher) === 1 ? "rgb(0,123,255)" :
                                            validateVoucher(voucher) === 2 ? "rgb(255,193,8)" : validateVoucher(voucher) === 3 ? "rgb(40,167,69)" : "rgb(220,53,69)"
                                    }}><b>{voucher.code}</b></div>
                            </div>
                            <div className="voucher-info">
                                <div><b>Created at: </b>{voucher.createdAtDate.split('-')[2].split("T")[0]}/{voucher.createdAtDate.split('-')[1]}/{voucher.createdAtDate.split('-')[0]}</div>
                            </div>

                            {voucher.startDate !== null &&
                                <div className="voucher-info">
                                    <div><b>Valid from: </b>{voucher.startDate.split('-')[2].split("T")[0]}/{voucher.startDate.split('-')[1]}/{voucher.startDate.split('-')[0]}</div>
                                </div>
                            }
                            {voucher.endDate !== null &&
                                <div className="voucher-info">
                                    <div><b>Valid until: </b>{voucher.endDate.split('-')[2].split("T")[0]}/{voucher.endDate.split('-')[1]}/{voucher.endDate.split('-')[0]}</div>
                                </div>
                            }
                            {!voucher.isPromoCode &&
                                <div className="voucher-info">
                                    <div><b>Used: </b>{voucher.isUsed ? "Yes" : "No"}</div>
                                </div>
                            }
                            {voucher.voucherAmount !== null &&
                                <div className="voucher-info">
                                    <div><b>Amount: </b>{voucher.voucherAmount.amount} RSD</div>
                                </div>
                            }
                            {voucher.discount !== null &&
                                <div className="voucher-info">
                                    <div><b>Discount: </b>{voucher.discount} %</div>
                                </div>
                            }
                            <div className="voucher-info">
                                <div><b>Approved: </b>{voucher.approved ? <b style={{ color: "green" }}>Yes</b> : <b style={{ color: "red" }}>No</b>}</div>
                            </div>
                            <br />
                            <button onClick={ApproveVoucher} disabled={voucher.approved}>Approve</button></div>
                    </div>
                    :
                    null
                }
            </div>
            <div className="left-admin-panel-menu-logo">
                All vouchers

            </div>
            <div className="find-voucher-search">
                Filters
                <div className="find-voucher-legend-container">
                    <div onClick={() => { setSelectedFilter(0) }} style={{ backgroundColor: selectedFilter === 0 ? "rgb(48, 60, 84)" : "rgb(150,150,150)", cursor: "pointer" }}>All vouchers</div>
                    <div onClick={() => { setSelectedFilter(1) }} style={{ backgroundColor: selectedFilter === 1 ? "rgb(0,123,255)" : "rgb(150,150,150)", cursor: "pointer" }}>Active promo code</div>
                    <div onClick={() => { setSelectedFilter(2) }} style={{ backgroundColor: selectedFilter === 2 ? "rgb(255,193,8)" : "rgb(150,150,150)", cursor: "pointer" }}>Expired promo code</div>
                    <div onClick={() => { setSelectedFilter(3) }} style={{ backgroundColor: selectedFilter === 3 ? "rgb(40,167,69)" : "rgb(150,150,150)", cursor: "pointer" }}>Active user voucher</div>
                    <div onClick={() => { setSelectedFilter(4) }} style={{ backgroundColor: selectedFilter === 4 ? "rgb(220,53,69)" : "rgb(150,150,150)", cursor: "pointer" }}>Expired or used user voucher</div>
                </div>
            </div>
            <div className='all-vouchers'>
                {
                    filteredVouchers.length > 0 &&
                    filteredVouchers.map((voucher, index) => {
                        return (
                            <div key={voucher.id}>
                                {voucher !== null ?
                                    <div key={voucher.id} className="voucher-info-container-v2"
                                        style={{
                                            borderLeft: validateVoucher(voucher) === 1 ? "4px solid rgb(0,123,255)" :
                                                validateVoucher(voucher) === 2 ? "4px solid rgb(255,193,8)" : validateVoucher(voucher) === 3 ? "4px solid rgb(40,167,69)" : "4px solid rgb(220,53,69)"
                                        }}
                                    >
                                        <div>
                                            <div className="voucher-info">
                                                <div
                                                    style={{
                                                        color: validateVoucher(voucher) === 1 ? "rgb(0,123,255)" :
                                                            validateVoucher(voucher) === 2 ? "rgb(255,193,8)" : validateVoucher(voucher) === 3 ? "rgb(40,167,69)" : "rgb(220,53,69)"
                                                    }}><b>{voucher.code}</b></div>
                                            </div>
                                            <div className="voucher-info">
                                                <div><b>Created at: </b>{voucher.createdAtDate.split('-')[2].split("T")[0]}/{voucher.createdAtDate.split('-')[1]}/{voucher.createdAtDate.split('-')[0]}</div>
                                            </div>

                                            {voucher.startDate !== null &&
                                                <div className="voucher-info">
                                                    <div><b>Valid from: </b>{editIndex === index ? <input type="date" value={newStartDate !== null && newStartDate} onChange={(e) => { setNewStartDate(e.target.value) }} /> : <>{voucher.startDate.split('-')[2].split("T")[0]}/{voucher.startDate.split('-')[1]}/{voucher.startDate.split('-')[0]}</>}</div>
                                                </div>
                                            }
                                            {voucher.endDate !== null &&
                                                <div className="voucher-info">
                                                    <div><b>Valid until: </b>{editIndex === index ? <input type="date" value={newEndDate !== null && newEndDate} onChange={(e) => { setNewEndDate(e.target.value) }} /> : <>{voucher.endDate.split('-')[2].split("T")[0]}/{voucher.endDate.split('-')[1]}/{voucher.endDate.split('-')[0]}</>}</div>
                                                </div>
                                            }
                                            {!voucher.isPromoCode &&
                                                <div className="voucher-info">
                                                    <div><b>Used: </b>{voucher.isUsed ? "Yes" : "No"}</div>
                                                </div>
                                            }
                                            {voucher.voucherAmount !== null &&
                                                <div className="voucher-info">
                                                    <div><b>Amount: </b>{voucher.voucherAmount.amount} RSD</div>
                                                </div>
                                            }
                                            {voucher.discount !== null &&
                                                <div className="voucher-info">
                                                    <div><b>Discount: </b>{voucher.discount} %</div>
                                                </div>
                                            }
                                            <div className="voucher-info">
                                                <div><b>Approved: </b>{voucher.approved ? <b style={{ color: "green" }}>Yes</b> : <b style={{ color: "red" }}>No</b>}</div>
                                            </div>
                                            {
                                                voucherDetails !== null && voucherDetails.voucherId === voucher.id &&
                                                <>
                                                    <br />
                                                    <div>Created for user:</div>
                                                    <div className="voucher-info">
                                                        <div><b>Name: </b>{voucherDetails.individual.name}</div>
                                                    </div>
                                                    <div className="voucher-info">
                                                        <div><b>Lastname: </b>{voucherDetails.individual.lastname}</div>
                                                    </div>
                                                    <div className="voucher-info">
                                                        <div><b>Email: </b>{voucherDetails.individual.email}</div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                editIndex === index ?
                                                    <>
                                                        <button onClick={handleEdit}>Save</button>
                                                        <button onClick={() => { CancelEditVoucher() }}>Cancel</button>
                                                    </>

                                                    :
                                                    <button onClick={() => { SetEditVoucher(voucher, index) }}>Edit</button>


                                            }

                                        </div>
                                        <button style={{ marginLeft: "30px", visibility: !voucher.isPromoCode ? "initial" : "hidden", display: voucherDetails !== null && voucherDetails.voucherId === voucher.id ? "none" : "initial" }} onClick={() => { GetVoucherDetails(voucher.id) }}>See more</button>

                                    </div>
                                    :
                                    <div className="voucher-info">
                                        <div><b>Voucher not found!</b></div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>

            {/*voucherDetails !== null ?
                <>
                    <div className="voucher-details-modal" onClick={() => { setVoucherDetails(null) }}>
                        <div className="voucher-details"
                            style={{
                                borderLeft: validateVoucher(voucherDetails.voucher) === 1 ? "4px solid rgb(0,123,255)" :
                                    validateVoucher(voucherDetails.voucher) === 2 ? "4px solid rgb(255,193,8)" : validateVoucher(voucherDetails.voucher) === 3 ? "4px solid rgb(40,167,69)" : "4px solid rgb(220,53,69)"
                            }}
                        >
                            <div className="voucher-info">
                                <div
                                    style={{
                                        color: validateVoucher(voucherDetails.voucher) === 1 ? "rgb(0,123,255)" :
                                            validateVoucher(voucherDetails.voucher) === 2 ? "rgb(255,193,8)" : validateVoucher(voucherDetails.voucher) === 3 ? "rgb(40,167,69)" : "rgb(220,53,69)"
                                    }}><b>{voucherDetails.voucher.code}</b> 
                                    {validateVoucher(voucherDetails.voucher) === 4 && !voucherDetails.voucher.isPromoCode && voucherDetails.voucher.isUsed ? "(Used)" : "(Expired)"}
                                </div>
                            </div>
                            
                            <b>For user:</b>
                            {voucherDetails.individual !== null &&
                                <>
                                    <div className="voucher-info">
                                        <div><b>Name: </b>{voucherDetails.individual.name}</div>
                                    </div>
                                    <div className="voucher-info">
                                        <div><b>Lastname: </b>{voucherDetails.individual.lastname}</div>
                                    </div>
                                    <div className="voucher-info">
                                        <div><b>Email: </b>{voucherDetails.individual.email}</div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </>
                :
                null

                        */}

        </>
    )
}

export default FindVoucher;