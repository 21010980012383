import {useState, useEffect} from 'react'
import '../../assets/styles/AdminLogin/AdminLogin.scss'
import Logo from '../../assets/images/logo.png'
import axios from 'axios'
import Loading from '../../../User/components/loading/loading'
import { backendRoot } from '../../../User/assets/apiConstants/ApiConstants'
import { useCookies } from 'react-cookie';

import * as UserActionCreators from '../../store/actionCreators/user';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

function AdminLogin(props){
    const {setAuthenticatedAdmin} = props

    const userSession = useSelector((state) => state.userSession)
    const dispatch = useDispatch();
    const { TryLogin, AutentificateUser } = bindActionCreators({ ...UserActionCreators}, dispatch)

    const [username, setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const handleLogin = () => {
        TryLogin(username,password)
    }

    const [tryAuthentificateUser, setTryAuthentificateUser] = useState(false)

    useEffect(()=>{
        if(!tryAuthentificateUser)
            AutentificateUser()
        setTryAuthentificateUser(true)
    })
    return(
        <>
        {userSession.loading && <Loading/>}
        <div className="admin-login-main-container">
            <div className="admin-login-container">
                <img src={Logo} alt="LOGO"/>
                <div className="login-logo-text">ESCAPE ROOM</div>
                <input type="text" placeholder="Username" onChange={(e)=>{setUsername(e.target.value)}}/>
                <input type="password" placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}}/>
                <button type='submit' onClick={handleLogin}>LOGIN</button>
            </div>
        </div>
        </>
    )
}

export default AdminLogin;