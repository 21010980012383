import { useState, useEffect } from "react";
import { backendRoot } from "../../assets/apiConstants/ApiConstants";
import "../../assets/styles/Gallery/Gallery.scss";
import ImageGallery from "react-image-gallery";
import axios from "axios";
import Footer from "../../components/footer/footer";

import img1 from "../../assets/images/galleryImages/img1.jpg";
import img2 from "../../assets/images/galleryImages/img2.jpg";
import img3 from "../../assets/images/galleryImages/img3.jpg";
import img4 from "../../assets/images/galleryImages/img4.jpg";
import img5 from "../../assets/images/galleryImages/img5.jpg";
import img6 from "../../assets/images/galleryImages/img6.jpg";
import img7 from "../../assets/images/galleryImages/img7.jpg";
import img8 from "../../assets/images/galleryImages/img8.jpg";
import img9 from "../../assets/images/galleryImages/img9.jpg";

function Gallery() {
  const [images, setImages] = useState([]);
  const [imagesPath, setImagesPath] = useState([]);
  const GetImages = async () => {
    await axios
      .get(backendRoot + "api/GalleryImages/Get")
      .then((response) => {
        //console.log(response)
        setImages(response.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    if (images.length === 0) {
      // GetImages(); -> return when backend restored
      setImages([img1, img2, img3, img4, img5, img6, img7, img8, img9]);
    } else {
      var imageArr = [];
      //console.log(images)
      //   images.forEach((image) => {              ----------------------->
      //     imageArr.push({
      //       original: backendRoot + "GalleryImages/" + image.imageName,
      //       thumbnail: backendRoot + "GalleryImages/" + image.imageName,
      //       imageName: image.imageName,
      //       id: image.id,
      //     });
      //   }); -------------------------------------------------------------> return when backend restored
      images.forEach((image) => {
        imageArr.push({
          original: image,
          thumbnail: image,
        });
      });
      setImagesPath(imageArr);
    }
  }, [images]);

  return (
    <div className="gallery-main-container">
      <div className="gallery-container">
        {imagesPath.length !== 0 && <ImageGallery items={imagesPath} />}
      </div>
      <Footer />
    </div>
  );
}

export default Gallery;
