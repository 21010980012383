import '../../assets/styles/ReservationStep/ReservationStep.scss'

function ReservationStep(props){
    const {stepNumber, text} = props
    return(
        <div className="reservation-step-container">
            <div className="reservation-step">
                {/*<span className="reservation-step-child-1">
                    {stepNumber}
    </span>*/}
                <span className="reservation-step-child-2">
                    {text}
                </span>
            </div>
        </div>
    );
}

export default ReservationStep