import react from "react";
import '../../assets/styles/Home/Home.scss'
import '../../assets/styles/HomeContact/HomeContact.scss'
import GoogleMapReact from 'google-map-react';

function HomeContact(){
    const defaultProps = {
        center: {
          lat: 43.3211221,
          lng: 21.8987715
        },
        zoom: 15
      };
    return(
        <div className="home-contact-main-container">
        <h1 className="home-contact-heading">BUDIMO U KONTAKTU</h1>
        <div className="contact-container">
            <div className="contact-container-child">
                <div className="contact-map">
                <GoogleMapReact
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                </GoogleMapReact> 
                </div>
            </div>
            <div className="contact-container-child">
                <div className="contact-form">
                    <input type="text" className="contact-form-input" placeholder="Vase ime"/>
                    <input type="email" className="contact-form-input" placeholder="Email"/>
                    <input type="text" className="contact-form-input" placeholder="Telefon"/>
                    <input type="text" className="contact-form-input" placeholder="Naslov"/>
                    <textarea type="text" className="contact-form-input-2" placeholder=""/>
                    <button className="contact-form-button">Posaljite</button> 
                </div>
            </div>
        </div>
        <p className="copyright">© 2019 5Mict, All rights reserved.</p>
        </div>
    );
}

export default HomeContact